import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import vehiclesSlice from './slices/vehiclesSlice'
import reservationsSlice from "./slices/reservationsSlice";
import authSlice from './slices/authSlice'
import analyticsSlice from "./slices/analyticsSlice"
import settingsSlice from "./slices/settingsSlice";
import clientsSlice from './slices/clientsSlice';
import claimsSlice from './slices/claimsSlice'
import partnerSplitSlice from './slices/partnerSplitSlice'
// import logger from 'redux-logger'

const store = configureStore({
	reducer: {
	  user: userSlice,
	  vehicles: vehiclesSlice,
	  reservations: reservationsSlice,
	  auth: authSlice,
	  analytics: analyticsSlice,
	  settings: settingsSlice,
	  clients: clientsSlice,
	  claims: claimsSlice,
	  partnerSplits: partnerSplitSlice, // Corrected the key name
	}
	// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger), // Apply middleware
  });


export default store
