import React, { useState } from 'react'
import CustomGooglePlacesNew from '../components/reservation/CustomGooglePlacesNew'
import CustomButtonContained from '../components/CustomButtonContained'

const Address = (props) => {
  const [address, setAddress] = useState(null)

  const handleButtonClick = () => {
    if (!address) {
      window.alert('Please search for the address')
      return
    }
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(address))
  }

  return (
		<div style={{
		  height: '100vh',
		  width: '100%',
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center',
		  position: 'relative'
		}}>
			<div style={{ width: '100%' }}>
				<CustomGooglePlacesNew
					placeholder={'Search Address...'}
					value={address}
					onChange={(value) => setAddress(value)}
				/>
			</div>
			<div style={{ position: 'absolute', bottom: 10 }}>
				<CustomButtonContained
					style={{ height: 40 }}
					text={'Add Address'}
					onClick={handleButtonClick}
				/>
			</div>
		</div>
  )
}

export default Address
