const Config = {
  // ENV: 'test',
  // CONTRACT_URL: 'https://contracts-test-real-testsite.web.app/',
  // STRIPE_SECRET: 'pk_test_51OqRQjLmjcRl9psMA4lvprWe1JKLcf9DLjRs1Fr52ScsJb1VDkJAJJKui8I1mRhC0G04MA5QG5BLVxS3Y5lRTDTM008dVMd9U2',
  // APRYSE_LICENSE_KEY: 'demo:1717004095040:7fd53c82030000000095bf31951dfe3fa504558555942b5384a79cf6f0',

  ENV: 'prod',
  CONTRACT_URL: 'https://contracts.golux.app/',
  STRIPE_SECRET: 'pk_live_51OqRQjLmjcRl9psMphKJhRSYiouaLq6QDkies8SE7cpjevc5igODwG4x000CWwtwIkbyfyzsQgA97ht4FRv6tfs6004pF9mROY',
  APRYSE_LICENSE_KEY: 'GoLux Inc:OEM:Roam::B+:AMS(20250629):CDA7E75FCF0437F2D5E06E4F0D82FC913096CFA2682C89F0E9EAB6F5C7',

  ADMIN_CREDS: ['admin@test.com', 'SmvpTKEyH6j0'], // admin project
  // ADMIN_CREDS:['admin@test.com', 'RJPmG2eSBcXl'],//test firebase project
  AWS_CLOUDFRONT_URL: 'https://d3nk8x6ofxzl5v.cloudfront.net/',
  GOOGLE_API_KEY: 'AIzaSyAlmct1SHhXm2OgLlUfShrHZjNFj3fvDNM'
}

export default Config
