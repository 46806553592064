import React, { useCallback, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { useLocation, useSearchParams } from 'react-router-dom'
import ClientsMap from '../../metadata/ClientsMap'
import axios from 'axios'
import Colors from '../../config/colors'
import CheckMark from '../../components/CheckMark'

const stripePromise = loadStripe(Config.STRIPE_SECRET)

const StripeCheckout = (props) => {
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const [afterCheckout, setAfterCheckout] = useState(false)

  useEffect(() => {
    if (location.pathname?.includes('return')) {
      setAfterCheckout(true)
      const sessionId = searchParams.get('session_id')
      console.log('session id is', sessionId)
    } else {
      setAfterCheckout(false)
    }
  }, [location])

  const fetchClientSecret = useCallback(() => {
    return new Promise((resolve, reject) => {
      const bus = searchParams.get('bus')
      const token = searchParams.get('token')
      const amount = searchParams.get('amount')
      const productId = searchParams.get('productId')
      const email = searchParams.get('email')
      const resId = searchParams.get('resId')
      const clientId = searchParams.get('clientId')
      const vehicleId = searchParams.get('vehicleId')
      const salesPerson = searchParams.get('salesPerson')
      const isSecurity = searchParams.get('isSecurity')
      const invoiceId = searchParams.get('invoiceId')
      const clientInfo = ClientsMap[bus]

      const data = {
        productId,
        email,
        amount: Number(amount),
        resId,
        vehicleId,
        salesPerson,
        clientId,
        isSecurityHold: isSecurity === 'true'
      }

      if (invoiceId) { data.metadata = { invoice_id: invoiceId } }

      const config = {
        method: 'post',
        url: `${clientInfo?.url}stripe/create-checkout-session`,
        headers: {
          Authorization: token
        },
        data
      }
      axios(config)
        .then(res => resolve(res.data?.clientSecret))
    })
  }, [])

  const options = { fetchClientSecret }

  return (
		<div id="checkout" style={{ width: '100%', height: '100%' }}>
			{
				afterCheckout
				  ? <div style={{
				    width: '100%',
				    height: '100%',
				    display: 'flex',
				    alignItems: 'center',
				    justifyContent: 'center',
				    flexDirection: 'column'
				  }}>
						<CheckMark/>
						<label style={{ width: '100%', textAlign: 'center', color: Colors.theme }}>Payment has been approved. You can
							now close this window.</label>
					</div>
				  : <EmbeddedCheckoutProvider
						stripe={stripePromise}
						options={options}
					>
						<EmbeddedCheckout/>
					</EmbeddedCheckoutProvider>
			}
		</div>
  )
}

export default StripeCheckout
