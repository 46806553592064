import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_CALL_STATUS } from '../../metadata/enums'
import { ADD_NEW_VEHICLE, FETCH_ALL_VEHICLES, GET_ALL_MAINTENANCES, UPDATE_VEHICLE } from '../actionTypes'
import axios from 'axios'
import Config from '../../config'
import { LOGGER } from '../../utils/Logger'

const initialState = {
  vehicles: [],
  maintenances: [],
  status: API_CALL_STATUS.IDLE,
  new_vehicle: false,
  updated_vehicle: false,
  error: null,
  vehicle_to_update: null,
  maintenance_to_update: null
}

export const fetchAllMaintenances = createAsyncThunk(GET_ALL_MAINTENANCES, async (payload) => {
  const config = {
    method: 'get',
    url: `${Config.BACKEND_URL}maintenances`,
    headers: { Authorization: payload.token }
  }
  try {
    const res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting maintenances', err)
    return []
  }
})

export const fetchAllVehicles = createAsyncThunk(FETCH_ALL_VEHICLES, async (payload) => {
  const config = {
    method: 'get',
    url: `${payload.backendUrl}vehicles`,
    headers: { Authorization: payload.token }
  }
  try {
    const res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting vehicles', err)
    return []
  }
})

export const addVehicleAndConfirm = createAsyncThunk(ADD_NEW_VEHICLE, async (payload) => {
  try {
    const config = {
      method: 'post',
      url: `${Config.BACKEND_URL}vehicles`,
      headers: { Authorization: payload.token, contentType: 'application/json' },
      data: payload.data
    }

    const res = await axios(config)
    const data = Object.assign({}, payload.data)
    data._id = res.data?._id
    return data
  } catch (err) {
    LOGGER.log('error when adding new vehicle', err)
  }

  // return order
})

export const updateVehicleAndConfirm = createAsyncThunk(UPDATE_VEHICLE, async (payload) => {
  try {
    const id = payload.data._id
    const url = encodeURI(`${Config.BACKEND_URL}vehicles/${id}`)
    const config = {
      method: 'put',
      url,
      headers: { Authorization: payload.token, contentType: 'application/json' },
      data: payload.data
    }

    console.log('updating', id, payload.data)

    const res = await axios(config)
    return payload.data
  } catch (err) {
    LOGGER.log('error when adding new reservation', err)
  }

  // return order
})

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    updateNewVehicleStatus (state, action) {
      return Object.assign({}, state, { new_vehicle: action.payload })
    },
    setUpdatedStatus (state, action) {
      return Object.assign({}, state, { updated_vehicle: false })
    },
    updateVehicle (state, action) {
      return Object.assign({}, state, { vehicle_to_update: action.payload })
    },
    updateMaintenanceToUpdate (state, action) {
      return Object.assign({}, state, { maintenance_to_update: action.payload })
    },
    addNewMaintenance (state, action) {
      const temp = [...state.maintenances]
      temp.unshift(action.payload)
    },
    updateMaintenance (state, action) {
      const newArray = []
      state.maintenances.forEach(entry => {
        if (entry._id === action.payload._id) { newArray.push(action.payload) } else { newArray.push(entry) }
      })

      return Object.assign({}, state, { maintenances: newArray })
    }
  },
  extraReducers (builder) {
    builder
      .addCase(addVehicleAndConfirm.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(addVehicleAndConfirm.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.vehicles.unshift(action.payload)
        state.new_vehicle = true
      })
      .addCase(addVehicleAndConfirm.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(fetchAllVehicles.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(fetchAllVehicles.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.vehicles = action.payload
      })
      .addCase(fetchAllVehicles.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(fetchAllMaintenances.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(fetchAllMaintenances.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.maintenances = action.payload
      })
      .addCase(fetchAllMaintenances.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(updateVehicleAndConfirm.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateVehicleAndConfirm.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        const temp = []
        state.vehicles.forEach(vehicle => {
          if (vehicle._id === action.payload._id) { temp.push(Object.assign({}, vehicle, action.payload)) } else { temp.push(vehicle) }
        })

        state.vehicles = temp
        state.updated_vehicle = true
      })
      .addCase(updateVehicleAndConfirm.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
  }

})

export const vehiclesMapSelector = (state) => {
  if (state.vehicles.vehicles && state.vehicles.vehicles.length > 0) {
    const temp = {}
    state.vehicles.vehicles.forEach(vehicle => temp[vehicle.id] = vehicle)
    return temp
  } else {
    return {}
  }
}

export const {
  updateNewVehicleStatus,
  setUpdatedStatus,
  updateVehicle,
  updateMaintenance,
  updateMaintenanceToUpdate,
  addNewMaintenance
} = vehiclesSlice.actions

export default vehiclesSlice.reducer
