import { Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/styles'
import Colors from '../../config/colors'
import { useSelector } from 'react-redux'
import { getIntegrationSettings } from '../../redux/slices/settingsSlice'

const CarCard = ({ vehicle, pickupDate, pickupTime, returnDate, returnTime, totalDays, location }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '78%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '95%',
      marginTop: '25px'

    },
    paper: {
      width: '90%',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '20px',
      border: '1px solid white'

    },
    card: {
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      border: '1px solid white'
    },

    fragment: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      lineHeight: '10px',
      height: '100%',
      margin: 'auto',
      width: '100%'
    },
    box: {
      width: '100%'
    },
    typography: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    button: {
      width: '100%',
      padding: '10px',
      fontWeight: 'bold',
      fontSize: '18px',
      borderRadius: '10px'
    },
    box2: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column'
    },
    box3: {
      display: 'flex',
      width: '80%'
    },
    typography2: {
      display: 'flex',
      width: '95%',
      justifyContent: 'space-between',
      padding: '5px'
    }
  }))
  const classes = useStyles()
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))

  const [onlinePriceSettings, setOnlinePriceSettings] = useState(null)

  const [subTotal, setSubTotal] = useState(0)
  const [cardFee, setCardFee] = useState(0)
  const [salesFee, setSalesFee] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [locationFee, setLocationFee] = useState(0)

  useEffect(() => {
    if (integrationSettings?.websiteIntegration) {
      setOnlinePriceSettings(integrationSettings.websiteIntegration)
    }
  }, [integrationSettings])

  useEffect(() => {
    if (vehicle && onlinePriceSettings) {
      const subTotal = Number(vehicle?.details?.daily_price) * totalDays
      let tempLocFee = 0
      if (typeof location !== 'string') {
        tempLocFee = Number(onlinePriceSettings?.location_fee)
      }
      setLocationFee(tempLocFee)
      setSubTotal(subTotal)
      setCardFee(onlinePriceSettings?.card_fee ? (((subTotal + tempLocFee) * onlinePriceSettings?.card_fee) / 100) : 0)
      setSalesFee(((subTotal + tempLocFee) * onlinePriceSettings?.sales_tax) / 100)
      setServiceFee(((subTotal + tempLocFee) * onlinePriceSettings?.service_tax.rentals) / 100)
      setTotalTax(Number(onlinePriceSettings?.service_tax.rentals) + Number(onlinePriceSettings?.sales_tax))
    }
  }, [pickupDate, returnDate, pickupTime, returnTime, vehicle, onlinePriceSettings, location])

  const card = (
		<React.Fragment>
			<CardContent className={`${classes.fragment} carCardContent`}>
				<label style={{ fontSize: 24, color: Colors.themeLight }}>{vehicle?.make}</label>

				<Box className={classes.box3} id="flex-col">
					<div style={{ height: '100%' }}>
						<img src={vehicle?.pictureUrl} width={300} height={170}/>
					</div>
					<div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						<Typography className={classes.typography} sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
							<div style={{ display: 'flex' }}>
								<div style={{ color: Colors.themeLight, fontSize: '24px' }}>
									${vehicle?.details?.daily_price}
								</div>
								<div style={{ fontSize: '18px', marginTop: '7px', color: Colors.themeLight, marginLeft: 2 }}>
									/day
								</div>
							</div>

						</Typography>

						<Typography className={classes.typography} sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
							<div style={{ display: 'flex', width: '100%', color: Colors.secondaryTextColor }}>
								{vehicle?.details?.mileage_allowance} free miles /day
							</div>
						</Typography>

						<Typography className={classes.typography} sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
							<div style={{ display: 'flex', width: '100%', color: Colors.secondaryTextColor }}>
								${vehicle?.details?.mileage_overage_price} /extra mile
							</div>
						</Typography>

					</div>
				</Box>

				<Box className="w-90">
					<Typography className={classes.typography}
					            sx={{ fontSize: 18, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
					            color="text.secondary" gutterBottom>
						<div style={{ color: Colors.secondaryTextColor }}>
							{typeof location === 'string' ? location : location?.label}
						</div>
					</Typography>
					<Typography className={classes.typography} sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
						<div style={{ color: Colors.secondaryTextColor }}>
							<span style={{ color: Colors.theme }}>{pickupDate}</span> @ <span
							style={{ color: Colors.theme }}>{pickupTime}</span> to <span
							style={{ color: Colors.theme }}>{returnDate}</span> @ <span
							style={{ color: Colors.theme }}>{returnTime}</span>

						</div>
					</Typography>
				</Box>

				<div style={{ width: '95%', height: '1px', backgroundColor: 'rgba(0,0,0,0.3)' }}></div>

				<div style={{
				  marginTop: '5px',
				  flexDirection: 'row',
				  color: Colors.secondaryTextColor,
				  justifyContent: 'space-between',
				  display: 'flex',
				  width: '90%',
				  fontSize: 18
				}}>
              <span>
                ${vehicle?.details?.daily_price} &times; {totalDays} day(s)
              </span>
					<span>
                ${Number(subTotal).toFixed(2)}
              </span>
				</div>

				{
					locationFee && locationFee > 0
					  ? <div style={{
					    marginTop: '5px',
					    flexDirection: 'row',
					    color: Colors.secondaryTextColor,
					    justifyContent: 'space-between',
					    display: 'flex',
					    width: '90%',
					    fontSize: 18
					  }}>
                  <span>
                    Custom Delivery Fee
                  </span>
							<span>
                    ${Number(locationFee).toFixed(2)}
                  </span>
						</div>
					  : null
				}

				<div
					style={{
					  width: '100%',
					  height: '1px',
					  backgroundColor: 'rgba(0,0,0,0.2)'
					}}
				></div>

				<div style={{
				  marginTop: '5px',
				  flexDirection: 'row',
				  color: Colors.secondaryTextColor,
				  justifyContent: 'space-between',
				  display: 'flex',
				  width: '90%',
				  fontSize: 18
				}}>
					<span>Sub Total</span>
					<span>${Number(subTotal + locationFee).toFixed(2)}</span>
				</div>

				<div style={{
				  marginTop: '5px',
				  flexDirection: 'row',
				  color: Colors.secondaryTextColor,
				  justifyContent: 'space-between',
				  display: 'flex',
				  width: '90%',
				  fontSize: 18
				}}>
					<span>Taxes & Fees ({totalTax}%)</span>
					<span>${Number(salesFee + serviceFee).toFixed(2)}</span>
				</div>

				{
					cardFee && cardFee > 0
					  ? <div style={{
					    marginTop: '5px',
					    flexDirection: 'row',
					    color: Colors.secondaryTextColor,
					    justifyContent: 'space-between',
					    display: 'flex',
					    width: '90%',
					    fontSize: 18
					  }}>
							<span>Card Fees ({onlinePriceSettings?.card_fee}%)</span>
							<span>${Number(cardFee).toFixed(2)}</span>
						</div>
					  : null
				}

				<div
					style={{
					  width: '100%',
					  height: '1px',
					  backgroundColor: 'rgba(0,0,0,0.2)'
					}}
				></div>

				<div style={{
				  flexDirection: 'row',
				  color: Colors.themeLight,
				  justifyContent: 'space-between',
				  display: 'flex',
				  width: '90%',
				  fontSize: 22,
				  marginBottom: 10
				}}>
					<span>Total Charges</span>
					<span>
                $
						{Number(subTotal + locationFee + salesFee + serviceFee + cardFee).toFixed(2)}
              </span>
				</div>
			</CardContent>

		</React.Fragment>
  )

  return (
		<div className={`${classes.container} carContainerDiv`}>
			<Paper elevation={3} className={classes.paper} id='w-90'>

				<Card variant="outlined" className={classes.card}>{card}</Card>
			</Paper>
		</div>
  )
}

export default CarCard
