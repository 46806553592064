import React, { useEffect, useState } from 'react'
import GeneralStyles from '../styles/GeneralStyles.module.scss'
import ClientsMap from '../../metadata/ClientsMap'
import CustomLoaderSpinner from '../../components/CustomLoaderSpinner'
import colors from '../../config/colors'
import { createTheme } from '@mui/material/styles'
import axios from 'axios'
import { LOGGER } from '../../utils/Logger'
import Styles from './styles/PaymentInvoice.module.scss'
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { TextField } from '@material-ui/core'
import Constants from '../../metadata/constants'
import Helpers from '../../utils/Helpers'
import Button from '@mui/material/Button'
import { auth } from '../../services/firebase'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import Config from '../../config'
import { useSearchParams } from 'react-router-dom'

const WINDOW_WIDTH = window.innerWidth
const WINDOW_HEIGHT = window.innerHeight

const theme = createTheme({
  palette: {
    primary: {
      main: colors.buttonTheme,
      dark: '#A996FF66',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ffffff00',
      dark: '#ffffff66',
      contrastText: '#fff',
      borderColor: '#fff'
    },
    tertiary: {
      main: colors.themeLight,
      dark: '#BFBEFC66',
      contrastText: '#000',
      borderColor: '#fff'
    },
    white: {
      main: colors.primaryTextColor,
      dark: '#FCFCFC66',
      contrastText: '#000'
    }
  }
})

let businessInfo = null

const PaymentInvoice = (props) => {
  const params = new URLSearchParams(window.location.search)
  const invoiceId = params.get('invoiceId')
  const random = params.get('random')
  const bus = params.get('bus')

  const [searchParams, setSearchParams] = useSearchParams()

  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [invoice, setInvoice] = useState(null)
  const [reservation, setReservation] = useState(null)

  const [cardName, setCardName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvv, setCVV] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('USA')
  const [cardType, setCardType] = useState({ type: 'unknown', image: null })
  const [vehicleDetails, setVehicleDetails] = useState(null)

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  useEffect(() => {
    console.log('inc', invoiceId, bus, random)
    if (!invoiceId || !random || !bus) {
      console.log('invoice id', invoiceId, random, bus)
      window.alert('invalid link')
    } else {
      const client = ClientsMap[bus]
      if (!client) {
        window.alert('Cannot find business information')
        return
      }
      businessInfo = client
      signIn()
    }
  }, [])

  const getVehicleDetails = async (url, token, vehicleId) => {
    const config = {
      method: 'get',
      url: `${url}vehicles/${vehicleId}`,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      if (res.data?.id) {
        setVehicleDetails(res.data)
      } else {
        window.alert('Error when getting details')
      }
    } catch (err) {
      LOGGER.error('Error when getting vehicle details', err)
      window.alert(`Error when getting invoice details : ${err.response?.data?.error}`)
    }
  }

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (authUser) => {
      console.log('auth state cahnged', businessInfo)
      if (authUser) {
        const authToken = await authUser.getIdToken(true)
        getInvoice(businessInfo?.url, authToken, invoiceId)
        setToken(authToken)
      } else {
        signIn()
      }
    })
    return listener
  }, [])

  const getInvoice = async (url, token, id) => {
    const config = {
      method: 'get',
      url: `${url}invoices/${id}`,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      const invoice = res.data
      console.log('invoice', invoice)
      if (invoice && invoice.res_id) {
        setInvoice(invoice)
        getReservation(url, token, invoice?.res_id)
        getVehicleDetails(url, token, invoice.vehicle_id)
      }
    } catch (err) {
      LOGGER.error('Error when getting invoice', err)
      window.alert(`Error when getting invoice details : ${err.response?.data?.error}`)
    }
  }

  const signIn = () => {
    const user = auth.currentUser
    console.log('user is', user)
    if (user) {
      return
    }

    const random = searchParams.get('random')
    signInWithEmailAndPassword(auth, Config.ADMIN_CREDS[0], random)
      .then((userCredential) => {
        // Signed in
        console.log('successfully signed in')
      }).catch(err => {
        console.log('error when signing in user', err)
        window.alert('Something went wrong, please contact support if the problem persists')
      })
  }

  const handleCardChange = (text) => {
    const spacedNumber = Helpers.getCardSpacedNumbers(text)
    setCardType(Helpers.getCardType(spacedNumber))
  }

  const getReservation = async (url, token, id) => {
    const config = {
      method: 'get',
      url: `${url}reservations/${id}`,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      const reservation = res.data
      console.log('reservation', reservation)
      setReservation(reservation)
      setIsLoading(false)
    } catch (err) {
      LOGGER.error('Error when getting reseration', err)
      window.alert(`Error when getting reservation details : ${err.response?.data?.error}`)
      return []
    }
  }

  const placeSecurityHoldNewCard = async () => {
    setUploading(true)
    try {
      const holdResponse = await placeHold()
      if (reservation?.client_details?.customerProfileId) {
        console.log('customer profile exists')
        createCustomerPaymentProfile()
          .then(() => {
            paymentSuccessCallback()
          }).catch(err => {
            LOGGER.error('error when creating payment profile', err)
            paymentSuccessCallback()
          })
      } else {
        console.log('customer profile needs to be created')
        createCustomerProfile()
          .then(() => {
            paymentSuccessCallback()
          }).catch(err => {
            LOGGER.error('error when creating payment profile', err)
            paymentSuccessCallback()
          })
      }
    } catch (err) {
      setErrorMessage('Something went wrong')
      setShowError(true)
      setUploading(false)
      setPaymentSuccess(false)
      LOGGER.error('Error when placing auth hold on card', err)
      window.alert('Something went wrong when placing the authorization hold. Please contact support if the problem persists.')
    }
  }

  const chargeNewCardForCustomer = async () => {
    setUploading(true)
    try {
      const chargeResponse = await chargeCard()
      if (reservation?.client_details?.customerProfileId) {
        console.log('customer profile exists')
        createCustomerPaymentProfile()
          .then(() => {
            paymentSuccessCallback()
          }).catch(err => {
            LOGGER.error('error when creatung payment profile', err)
            paymentSuccessCallback()
          })
      } else {
        console.log('customer profile needs to be created')
        createCustomerProfile()
          .then(() => {
            paymentSuccessCallback()
          }).catch(err => {
            LOGGER.error('error when creatung payment profile', err)
            paymentSuccessCallback()
          })
      }
    } catch (err) {
      setErrorMessage('Something went wrong')
      setShowError(true)
      setUploading(false)
      setPaymentSuccess(false)
      LOGGER.error('Error when charging card', err)
      window.alert('Something went wrong when charging the card. Please contact support if the problem persists.')
    }
  }

  const paymentSuccessCallback = () => {
    setUploading(false)
    setPaymentSuccess(true)
    setInvoice(Object.assign({}, invoice, { paid: new Date() }))
    const chargeConfig = {
      method: 'put',
      url: `${businessInfo?.url}invoices/${invoice?._id}`, // replace with config backend url
      data: {
        paid: new Date()
      },
      headers: { Authorization: token }
    }
    axios(chargeConfig)
      .then(() => console.log('Updated invoice status'))
      .catch(err => LOGGER.error('error when updating invoice to paid status', err))
  }

  const chargeCard = async () => new Promise(async (resolve, reject) => {
    const salesPerson = 'Payment Invoice'
    const payload = {
      firstName: cardName.split(' ', 2)[0],
      lastName: cardName.split(' ', 2)[1],
      clientId: reservation?.client,
      reservationId: reservation?._id,
      vehicleId: reservation?.vehicle_id,
      address: billingAddress,
      city,
      state,
      zip,
      country,
      salesPerson,
      invoiceId: invoice?._id,
      payment: {
        amount: Number(invoice?.amount),
        creditCard: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: expiry.replace('/', ''),
          cardCode: cvv
        }
      }
    }

    const chargeConfig = {
      method: 'post',
      url: `${businessInfo?.url}payments/online/balance`, // replace with config backend url
      data: payload,
      headers: { Authorization: token }
    }

    try {
      const chargeResponse = await axios(chargeConfig)
      if (chargeResponse.data?._id) {
        LOGGER.log('successfully charged the card')
        LOGGER.log('charge card response > ', chargeResponse)
        resolve(chargeResponse.data)
      }
    } catch (err) {
      LOGGER.error('Error when charging card', err)
      reject(err)
    }
  })

  const createCustomerProfile = async () => new Promise(async (resolve, reject) => {
    const customerPayload = {
      firstName: cardName.split(' ', 2)[0],
      lastName: cardName.split(' ', 2)[1],
      clientId: reservation?.client,
      address: billingAddress,
      city,
      state,
      zip,
      country,
      customerData: {
        customerId: reservation?.client,
        email: reservation?.client_details?.email,
        creditCard: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: expiry.replace('/', '')
        }
      }
    }
    const customerConfig = {
      method: 'post',
      url: `${businessInfo?.url}createCustomerProfile`,
      data: customerPayload,
      headers: { Authorization: token }
    }

    try {
      const customerResponse = await axios(customerConfig)
      LOGGER.log('createCustomerProfile', customerResponse.data)
      if (customerResponse.data.messages.resultCode === 'Ok') {
        resolve()
      } else if (customerResponse.data?.messages?.message[0]?.code === 'E00039') {
        reject('duplicate')
      } else {
        reject('unknown error')
      }
    } catch (err) {
      LOGGER.error('Error when creating customer profile', err)
      reject(err)
    }
  })

  const createCustomerPaymentProfile = async () => new Promise(async (resolve, reject) => {
    const paymentProfilePayload = {
      firstName: cardName.split(' ', 2)[0],
      lastName: cardName.split(' ', 2)[1],
      clientId: reservation?.client,
      customerProfileId: reservation?.client_details?.customerProfileId,
      address: billingAddress,
      city,
      state,
      zip,
      country,
      payment: {
        creditCard: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: expiry.replace('/', '')
        }
      }
    }

    const paymentProfileConfig = {
      method: 'post',
      url: `${businessInfo?.url}createCustomerPaymentProfile`,
      data: paymentProfilePayload,
      headers: {
        Authorization: token
      }
    }

    LOGGER.log('createCustomerPaymentProfile', paymentProfilePayload)
    try {
      const paymentProfileResponse = await axios(paymentProfileConfig)
      LOGGER.log('createCustomerPaymentProfile', paymentProfilePayload, paymentProfileResponse)
      if (paymentProfileResponse.data?.messages?.resultCode === 'Ok' ||
				paymentProfileResponse.data?.messages?.message[0]?.code === 'E00039') {
        resolve()
      } else {
        reject('Unknown error')
      }
    } catch (err) {
      LOGGER.error('Error when creating customer payment profile', err)
      reject(err)
    }
  })

  const validateDate = () => {
    if (cardName.length === 0) {
      setErrorMessage('Please enter the full name on the card ')
      return false
    }

    if (!Helpers.validateCardNumber(cardNumber, cvv, expiry)) {
      setErrorMessage('Please enter the valid card details')
      return false
    }

    if (!billingAddress || billingAddress.length === 0) {
      setErrorMessage('Please enter billing address')
      return false
    }

    if (!city || city.length === 0) {
      setErrorMessage('Please enter billing city')
      return false
    }

    if (!state || state.length === 0) {
      setErrorMessage('Please enter billing state')
      return false
    }

    if (!zip || zip.length === 0) {
      setErrorMessage('Please enter billing zip')
      return false
    }

    if (!country || country.length === 0) {
      setErrorMessage('Please enter billing country')
      return false
    }

    return true
  }

  const handleSubmit = () => {
    if (!validateDate()) {
      setShowError(true)
      return
    }

    const text = invoice?.isSecurityHold ? `Are you sure you want to place a security hold of $${Number(invoice.amount).toFixed(2)} on your card?` : `Are you sure you want to pay $${Number(invoice.amount).toFixed(2)} from your card?`
    if (!window.confirm(text)) {
      return
    }

    setShowError(false)
    setUploading(true)
    if (invoice.isSecurityHold) {
      placeSecurityHoldNewCard()
    } else {
      chargeNewCardForCustomer()
    }
  }

  const placeHold = async () => new Promise(async (resolve, reject) => {
    setUploading(true)
    const salesPerson = 'Payment Invoice'
    const payload = {
      firstName: cardName.split(' ', 2)[0],
      lastName: cardName.split(' ', 2)[1],
      clientId: reservation?.client,
      reservationId: reservation?._id,
      vehicleId: reservation?.vehicle_id,
      address: billingAddress,
      city,
      state,
      zip,
      country,
      salesPerson,
      invoiceId: invoice?._id,
      payment: {
        amount: Number(invoice?.amount),
        creditCard: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          expirationDate: expiry.replace('/', ''),
          cardCode: cvv
        }
      }
    }
    const config = {
      method: 'post',
      url: `${businessInfo?.url}payments/online/deposit`,
      data: payload,
      headers: { Authorization: token }
    }

    try {
      const res = await axios(config)
      LOGGER.log('auth card resp > ', res)
      if (res.data?._id) {
        resolve(res.data)
      }
    } catch (err) {
      LOGGER.error('Something went wrong when placing auth hold on card', err)
      setErrorMessage('Could not place security hold')
      setShowError(true)
      setUploading(false)
      setPaymentSuccess(false)
    }
  })

  const renderStripeCheckout = () => {
    if (!vehicleDetails) {
      return null
    }

    const salesPerson = 'Payment Invoice'

    const url =
			`${Config.CONTRACT_URL}stripe/checkout?invoiceId=${invoiceId}&bus=${bus}&email=${invoice?.email}&productId=${vehicleDetails?.stripe?.product_id}&token=${token}&clientId=${invoice?.client_id}&vehicleId=${invoice.vehicle_id}&resId=${invoice?.res_id}&salesPerson=${salesPerson}&isSecurity=${invoice?.isSecurityHold}&amount=${invoice?.amount}`
    console.log('url is', url)

    return (
			<div style={{
			  width: '100%',
			  height: '100%',
			  display: 'flex',
			  flexDirection: 'column',
			  alignItems: 'center',
			  marginTop: 50
			}}>
				<iframe
					style={{ borderWidth: 0 }}
					src={url}
					height={window.innerHeight}
					width={window.innerWidth - 100}/>
			</div>
    )
  }

  const renderCardDetails = () => {
    if (uploading) {
      return (
				<div style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'row',
				  justifyContent: 'center',
				  marginTop: 100
				}}>
					<CustomLoaderSpinner text={invoice?.isSecurityHold ? 'placing security hold ' : 'making payment'}/>
				</div>
      )
    }

    if (paymentSuccess) {
      return (
				<div style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'column',
				  alignItems: 'center',
				  marginTop: 50
				}}>
					<CheckCircleIcon style={{
					  width: 40,
					  height: 40,
					  color: 'green'
					}}/>
					<p
						style={{ color: colors.theme }}>{invoice?.isSecurityHold ? 'Successfully placed authorization hold on your card' : `Successfully captured the payment of $${Number(invoice?.amount).toFixed(2)}`}</p>
					<p style={{ color: colors.secondaryTextColor, marginTop: 20 }}>You can now close this window</p>
				</div>
      )
    }

    if (invoice?.paid) {
      return (
				<div style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'column',
				  alignItems: 'center',
				  marginTop: 50
				}}>
					<CheckCircleIcon style={{
					  width: 40,
					  height: 40,
					  color: 'green'
					}}/>
					<p style={{ color: colors.theme }}>This invoice is already settled.</p>
				</div>
      )
    }
    return (
			<div className={Styles.cardDetailsWrapper}>
				{
					showError &&
					<div
						style={{ width: '100%', marginTop: 10, display: 'flex', alignItems: 'center' }}>
						<label style={{ width: '100%', textAlign: 'center', color: 'red' }}>{errorMessage}</label>
					</div>
				}
				<TextField
					variant='outlined'
					type="text"
					id="cardName"
					size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
					placeholder='NAME ON CARD*'
					style={{ width: '100%', marginTop: 20 }}
					value={cardName}
					onChange={(e) => setCardName(e.target.value)}
					required
				/>

				<TextField
					variant='outlined'
					type="text"
					id="cardNumber"
					size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
					placeholder='CARD NUMBER*'
					style={{ width: '100%' }}
					value={cardNumber}
					onChange={(e) => {
					  const text = e.target.value
					  if (text.length < 20) {
					    const value = text
					      .replace(/[^0-9]/gi, '')
					      .replace(/(.{4})/g, '$1 ')
					      .trim()
					    setCardNumber(value)
					  }
					  handleCardChange(text)
					}}
					required
					InputProps={{ inputProps: { maxLength: 19 } }}
				/>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<TextField
						variant='outlined'
						type="text"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						style={{ width: '50%' }}
						id="expiry"
						placeholder='EXPIRY MM/YY'
						value={expiry}
						onChange={(e) => {
						  const text = e.target.value
						  const number = text.replace('/', '')
						  if (isNaN(number)) {
						    return
						  }
						  setExpiry(Helpers.getFormattedExpiry(text, expiry))
						}}
						required
						InputProps={{ inputProps: { maxLength: 5 } }}
						pattern="/^-?\d+\.?\d*$/"
					/>

					<TextField
						variant='outlined'
						type="text"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						style={{ width: '50%' }}
						id="cvv"
						placeholder='CVV*'
						value={cvv}
						onChange={(e) => {
						  if (isNaN(e.target.value)) {
						    return
						  }
						  setCVV(e.target.value)
						}}
						required
						InputProps={{ inputProps: { maxLength: 4 } }}
						pattern="/^-?\d+\.?\d*$/"
					/>
				</div>
				<TextField
					variant='outlined'
					type="text"
					id="address"
					size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
					placeholder='BILLING ADDRESS*'
					style={{ width: '100%' }}
					value={billingAddress}
					onChange={(e) => setBillingAddress(e.target.value)}
					required
				/>
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<TextField
						variant='outlined'
						type="text"
						id="city"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						placeholder='BILLING CITY*'
						style={{ width: '100%' }}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						required
					/>
					<TextField
						variant='outlined'
						type="text"
						id="state"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						placeholder='BILLING STATE*'
						style={{ width: '100%' }}
						value={state}
						onChange={(e) => setState(e.target.value)}
						required
					/>
				</div>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<TextField
						variant='outlined'
						type="text"
						id="zip"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						placeholder='BILLING ZIP*'
						style={{ width: '100%' }}
						value={zip}
						onChange={(e) => setZip(e.target.value)}
						required
					/>
					<TextField
						variant='outlined'
						type="text"
						id="country"
						size={WINDOW_WIDTH < Constants.breakpoint ? 'small' : 'medium'}
						placeholder='BILLING COUNTRY*'
						style={{ width: '100%' }}
						value={country}
						onChange={(e) => setCountry(e.target.value)}
						required
					/>
				</div>

				<Button onClick={handleSubmit} style={{
				  width: '100%',
				  padding: '10px',
				  backgroundColor: '#1976d2',
				  color: 'white',
				  cursor: 'pointer',
				  marginTop: 40
				}}>
					{
						invoice && invoice.isSecurityHold ? 'Authorize Hold' : 'Make Payment'
					}
				</Button>
			</div>
    )
  }

  const renderDetails = () => {
    const { created_at, amount, description, paid } = invoice
    const { vehicle_details, client_details, pickup_datetime, dropoff_datetime } = reservation
    return (
			<div className={Styles.container}>
				<div className={Styles.imageContainer}>
					<img src={vehicle_details?.pictureUrl} width={'100%'} height={'100%'}
							 style={{ borderRadius: 10 }}/>
				</div>

				<div style={{
				  marginTop: 20,
				  width: '100%',
				  display: 'flex',
				  flexDirection: 'column',
				  alignItems: 'center'
				}}>
					<label className={Styles.carText}>{vehicle_details?.make}</label>
					<div style={{
					  width: '100%',
					  display: 'flex',
					  flexDirection: 'row',
					  alignItems: 'center',
					  justifyContent: 'center',
					  marginTop: 4
					}}>
						<label
							className={Styles.dateText}>{moment(pickup_datetime).format('MMM Do, YYYY hh:mm A')}</label>
						<label className={Styles.dateText} style={{ marginRight: 10, marginLeft: 10 }}>-</label>
						<label
							className={Styles.dateText}>{moment(dropoff_datetime).format('MMM Do, YYYY hh:mm A')}</label>
					</div>

					<div className={Styles.invoiceDetailsWrapper}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<label className={Styles.detailsLabel}>Invoice Date & Time</label>
							<label
								className={Styles.detailsValue}>{moment(created_at).format('MMM Do, YYYY hh:mm A')}</label>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
							<label className={Styles.detailsLabel}>Invoice Amount</label>
							<label
								className={Styles.detailsValue}>${Number(amount).toFixed(2)}</label>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
							<label className={Styles.detailsLabel}>Invoice Type</label>
							<label
								className={Styles.detailsValue}>{invoice?.isSecurityHold ? 'Authorization Hold' : 'Payment'}</label>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
							<label className={Styles.detailsLabel}>Invoice Status</label>

							{
								paid
								  ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<label
											className={Styles.detailsValue}>{`Paid \r\n ${moment(paid).format('MMM Do, YYYY hh:mm A')}`}</label>
										<CheckCircleIcon style={{
										  width: 18,
										  height: 18,
										  color: 'green',
										  marginLeft: 6
										}}/>
									</div>
								  : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<label className={Styles.detailsValue}>Not Paid</label>
										<CancelIcon style={{ width: 20, height: 20, color: 'red', marginLeft: 6 }}/>
									</div>

							}
						</div>

					</div>

				</div>

				{/* {renderCardDetails()} */}
				{renderStripeCheckout()}

			</div>
    )
  }

  return (
		<div style={{ width: '100%', height: '100%' }}>
			{isLoading
			  ? (
					<div
						className={GeneralStyles.container}
						style={{
						  alignItems: 'center',
						  justifyContent: 'center',
						  display: 'flex'
						}}
					>
						<CustomLoaderSpinner text={'Loading ...'}/>
					</div>
			    )
			  : renderDetails()
			}
		</div>
  )
}

export default PaymentInvoice
