import React, { useEffect, useState } from 'react'
import ClientsMap from '../../metadata/ClientsMap'
import axios from '../../axios/axios.js'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField } from '@material-ui/core'
import 'react-awesome-slider/dist/styles.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { LOGGER } from '../../utils/Logger'
import Colors from '../../config/colors'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../services/firebase'
import Config from '../../config'
import { addBackendUrl, addBusiness, addToken } from '../../redux/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addReservationDetails } from '../../redux/slices/reservationsSlice'
import { fetchAllVehicles } from '../../redux/slices/vehiclesSlice'
import {
  getBusinessSettings,
  getIntegrationSettings,
  getSettingsData,
  updateViewType
} from '../../redux/slices/settingsSlice'
import { Audio } from 'react-loader-spinner'
import { API_CALL_STATUS, VIEW_TYPES } from '../../metadata/enums'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CustomModal from '../../components/CustomModal'
import { addDays } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import Styles from './styles/Reservation.module.scss'
import { Alert, Snackbar } from '@mui/material'

const INNER_WIDTH = window.innerWidth
const INNER_HEIGHT = window.innerHeight

const Reservation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme) => ({
    carouselContainer: {
      margin: 'auto',
      width: '90%',
      height: '80%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px'
    },
    image: {
      maxHeight: '200px',
      width: '100%',
      objectFit: 'fit',
      maxWidth: '100%',
      height: 'auto',
      marginBottom: theme.spacing(2)
    },
    card: {
      width: '350px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '20px !important',
      boxShadow: '0px 10px 8px 2px rgba(0, 0, 0, 0.2) !important',
      borderBottom: '5px solid rgba(0, 0, 0, 0.2)'
    },
    carDetails: {
      display: 'flex',
      width: '300px',
      justifyContent: 'space-between',
      padding: '10px',
      height: '40px'
    },
    paper2: {
      width: '100%',
      height: '80%'
    },
    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)'
    },

    DateCalendar: {
      padding: '0px !important'
    },
    overlay: {
      position: 'fixed',
      display: 'none',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 100,
      cursor: 'pointer'
    }
  }))
  const [searchParams, setSearchParams] = useSearchParams()

  const token = useSelector(state => state.auth.token)
  const backendUrl = useSelector(state => state.auth.backend_url)
  const businessSettings = useSelector(state => getBusinessSettings(state.settings))
  const reservationDetails = useSelector(state => state.reservations.reservation_details)
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))
  const vehiclesState = useSelector(state => state.vehicles)

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [vehicles, setVehicles] = useState([])
  const [previouslySelected, setPreviouslySelected] = useState(null)
  const [changeVehicleClicked, setChangeVehicleClicked] = useState(false)
  const [fetchingBlockedVehicles, setFetchingBlockedVehicles] = useState(false)
  const reservationState = useSelector((state) => state.reservations)
  const [forbiddenVehicle, setForbiddenVehicle] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [availableVehicles, setAvailableVehicles] = useState([])
  const [unavailableVehicles, setUnavailableVehicles] = useState([])
  const [timeUpdated, setTimeUpdated] = useState(false)
  const [gracePeriod, setGracePeriod] = useState(0)
  const [minDuration, setMinDuration] = useState(0)
  const [viewType, setViewType] = useState(VIEW_TYPES.list)
  const [showDateModal, setShowDateModal] = useState(false)

  useEffect(() => {
    const bus = searchParams.get('bus')
    const random = searchParams.get('random')
    const view = searchParams.get('view')

    if (view === 'dropdown') {
      setViewType(VIEW_TYPES.dropdown)
      dispatch(updateViewType(VIEW_TYPES.dropdown))
    } else {
      setViewType(VIEW_TYPES.list)
      dispatch(updateViewType(VIEW_TYPES.list))
    }
    if (!bus || !random) {
      window.alert('invalid link')
    } else {
      const client = ClientsMap[bus]
      if (!client) {
        window.alert('Cannot find business information')
        return
      }
      dispatch(addBackendUrl(client.url))
      dispatch(addBusiness(bus))
    }
  }, [])

  useEffect(() => {
    if (vehiclesState.status === API_CALL_STATUS.SUCCEEDED) {
      console.log('got vehicles', vehiclesState)
      const temp = []
      vehiclesState.vehicles.forEach(vehicle => {
        if (vehicle.status === false || vehicle.online_status === false) {
          return
        }
        temp.push(vehicle)
      })
      setVehicles(temp)
    }
  }, [vehiclesState])

  useEffect(() => {
    if (integrationSettings?.websiteIntegration) {
      const { grace_period, minimum_duration } = integrationSettings.websiteIntegration
      if (grace_period === gracePeriod || minimum_duration === minDuration) {
        return
      }
      if (grace_period && !isNaN(grace_period) && Number(grace_period) > 0) setGracePeriod(grace_period)
      if (minimum_duration && !isNaN(minimum_duration) && Number(minimum_duration) > 0) setMinDuration(minimum_duration)
    }
  }, [integrationSettings])

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (authUser) => {
      console.log('auth state cahnged')
      if (authUser) {
        const authToken = await authUser.getIdToken(true)
        const bus = searchParams.get('bus')
        const client = ClientsMap[bus]
        if (!client) {
          window.alert('Cannot find business information')
          return
        }
        dispatch(addBackendUrl(client.url))
        // fetchVehicles(client.url, authToken)
        dispatch(addToken(authToken))
        dispatch(fetchAllVehicles({ token: authToken, backendUrl: client.url }))
        dispatch(getSettingsData({ token: authToken, backendUrl: client.url }))
      } else {
        signIn()
      }
    })
    return listener
  }, [])

  useEffect(() => {
    if (reservationDetails?.startDate && reservationDetails?.endDate) {
      const { endDate, startDate, pickupTime, returnTime } = reservationDetails
      setStartDate(moment(startDate).toDate())
      setEndDate(moment(endDate).toDate())
      setPickupTime(moment(pickupTime).toDate())
      setReturnTime(moment(returnTime).toDate())
    }
  }, [reservationDetails])

  // const fetchVehicles = async (url, token) => {
  //   const config = {
  //     method: 'get',
  //     url: `${url}vehicles/iframe`,
  //     headers: { Authorization: token }
  //   }
  //
  //   try {
  //     const res = await axios(config)
  //     setVehicles(res.data)
  //   } catch (err) {
  //     LOGGER.error('Error when getting vehicles', err)
  //   }
  // }

  const signIn = () => {
    const user = auth.currentUser
    console.log('user is', user)
    if (user) {
      return
    }

    const random = searchParams.get('random')
    signInWithEmailAndPassword(auth, Config.ADMIN_CREDS[0], random)
      .then((userCredential) => {
        // Signed in
        console.log('successfully signed in')
      }).catch(err => {
        console.log('error when signing in user', err)
      })
  }

  const getBlockingResOrMain = async (isReservation, startDate, endDate) => {
    console.log('start date', startDate, endDate)
    return new Promise((resolve, reject) => {
      let url
      if (isReservation) {
        url = `${backendUrl}reservations/blocking?start_date=${startDate}&end_date=${endDate}`
      } else {
        url = `${backendUrl}maintenances/blocking?start_date=${startDate}&end_date=${endDate}`
      }

      const config = {
        method: 'get',
        url,
        headers: { Authorization: token, contentType: 'application/json' }
      }
      axios(config)
        .then((res) => resolve(res.data))
        .catch((err) => {
          LOGGER.error('error when getting blocing something', err)
          reject(err)
        })
    })
  }

  const [optionsDisabled, setOptionsDisabled] = useState(true)

  // useEffect(() => {
  //   const payload = { token: token };
  //   dispatch(fetchAllVehicles(payload));

  // }, []);
  // const vehicleData = useSelector((state) => state.vehicles.vehicles);

  const classes = useStyles()

  const [startDate, setStartDate] = useState(moment().add(1, 'day').toDate())
  const [endDate, setEndDate] = useState(moment().add(2, 'day').toDate())
  const time = moment().minutes(0).hours(10).toDate()
  const [pickupTime, setPickupTime] = useState(time)
  const [returnTime, setReturnTime] = useState(time)

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection'
  }

  const handleDateSelect = (ranges) => {
    const { selection } = ranges
    const { startDate, endDate } = selection

    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    if (!token || Object.keys(vehicles).length === 0) return
    if (!fetchingBlockedVehicles) asyncFunctions()
  }, [
    timeUpdated,
    startDate,
    endDate,
    reservationState,
    token,
    changeVehicleClicked
  ])

  useEffect(() => {
    if (token && vehicles && vehicles.length > 0) {
      asyncFunctions()
    }
  }, [token, vehicles])

  async function asyncFunctions () {
    setOptionsDisabled(true)
    const finalPickupDatetime = moment(startDate)
      .hours(pickupTime?.getHours())
      .minutes(pickupTime?.getMinutes())
      .toDate()
      .toISOString()
    const finalReturnDatetime = moment(endDate)
      .hours(returnTime?.getHours())
      .minutes(returnTime?.getMinutes())
      .toDate()
      .toISOString()
    if (moment(finalReturnDatetime).diff(finalPickupDatetime, 'hours') < 1) {
      setOptionsDisabled(false)
      return
    }
    setFetchingBlockedVehicles(true)

    const blockingRes = await getBlockingResOrMain(
      true,
      finalPickupDatetime,
      finalReturnDatetime
    )
    const blockingMain = await getBlockingResOrMain(
      false,
      finalPickupDatetime,
      finalReturnDatetime
    )
    setFetchingBlockedVehicles(false)
    const total = blockingRes.concat(blockingMain)

    // dedupe and get only ids
    const forbiddenVehicleIds = total
      .filter(
        (v, i, a) => a.findIndex((v2) => v2.vehicle_id === v.vehicle_id) === i
      )
      .map((temp) => temp.vehicle_id)
    const ava = []
    const unAva = []
    console.log('forbidden', forbiddenVehicleIds)
    setForbiddenVehicle(forbiddenVehicleIds)
    console.log('previous', previouslySelected)
    const GRACE_PERIOD = 0
    Object.values(vehicles).forEach((vehicle) => {
      if (vehicle.status === false || vehicle.online_status === false) {
        unAva.push(vehicle)
      }

      const temp = Object.assign({}, vehicle)
      const blockingReservationsForVehicle = blockingRes.filter(
        (res) => res.vehicle_id === temp.id
      )
      const blockingMaintenancesForVehicle = blockingMain.filter(
        (res) => res.vehicle_id === temp.id
      )
      blockingReservationsForVehicle.sort((a, b) => {
        return new Date(a.dropoff_datetime) - new Date(b.pickup_datetime)
      })

      blockingMaintenancesForVehicle.sort((a, b) => {
        return new Date(a.end_date) - new Date(b.start_date)
      })

      if (forbiddenVehicleIds.includes(temp.id)) {
        temp.available = false
        const lastBlockingReservation = blockingReservationsForVehicle[
          blockingReservationsForVehicle.length - 1
        ] || { dropoff_datetime: moment().subtract(1, 'year').toDate() }
        const lastBlockingMaintenance = blockingMaintenancesForVehicle[
          blockingMaintenancesForVehicle.length - 1
        ] || { end_date: moment().subtract(1, 'year').toDate() }
        if (
          moment(lastBlockingReservation.dropoff_datetime).isAfter(
            moment(lastBlockingMaintenance.end_date)
          )
        ) {
          temp.nextAvailable = moment(lastBlockingReservation.dropoff_datetime)
            .add(GRACE_PERIOD, 'hour')
            .toDate()
          temp.blockingReservation = lastBlockingReservation
        } else {
          temp.nextAvailable = moment(lastBlockingMaintenance.end_date)
            .add(GRACE_PERIOD, 'hour')
            .toDate()
          temp.blockingMaintenance = lastBlockingMaintenance
        }
        if (temp.id === previouslySelected?.id) {
          temp.previous = true
          unAva.unshift(temp)
        } else {
          unAva.push(temp)
        }
      } else {
        temp.available = true
        if (temp.id === previouslySelected?.id) {
          temp.previous = true
          ava.unshift(temp)
        } else {
          ava.push(temp)
        }
      }
    })

    setAvailableVehicles(ava)
    setUnavailableVehicles(unAva)
    setOptionsDisabled(false)
    // setShowLoading(false);
  }

  const getTotalDays = () => {
    const finalPickupDatetime = moment(startDate)
      ?.hours(pickupTime?.getHours())
      ?.minutes(pickupTime?.getMinutes())
    const finalReturnDatetime = moment(endDate)
      ?.hours(returnTime?.getHours())
      ?.minutes(returnTime?.getMinutes())
    const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours')
    if (totalHours <= 24) return 1
    const totalDays = Math.floor(totalHours / 24)
    const reminder = totalHours % 24
    if (reminder > 2) return totalDays + 1
    else return totalDays
  }

  const handleChange = (vehicle, dateSelected) => {
    let address = ''
    if (businessSettings && businessSettings.address) {
      address = `${businessSettings?.address}, ${businessSettings?.city_state} ${businessSettings?.zip}`
    }

    const totalDays = getTotalDays()
    const finalPickupDatetime = moment(startDate).hours(pickupTime?.getHours()).minutes(pickupTime?.getMinutes())
    const finalReturnDatetime = moment(endDate).hours(returnTime?.getHours()).minutes(returnTime?.getMinutes())

    if (dateSelected) {
      if (!vehicle.available) {
        setErrorMessage('This vehicle is not available for selected dates. Please change the dates or choose a different vehicle')
        setShowError(true)
        // window.alert('This vehicle is not available for selected dates. Please change the dates or choose a different vehicle')
        return
      }
      // if the vehicle list page, then we shold check for grace period and minimum period
      if (minDuration && Number(minDuration) > 0) {
        // there is a minimum duration check that need to be done
        if (moment(finalReturnDatetime).diff(finalPickupDatetime, 'hours') < minDuration) {
          setErrorMessage(`The minimum rental period must be ${minDuration} hours`)
          setShowError(true)
          // window.alert(`The minimum rental period must be ${minDuration} hours`)
          return
        }
      }

      if (gracePeriod && Number(gracePeriod) > 0) {
        // there is a minimum duration check that need to be done
        if (moment(finalPickupDatetime).diff(new Date(), 'hours') < gracePeriod) {
          setErrorMessage(`The start time should be at least ${gracePeriod} hours from now`)
          setShowError(true)
          // window.alert(`The start time should be at least ${gracePeriod} hours from now`)
          return
        }
      }
    }

    const data = {
      vehicle,
      pickupLocation: address,
      dropoffLocation: address
    }
    if (dateSelected) {
      // startDate: startDate.toISOString(),
      // endDate: endDate.toISOString(),
      // pickupTime: pickupTime.toISOString(),
      // returnTime: returnTime.toISOString(),
      // totalDays,
      data.startDate = startDate.toISOString()
      data.endDate = endDate.toISOString()
      data.pickupTime = pickupTime.toISOString()
      data.returnTime = returnTime.toISOString()
      data.totalDays = totalDays
    }
    dispatch(addReservationDetails(data))
    const bus = searchParams.get('bus')
    const random = searchParams.get('random')
    navigate(`/iframe/vehicles?vehicleId=${vehicle.id}&bus=${bus}&random=${random}`)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setShowDateModal(true)
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const renderVehicleRow = (vehicle, index) => {
    return (
			<div className={`${Styles.vehicleRow} ${Styles.boxShadow}`} onClick={() => handleChange(vehicle, true)}>
				<div style={{ width: '40%', marginRight: 10, borderRadius: '10px', height: '100%' }}>
					<img src={vehicle.pictureUrl} width={'100%'} height={'100%'}
					     style={{ borderRadius: 10, objectFit: 'contain' }}/>
				</div>
				<div style={{ position: 'relative', height: '100%', width: '100%' }}>
					<div
						style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
						<label className={Styles.vehRowMake}>{vehicle.make}</label>
					</div>

					<div style={{ marginTop: '2%' }}>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
							<label className={Styles.vehRowLabel}>Mileage Allowance</label>
							<label
								className={Styles.vehRowValue}> : {`${vehicle?.details?.mileage_allowance || 100} Miles / Day`}</label>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 2 }}>
							<label className={Styles.vehRowLabel}>Mileage Overage</label>
							<label
								className={Styles.vehRowValue}> : {`$${vehicle?.details?.mileage_overage_price || ''} / Mile`}</label>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 2 }}>
							<label className={Styles.vehRowLabel}>Security Deposit</label>
							<label className={Styles.vehRowValue}> : {`$${vehicle?.details?.deposit || ' '}`}</label>
						</div>
					</div>

					<div style={{
					  display: 'flex',
					  flexDirection: 'row',
					  justifyContent: 'space-between',
					  width: '100%',
					  position: 'absolute',
					  bottom: 0,
					  alignItems: 'flex-end'
					}}>
						<div style={{ display: 'flex', flexDirection: 'row', fontSize: 20, alignItems: 'center' }}>
							<label className={Styles.vehRowMake}>Daily Price: </label>
							<label className={Styles.priceTxt}>{`$${Number(vehicle?.details?.daily_price).toFixed(2)}`}</label>
						</div>

						<label className={Styles.avaLabel}
						       style={{ color: vehicle.available ? Colors.darkTextColor : Colors.secondaryTextColor }}>{vehicle.available ? 'Available' : 'Not Available'}</label>
						<div className={Styles.vehRowBtnWrapper}>
							{
								vehicle.available
								  ? <Button className='w-90 btn' variant="contained"
									          style={{
										          width: '100%',
										          padding: '10px',
										          backgroundColor: Colors.themeLight,
										          color: 'white',
										          cursor: 'pointer',
										          borderRadius: 10
									          }}
									          onClick={() => handleChange(vehicle, true)}>Select Vehicle</Button>
								  : <Button className='w-90 btn' variant="contained"
									          style={{
										          width: '100%',
										          padding: '10px',
										          backgroundColor: Colors.borderColor,
										          color: 'white',
										          cursor: 'pointer',
										          borderRadius: 10
									          }}
									          onClick={null}>Unavailable</Button>
							}
						</div>

					</div>
				</div>

			</div>
    )
  }

  const renderListView = () => {
    if (optionsDisabled) {
      return (
				<div style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'row',
				  justifyContent: 'center',
				  marginTop: 200,
				  backgroundColor: 'transparent'
				}}>
					<Audio
						height="100"
						width="100"
						color={Colors.theme}
						ariaLabel='loading'
					/>
				</div>
      )
    }
    const ava = availableVehicles.map((vehicle, index) => renderVehicleRow(vehicle, index))
    const unAva = unavailableVehicles.map((vehicle, index) => renderVehicleRow(vehicle, index))
    // const elements = []
    // vehicles.forEach((vehicle, index) => {
    //   if (!vehicle.status) return null
    //   else {
    //     elements.push(renderVehicleRow(vehicle, index))
    //   }
    // })
    return (
			<div style={{
			  width: '100%',
			  display: 'flex',
			  flexDirection: 'column',
			  alignItems: 'center',
			  height: '90%',
			  overflowY: 'scroll',
			  paddingBottom: 100,
			  backgroundColor: 'transparent'
			}}>
				{renderDateSelectors()}
				{ava}
				{unAva}
			</div>
    )
  }

  const renderDropdownView = () => {
    return (
			<div
				style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'column',
				  alignItems: 'center',
				  marginTop: 10,
				  backgroundColor: 'transparent'
				}}
			>
				<SearchWithAutoComplete items={vehicles} onItemSelected={handleChange}/>

				{/* eslint-disable-next-line no-tabs */}
			</div>
    )
  }

  const renderDateSelectors = () => {
    return (
			<div className={Styles.dateWrapper}>
				<div className={Styles.indiDateWrapper} style={{ marginBottom: INNER_WIDTH < 768 ? 20 : 0 }}>
					<TextField
						InputLabelProps={{
						  style: { color: Colors.darkTextColor, fontSize: 20, fontWeight: 'bold' }
						}}
						style={{ backgroundColor: 'transparent', height: 'fit-content' }}
						id="outlined-basic"
						label="Start Date*"
						variant="outlined"
						onClick={handleClick}
						value={
							startDate
							  ? startDate.toLocaleDateString(undefined, options)
							  : ''
						}
					/>

					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<TimePicker
							value={pickupTime}
							onChange={(newValue) => {
							  // setSelectedVehicle(null)
							  setPickupTime(newValue)
							}}
							onAccept={() => setTimeUpdated(!timeUpdated)}
							sx={{ backgroundColor: 'white' }}
							renderInput={(params) => {
							  return (
									<TextField
										sx={{
										  '.MuiOutlinedInput-root': {
										    backgroundColor: 'transparent',
										    color: Colors.darkTextColor,
										    height: 30,
										    borderRadius: 5
										  },
										  '.MuiSvgIcon-root': { color: Colors.darkTextColor }
										}}
										InputLabelProps={{
										  style: { color: Colors.darkTextColor }
										}}
										{...params}
									/>
							  )
							}}
						/>
					</LocalizationProvider>
				</div>

				<div className={Styles.indiDateWrapper}>
					<TextField
						InputLabelProps={{
						  style: { color: Colors.darkTextColor, fontSize: 20, fontWeight: 'bold' }
						}}
						style={{ backgroundColor: 'transparent', height: 'fit-content' }}
						id="outlined-basic"
						label="End Date*"
						variant="outlined"
						onClick={handleClick}
						value={
							endDate ? endDate.toLocaleDateString(undefined, options) : ''
						}
					/>

					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<TimePicker
							value={returnTime}
							onChange={(newValue) => {
							  // setSelectedVehicle(null)
							  setReturnTime(newValue)
							}}
							onAccept={() => setTimeUpdated(!timeUpdated)}
							sx={{ backgroundColor: 'white' }}
							renderInput={(params) => {
							  return (
									<TextField
										sx={{
										  '.MuiOutlinedInput-root': {
										    backgroundColor: 'transparent',
										    color: Colors.darkTextColor,
										    height: 30,
										    borderRadius: 5
										  },
										  '.MuiSvgIcon-root': { color: Colors.darkTextColor }
										}}
										InputLabelProps={{
										  style: { color: Colors.darkTextColor }
										}}
										{...params}
									/>
							  )
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>
    )
  }

  const renderContent = () => {
    return (
			<div style={{ height: '100vh', width: '100vw', overflowY: 'hidden', backgroundColor: 'transparent' }}>
				{/* { */}
				{/*    integrationSettings?.websiteIntegration?.website_image_url && */}
				{/*    <img */}
				{/*        alt="pictureUrl" */}
				{/*        src={integrationSettings?.websiteIntegration?.website_image_url} */}
				{/*        style={{ */}
				{/*          width: '100%', */}
				{/*          height: '100%', */}
				{/*          objectFit: 'fit', */}
				{/*          position: 'absolute', */}
				{/*          zIndex: -20, */}
				{/*          opacity: 1 */}
				{/*        }} */}
				{/*    /> */}
				{/* } */}

				{/* <div */}
				{/*  id={'overlay'} */}
				{/*  style={{ */}
				{/*    position: 'fixed', */}
				{/*    width: '100%', */}
				{/*    height: '100%', */}
				{/*    top: 0, */}
				{/*    left: 0, */}
				{/*    right: 0, */}
				{/*    bottom: 0, */}
				{/*    backgroundColor: 'rgba(0, 0, 0, 0.6)', */}
				{/*    zIndex: -1, */}
				{/*    cursor: 'pointer' */}
				{/*  }} */}
				{/* /> */}

				{
					viewType === VIEW_TYPES.dropdown
					  ? renderDropdownView()
					  : renderListView()
				}

				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={showError}
					autoHideDuration={3000}
					onClose={() => setShowError(false)}
				>
					<Alert severity={'error'} sx={{ width: '100%' }}>
						{errorMessage}
					</Alert>
				</Snackbar>

			</div>
    )
  }

  return (
		<div>
			{renderContent()}
			<CustomModal
				show={showDateModal}
				handleClose={() => setShowDateModal(false)}
				containerWidth={window.innerWidth / 2}
				containerHeight={window.innerHeight / 2}
			>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
					<DateRangePicker
						onChange={handleDateSelect}
						months={1}
						minDate={moment().add(gracePeriod, 'hours').toDate()}
						maxDate={addDays(new Date(), 900)}
						direction="vertical"
						scroll={{ enabled: true }}
						ranges={[selectionRange]}
					/>
				</div>
			</CustomModal>
		</div>
  )
}

const SearchWithAutoComplete = ({ items, onItemSelected }) => {
  const [bgColor, setBgColor] = useState('#00000055')
  const [textColor, setTextColor] = useState('white')
  const [iconColor, setIconColor] = useState('white')
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  // const handleOnHover = (result) => {
  //   // the item hovered
  //   console.log(result)
  // }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
    onItemSelected(item)
  }

  const handleOnFocus = () => {
    setBgColor('white')
    setIconColor('black')
    setTextColor('black')
  }

  const formatResult = (item) => {
    return (
			<div style={{
			  display: 'flex',
			  flexDirection: 'row',
			  alignItems: 'center',
			  borderBottom: '1px solid white',
			  cursor: 'pointer'
			}}>
				<img src={item.pictureUrl} style={{ width: '15%', height: 'auto', borderRadius: 10 }}/>
				<p style={{
				  fontSize: 20,
				  marginLeft: 20,
				  color: Colors.theme
				}}>{item.make}</p>
			</div>
    )
  }

  return (
		<div style={{ width: '90%' }}>
			<ReactSearchAutocomplete
				items={items}
				resultStringKeyName={'make'}
				fuseOptions={{ keys: ['make'] }}
				onSearch={handleOnSearch}
				// onHover={handleOnHover}
				onSelect={handleOnSelect}
				onFocus={handleOnFocus}
				autoFocus={false}
				formatResult={formatResult}
				placeholder={'Search by make or model'}
				styling={{
				  placeholderColor: 'gray',
				  backgroundColor: 'transparent',
				  iconColor,
				  hoverBackgroundColor: '#ffffff44'
				}}
			/>
		</div>
  )
}

export default Reservation
