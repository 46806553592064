import React from 'react'
import colors from '../config/colors'
import Colors from '../config/colors'
import { Audio } from 'react-loader-spinner'

function CustomLoaderSpinner ({ color, text }) {
  return (
        <>
            <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1
                }}
            >
                <Audio
                    height="100"
                    width="100"
                    color={color || colors.theme}
                    ariaLabel="loading"
                />
                {text
                  ? <div style={{ marginTop: 4 }}>
                        <label style={{ color: Colors.theme }}>{text}</label>
                    </div>
                  : null}
            </div>
        </>
  )
}

export default CustomLoaderSpinner
