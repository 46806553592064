import React, { useEffect, useState } from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Colors from '../../config/colors'
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  getBusinessSettings,
  getIntegrationSettings,
  getPriceSettings,
  getSettingsData
} from '../../redux/slices/settingsSlice'
import Res1Icon from '../../assets/res1.jpeg'
import Res2Icon from '../../assets/res2.jpeg'
import { fetchAllVehicles, vehiclesMapSelector } from '../../redux/slices/vehiclesSlice'
import Styles from './styles/CalendarPage.module.scss'
import Helpers from '../../utils/Helpers'
import moment from 'moment'
import CustomButton from '../../components/CustomButtonContained'
import ClientsMap from '../../metadata/ClientsMap'
import { addBackendUrl, addBusiness, addToken } from '../../redux/slices/authSlice'
import { Audio } from 'react-loader-spinner'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../services/firebase'
import Config from '../../config'
import CustomModal from '../../components/CustomModal'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import axios from '../../axios/axios'
import { LOGGER } from '../../utils/Logger'
import { addReservationDetails } from '../../redux/slices/reservationsSlice'
import EngineIcon from '../../assets/Engine.png'
import LocationIcon from '../../assets/Location.png'
import SeatsIcons from '../../assets/Seats.png'
import SpeedIcon from '../../assets/Speed.png'
import { Alert, Snackbar } from '@mui/material'

const CalendarPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const token = useSelector(state => state.auth.token)
  const backendUrl = useSelector(state => state.auth.backend_url)
  const businessSettings = useSelector(state => getBusinessSettings(state.settings))
  const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [startDate, setStartDate] = useState(moment().add(1, 'day').toDate())
  const [endDate, setEndDate] = useState(moment().add(2, 'day').toDate())
  const time = moment().minutes(0).hours(10).toDate()
  const [pickupTime, setPickupTime] = useState(time)
  const [returnTime, setReturnTime] = useState(time)
  const [totalDays, setTotalDays] = useState(null)
  const [showBreakdown, setShowBreakdown] = useState(false)
  const [showDateModal, setShowDateModal] = useState(false)

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection'
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      width: '25%',
      height: '50%',
      borderRadius: '20px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    textField: {
      display: 'flex'
    },
    popOver: {
      width: '40vw',
      height: '40vh',
      backgroundColor: 'red'
    },
    paper2: {
      width: '100%',
      height: '80%'
    },
    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)'
    },

    DateCalendar: {
      padding: '0px !important'
    },
    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    typography: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      color: 'black'
    }
  }))

  const reservationDetails = useSelector(state => state.reservations.reservation_details)
  const priceSettings = useSelector(state => getPriceSettings(state.settings))
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))

  const [onlinePriceSettings, setOnlinePriceSettings] = useState(null)
  const [blockedDates, setBlockedDates] = useState([])
  const [showLoading, setShowLoading] = useState(true)
  const [gracePeriod, setGracePeriod] = useState(0)
  const [minDuration, setMinDuration] = useState(0)

  const [state, setState] = useState({
    selection: {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  })

  const [vehicle, setVehicle] = useState(null)
  const [pickupDays, setPickupDays] = useState()

  // useEffect(() => {
  //   if (!reservationDetails) {
  //     navigate(-1)
  //   }
  // }, [reservationDetails])

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (authUser) => {
      console.log('auth state cahnged')
      if (authUser) {
        const authToken = await authUser.getIdToken(true)
        const bus = searchParams.get('bus')
        const client = ClientsMap[bus]
        if (!client) {
          window.alert('Cannot find business information')
          return
        }
        dispatch(addBackendUrl(client.url))
        // fetchVehicles(client.url, authToken)
        dispatch(addToken(authToken))
        dispatch(fetchAllVehicles({ token: authToken, backendUrl: client.url }))
        dispatch(getSettingsData({ token: authToken, backendUrl: client.url }))
      } else {
        signIn()
      }
    })
    return listener
  }, [])

  const signIn = () => {
    const user = auth.currentUser
    if (user) {
      return
    }

    const random = searchParams.get('random')
    signInWithEmailAndPassword(auth, Config.ADMIN_CREDS[0], random)
      .then((userCredential) => {
        // Signed in
        console.log('successfully signed in')
      }).catch(err => {
        console.log('error when signing in user', err)
      })
  }

  useEffect(() => {
    const bus = searchParams.get('bus')
    const random = searchParams.get('random')

    if (!bus || !random) {
      window.alert('invalid link')
    } else {
      const client = ClientsMap[bus]
      if (!client) {
        window.alert('Something went wrong')
        return
      }
      dispatch(addBackendUrl(client.url))
      dispatch(addBusiness(bus))
    }
  }, [])

  useEffect(() => {
    if (integrationSettings?.websiteIntegration) {
      const { grace_period, minimum_duration } = integrationSettings.websiteIntegration
      if (grace_period === gracePeriod || minimum_duration === minDuration) {
        return
      }
      if (grace_period && !isNaN(grace_period) && Number(grace_period) > 0) setGracePeriod(grace_period)
      if (minimum_duration && !isNaN(minimum_duration) && Number(minimum_duration) > 0) setMinDuration(minimum_duration)

      setOnlinePriceSettings(integrationSettings.websiteIntegration)
    }
  }, [integrationSettings])

  useEffect(() => {
    if (reservationDetails) {
      if (reservationDetails?.startDate && reservationDetails?.endDate) {
        setStartDate(new Date(reservationDetails?.startDate))
        setEndDate(new Date(reservationDetails?.endDate))
        setPickupTime(new Date(reservationDetails?.pickupTime))
        setReturnTime(new Date(reservationDetails?.returnTime))
        setTotalDays(reservationDetails?.totalDays)
        setShowBreakdown(true)
      }
    } else {
      setShowBreakdown(true)
    }
  }, [reservationDetails])

  const handleDateSelect = (ranges) => {
    const { selection } = ranges
    const { startDate, endDate } = selection

    setStartDate(startDate)
    setEndDate(endDate)
  }

  const getTotalDays = () => {
    const finalPickupDatetime = moment(startDate)
      ?.hours(pickupTime?.getHours())
      ?.minutes(pickupTime?.getMinutes())
    const finalReturnDatetime = moment(endDate)
      ?.hours(returnTime?.getHours())
      ?.minutes(returnTime?.getMinutes())
    const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours')
    if (totalHours <= 24) return 1
    const totalDays = Math.floor(totalHours / 24)
    const reminder = totalHours % 24
    if (reminder > 2) return totalDays + 1
    else return totalDays
  }

  useEffect(() => {
    if (vehicle && onlinePriceSettings) {
      const totalDays = getTotalDays()
      const subTotal = Number(vehicle?.details?.daily_price) * totalDays
      setSubTotal(subTotal)
      setCardFee(onlinePriceSettings?.card_fee ? ((subTotal * onlinePriceSettings?.card_fee) / 100) : 0)
      setSalesFee((subTotal * onlinePriceSettings?.sales_tax) / 100)
      setServiceFee((subTotal * onlinePriceSettings?.service_tax.rentals) / 100)
      setTotalTax(Number(onlinePriceSettings?.service_tax.rentals) + Number(onlinePriceSettings?.sales_tax))
      setShowBreakdown(true)
      setTotalDays(totalDays)
    }
  }, [startDate, endDate, pickupTime, returnTime, vehicle, onlinePriceSettings])

  useEffect(() => {
    if (!vehiclesMap || Object.keys(vehiclesMap).length === 0 || vehicle !== null) {
      return
    }
    const vehicleId = searchParams.get('vehicleId')
    setVehicle(vehiclesMap[vehicleId] || {})
    setShowLoading(false)
  }, [searchParams, vehiclesMap])

  useEffect(() => {
    if (!vehicle || !token || !backendUrl) return
    asyncFunctions()
  }, [vehicle, backendUrl, token])

  const getBlockingResOrMain = async (isReservation, startDate, endDate) => {
    return new Promise((resolve, reject) => {
      let url
      if (isReservation) {
        url = `${backendUrl}reservations/blocking?start_date=${startDate}&end_date=${endDate}&vehicle_id=${vehicle?.id}`
      } else {
        url = `${backendUrl}maintenances/blocking?start_date=${startDate}&end_date=${endDate}&vehicle_id=${vehicle?.id}`
      }

      const config = {
        method: 'get',
        url,
        headers: { Authorization: token, contentType: 'application/json' }
      }
      axios(config)
        .then((res) => resolve(res.data))
        .catch((err) => {
          LOGGER.error('error when getting blocing something', err)
          reject(err)
        })
    })
  }

  async function asyncFunctions () {
    const finalPickupDatetime = moment().toISOString()
    const finalReturnDatetime = moment().add(2, 'years').toISOString()
    setShowLoading(true)

    const blockingRes = await getBlockingResOrMain(
      true,
      finalPickupDatetime,
      finalReturnDatetime
    )
    const blockingMain = await getBlockingResOrMain(
      false,
      finalPickupDatetime,
      finalReturnDatetime
    )

    setShowLoading(false)
    const blockedDates = []
    blockingRes.forEach(res => {
      const { pickup_datetime, dropoff_datetime } = res
      let temp = new Date(pickup_datetime)
      const last = new Date(dropoff_datetime)
      while (moment(temp).isSameOrBefore(last)) {
        blockedDates.push(temp)
        temp = moment(temp).add(1, 'days').toDate()
      }
    })

    blockingMain.forEach(main => {
      const { start_date, end_date } = main
      let temp = new Date(start_date)
      const last = new Date(end_date)
      while (moment(temp).isSameOrBefore(last)) {
        blockedDates.push(temp)
        temp = moment(temp).add(1, 'days').toDate()
      }
    })

    console.log('blcoked dates', blockedDates)

    setBlockedDates(blockedDates)
  }

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setShowDateModal(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const [selectedDates, setSelectedDates] = useState([])

  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  const handleClick2 = () => {
    const tempDates = blockedDates.map(date => moment(date).format('MM/DD/YYYY'))
    let start = moment(startDate)
    const end = moment(endDate)
    let flag = false
    while (moment(start).isSameOrBefore(end)) {
      // go through all the reserved dates and see if any of them are blocked
      if (tempDates.includes(start.format('MM/DD/YYYY'))) {
        flag = true
      }
      start = moment(start).add(1, 'days')
    }

    if (flag) {
      setErrorMessage('The car is not available on one or more selected dates. Please select a different date range.')
      setShowError(true)
      // window.alert('The car is not available on one or more selected dates. Please select a different date range.')
      return
    }

    let address = ''
    if (businessSettings && businessSettings.address) {
      address = `${businessSettings?.address}, ${businessSettings?.city_state} ${businessSettings?.zip}`
    }

    const totalDays = getTotalDays()
    const finalPickupDatetime = moment(startDate).hours(pickupTime?.getHours()).minutes(pickupTime?.getMinutes())
    const finalReturnDatetime = moment(endDate).hours(returnTime?.getHours()).minutes(returnTime?.getMinutes())

    if (minDuration && Number(minDuration) > 0) {
      // there is a minimum duration check that need to be done
      if (moment(finalReturnDatetime).diff(finalPickupDatetime, 'hours') < minDuration) {
        setErrorMessage(`The minimum rental period must be ${minDuration} hours`)
        setShowError(true)
        // window.alert(`The minimum rental period must be ${minDuration} hours`)
        return
      }
    }

    if (gracePeriod && Number(gracePeriod) > 0) {
      // there is a minimum duration check that need to be done
      if (moment(finalPickupDatetime).diff(new Date(), 'hours') < gracePeriod) {
        setErrorMessage(`The start time should be at least ${gracePeriod} hours from now`)
        setShowError(true)
        // window.alert(`The start time should be at least ${gracePeriod} hours from now`)
        return
      }
    }

    const data = {
      vehicle,
      pickupLocation: address,
      dropoffLocation: address,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      pickupTime: pickupTime.toISOString(),
      returnTime: returnTime.toISOString(),
      totalDays
    }
    dispatch(addReservationDetails(data))

    navigate('/iframe/payment')
  }

  const [subTotal, setSubTotal] = useState(0)
  const [cardFee, setCardFee] = useState(0)
  const [salesFee, setSalesFee] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [totalTax, setTotalTax] = useState(0)

  const renderMilAndSecInfo = () => {
    return (
			<div className={Styles.mileSecWrapper}>
				<div className={Styles.detsWrapper}>
					<img src={Res1Icon} className={Styles.icon}/>
					<div style={{
					  display: 'flex',
					  flexDirection: 'column',
					  justifyContent: 'space-between',
					  height: '80%'
					}}>
						<div className={'row'} style={{ height: '20px' }}>
							<label style={{ fontSize: 40, color: Colors.theme, marginRight: 4 }}>·</label>
							<label className={Styles.detsTxt}>{vehicle?.details?.mileage_allowance} Free Miles /
								Day</label>
						</div>
						<div className={'row'} style={{ height: '20px' }}>
							<label style={{ fontSize: 40, color: Colors.theme, marginRight: 4 }}>·</label>
							<label className={Styles.detsTxt}>${vehicle?.details?.mileage_overage_price} / Extra
								Mile</label>
						</div>
					</div>

				</div>

				<div className={Styles.detsWrapper}>
					<img src={Res2Icon} className={Styles.icon}/>
					<div style={{
					  display: 'flex',
					  flexDirection: 'column',
					  justifyContent: 'space-between',
					  height: '80%'
					}}>
						<div className={'row'} style={{ height: '20px' }}>
							<label style={{ fontSize: 40, color: Colors.theme, marginRight: 4 }}>·</label>
							<label className={Styles.detsTxt}>Security Deposit:
								${vehicle?.details?.deposit}</label>
						</div>
						<div className={'row'} style={{ height: '20px' }}>
							<label style={{ fontSize: 40, color: Colors.theme, marginRight: 4 }}>·</label>
							<label className={Styles.detsTxt}>Min. Duration: {minDuration} Hours</label>
						</div>
					</div>

				</div>
			</div>
    )
  }

  const renderEngineDetails = () => {
    const details = vehicle?.details || {}
    return (
			<div className={Styles.engineDetsWrapper}>
				<div className={Styles.engineDetailsBox}>
					<img src={EngineIcon} className={Styles.icon2}/>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<label className={Styles.detsLabel}>Engine</label>
						<label className={Styles.detsValue}>{`${details.engine_capacity}`} <span
							style={{ fontWeight: 20 }}>&</span>{` ${details.engine_bhp} BHP`}</label>
					</div>
				</div>
				<div className={Styles.engineDetailsBox}>
					<img src={SeatsIcons} className={Styles.icon2}/>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<label className={Styles.detsLabel}>Seats</label>
						<label className={Styles.detsValue}>{`${details.seats}`}</label>
					</div>
				</div>
				<div className={Styles.engineDetailsBox}>
					<img src={SpeedIcon} className={Styles.icon2}/>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<label className={Styles.detsLabel}>0-60 mph</label>
						<label className={Styles.detsValue}>{`${details['0to60']} sec`}</label>
					</div>
				</div>
				<div className={Styles.engineDetailsBox}>
					<img src={LocationIcon} className={Styles.icon2}/>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<label className={Styles.detsLabel}>MSRP</label>
						<label className={Styles.detsValue}>{`$${Helpers.numFormatter(details.msrp)}`}</label>
					</div>
				</div>

			</div>
    )
  }

  const renderContent = () => {
    if (showLoading) {
      return (
				<div style={{
				  width: '100%',
				  height: '100%',
				  display: 'flex',
				  flexDirection: 'row',
				  justifyContent: 'center',
				  marginTop: 200
				}}>
					<Audio
						height="100"
						width="100"
						color={Colors.theme}
						ariaLabel='loading'
					/>
				</div>
      )
    }
    return (
			<div className={Styles.container}>
				<div className={Styles.calendarImgDiv}>
					<img
						src={vehicle?.pictureUrl}
						style={{
						  width: '100%',
						  height: 'auto',
						  borderRadius: '10px',
						  boxShadow: '0px 0px 5px grey'
						}}
						alt=""
						// className="calendar-img"
					/>
					{renderMilAndSecInfo()}
					{renderEngineDetails()}

				</div>

				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={showError}
					autoHideDuration={3000}
					onClose={() => setShowError(false)}
				>
					<Alert severity={'error'} sx={{ width: '100%' }}>
						{errorMessage}
					</Alert>
				</Snackbar>

				<div className={`${Styles.calendarDetailsDiv} ${Styles.boxShadow}`}>
					<span className={Styles.makeTxt}>{vehicle.make}</span>
					<div style={{
					  display: 'flex',
					  flexDirection: 'row',
					  alignItems: 'center',
					  marginBottom: 20,
					  marginTop: 0
					}}>
						<span className={Styles.priceTxt}>${vehicle?.details?.daily_price}</span>
						<span className={Styles.priceDayTxt}>/ day</span>
					</div>

					<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Start Date"
							variant="outlined"
							onClick={handleClick}
							value={moment(startDate).format('Do MMM, YYYY')}
						/>

						{/* <TextField */}
						{/*    fullWidth */}
						{/*    id="outlined-basic" */}
						{/*    label="Start Time" */}
						{/*    variant="outlined" */}
						{/*    onClick={handleClick} */}
						{/*    value={moment(pickupTime).format('hh:mm A')} */}
						{/* /> */}
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<TimePicker
								value={pickupTime}
								onChange={(newValue) => {
								  // setSelectedVehicle(null)
								  setPickupTime(newValue)
								}}
								sx={{ backgroundColor: 'white' }}
								renderInput={(params) => {
								  return (
										<TextField
											sx={{
											  '.MuiOutlinedInput-root': {
											    backgroundColor: Colors.theme,
											    color: Colors.darkTextColor,
											    height: 30,
											    borderRadius: 5
											  },
											  '.MuiSvgIcon-root': { color: Colors.darkTextColor }
											}}
											InputLabelProps={{
											  style: { color: Colors.darkTextColor }
											}}
											{...params}
										/>
								  )
								}}
							/>
						</LocalizationProvider>
					</div>

					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 20 }}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="End Date"
							variant="outlined"
							onClick={handleClick}
							value={moment(endDate).format('Do MMM, YYYY')}
						/>

						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<TimePicker
								value={returnTime}
								onChange={(newValue) => {
								  // setSelectedVehicle(null)
								  setReturnTime(newValue)
								}}
								sx={{ backgroundColor: 'white' }}
								renderInput={(params) => {
								  return (
										<TextField
											sx={{
											  '.MuiOutlinedInput-root': {
											    backgroundColor: Colors.theme,
											    color: Colors.darkTextColor,
											    height: 30,
											    borderRadius: 5
											  },
											  '.MuiSvgIcon-root': { color: Colors.darkTextColor }
											}}
											InputLabelProps={{
											  style: { color: Colors.darkTextColor }
											}}
											{...params}
										/>
								  )
								}}
							/>
						</LocalizationProvider>
					</div>

					<CustomButton
						text={'NEXT'}
						color={'primary'}
						onClick={handleClick2}
						style={{ marginTop: 2, width: '100%', marginBottom: 2 }}
					/>
					{/* <Button */}
					{/*    variant="contained" */}
					{/*    sx={{ cursor: 'pointer', marginTop: 10, backgroundColor: Colors.theme }} */}
					{/*    onClick={handleClick2} */}
					{/* > */}
					{/*    Next */}
					{/* </Button> */}

					{
						showBreakdown &&
						<div>
							<Typography style={{ marginTop: '25px' }} className={classes.typography}>
                                 <span>
                                  ${vehicle?.details?.daily_price} &times; {totalDays} day(s)
                                 </span>
								<span>
                                    ${Number(subTotal).toFixed(2)}
                                </span>
							</Typography>

							<Typography className={classes.typography} style={{ marginTop: 10 }}>
								<span>Sub Total</span>
								<span>${Number(subTotal).toFixed(2)}</span>
							</Typography>

							<Typography className={classes.typography} style={{ marginTop: 10 }}>
								<span>Taxes & Fees ({totalTax}%)</span>
								<span>${Number(salesFee + serviceFee).toFixed(2)}</span>
							</Typography>

							{
								cardFee && cardFee > 0
								  ? <Typography className={classes.typography} style={{ marginTop: 10 }}>
										<span>Card Fees ({onlinePriceSettings?.card_fee}%)</span>
										<span>${Number(cardFee).toFixed(2)}</span>
									</Typography>
								  : null
							}

							<div
								style={{
								  width: '100%',
								  height: '1px',
								  backgroundColor: 'rgba(0,0,0,0.2)',
								  marginTop: 10,
								  marginBottom: 10
								}}
							></div>

							<Typography
								style={{ fontWeight: 'bold', fontSize: 20 }}
								className={classes.typography}
							>
								<span>Total Charges</span>
								<span>${Number(subTotal + salesFee + serviceFee + cardFee).toFixed(2)}</span>
							</Typography>

							{/* <p */}
							{/*	onClick={() => navigate(-1)} */}
							{/*	style={{ */}
							{/*	  width: '100%', */}
							{/*	  textAlign: 'center', */}
							{/*	  color: Colors.secondaryTextColor, */}
							{/*	  textDecoration: 'underline', */}
							{/*	  cursor: 'pointer' */}
							{/*	}} */}
							{/* > */}
							{/*	change vehicle */}
							{/* </p> */}
						</div>
					}
				</div>
			</div>
    )
  }

  return (
		<div className={`${Styles.wrapper}`}>
			{renderContent()}

			<CustomModal
				show={showDateModal}
				handleClose={() => setShowDateModal(false)}
				containerWidth={window.innerWidth / 2}
				containerHeight={window.innerHeight / 2}
			>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
					<DateRangePicker
						onChange={handleDateSelect}
						months={1}
						disabledDates={blockedDates}
						minDate={moment().toDate()}
						maxDate={addDays(new Date(), 900)}
						direction="vertical"
						scroll={{ enabled: true }}
						ranges={[selectionRange]}

					/>
				</div>
			</CustomModal>

		</div>
  )
}

export default CalendarPage
