import {createSlice} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'

const initialState = {
	token: null,
	status: API_CALL_STATUS.IDLE,
	error: null,
	access_allowed: true,
	backend_url: null,
	business: null
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addToken(state, action) {
			return Object.assign({}, state, {token: action.payload})
		},
		addBackendUrl(state, action) {
			return Object.assign({}, state, {backend_url: action.payload})
		},
		addBusiness(state, action) {
			return Object.assign({}, state, {business: action.payload})
		}

	}
})


export const {addToken, addBackendUrl, addBusiness} = authSlice.actions

export default authSlice.reducer
