
export const ADD_USER_DATA_ACTION = 'users/addUser';
export const ADD_SESSION_COOKIE = 'cookies/addSessionCookie';
export const FETCH_USER_DETAILS = 'user/fetchDetails';
export const UPDATE_USER_DETAILS = 'user/updateDetails';
export const FETCH_ALL_VEHICLES = 'vehicles/fetchAll';
export const UPDATE_VEHICLE = 'vehicles/updateOne'
export const ADD_NEW_VEHICLE = 'vehicles/addNew';
export const FETCH_UPCOMING_RESERVATIONS = 'reservations/fetchUpcoming';
export const FETCH_OLD_RESERVATIONS = 'reservations/fetchOld';
export const ADD_NEW_RESERVATION = 'reservations/addNew';
export const UPDATE_RESERVATION = 'reservations/updateOne';
export const DELETE_RESERVATION = 'reservations/deleteOne';
export const GET_ANALYTICS_DATA = 'analytics/getData';
export const GET_SALES_ANALYTICS_DATA = 'analytics/getSalesData';
export const GET_PROFITABILITY_ANALYTICS_DATA = 'analytics/getProfitabilityData';
export const GET_VEHICLES_ANALYTICS_DATA = 'analytics/getVehiclesData';
export const GET_SETTINGS = 'settings/fetchAll';
export const UPDATE_SETTINGS = 'settings/updateOne';
export const GET_ALL_USERS = 'users/fetchAll';
export const UPDATE_USER = 'users/updateOne';
export const ADD_NEW_USER = 'users/addUser';
export const GET_ALL_CLIENTS = 'clients/fetchAll';
export const GET_ONE_CLIENT = 'clients/getOne'
export const UPDATE_CLIENT = 'clients/updateOne';
export const ADD_CLIENT = 'clients/addOne';
export const GET_CLIENT_DETAILS = 'clients/getDetails';
export const GET_ALL_CLAIMS = 'claims/fetchAll';
export const UPDATE_CLAIM = 'claims/updateOne';
export const ADD_CLAIM = '=claims/addOne';
export const GET_CLAIM_DETAILS = 'claims/getDetails';
export const GET_ALL_MAINTENANCES = 'claims/fetchAll'
export const GET_PARTNER_SLICE_DATA = 'partnerSplit/fetchAllPartnerSplits'
export const GET_PARTNER_PROFILE_DATA = 'partnerSplit/fetchPartnerData_forPartnerProfile'
export const GET_PARTNER_PROFILE_DATA_MONTHLY_BREAKDOWN = 'partnerSplit/fetchPartnerMonthlyBreakDown'
export const GET_PARTNER_SLICE_NAME_AND_ID = 'partnerSplit/fetchAllPartnerSplits_nameAndId'
export const ADD_NEW_PARTNER_AND_UPDATE_VEHICLE = 'partnerSplit/addPartnerAndVehicleThenConfirm'
export const CHARGE_CARD = 'chargeCard';