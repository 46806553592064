import { useEffect, useRef } from "react";

export default function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let instance, PSPDFKit;
    (async function () {
      PSPDFKit = await import("pspdfkit");

      // Unload the previous PSPDFKit instance if it exists
      if (instance) {
        PSPDFKit.unload(container);
      }

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      });

      // instance.setViewState(viewState => viewState.set("showToolbar", !viewState.showToolbar));
    })();

    return () => {
      // Cleanup when the component unmounts or when a new instance is loaded
      if (instance) {
        PSPDFKit.unload(container);
      }
    };
  }, [props.document]); // Add props.document to the dependency array

  return <div ref={containerRef} style={{ width: "100%", height: "90vh" }} />;
}
