import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import Config from '../../config'
import Helpers from '../../utils/Helpers'
import { LOGGER } from '../../utils/Logger'
import WebViewer from '@pdftron/webviewer'
import CustomLoaderSpinner from '../CustomLoaderSpinner'

const STORAGE_KEY = 'signatures_storage'
const ATTACHMENTS_KEY = 'attachments_storage'
const WIDTH = window.innerWidth

export default function DriveRentalRendererNew ({
																								 doc,
																								 reservation,
																								 shouldSave,
																								 onSave,
																								 token,
																								 businessInfo,
																								 backendUrl,
																								 isPreTrip,
																								 shouldSendInvoice,
																								 onDocSend,
																								 checkSignatures,
																								 onSignatureChecked,
																								 pricing
																							 }) {
  const viewer = useRef(null)
  const [instance, setInstance] = useState(null)
  const [loading, setLoading] = useState(false)

  let index = 0
  const searchStrings = [
    'reserv-id',
    'client-name',
    'client-address',
    'client-city-state',
    'client-phone',
    'client-email',
    'client-dl-no',
    'client-dl-exp',
    'client-dl-state',
    'Insurance Info',
    'client-policy-no',
    'client-policy-pro',
    'Additional Driver',
    'add-name',
    'add-license-no',
    'add-license-exp',
    'add-license-state',
    'pickup-date-time',
    'return-date-time',
    'rental-info-total-string',
    'vehicle-name',
    'vehicle-vin',
    'vehicle-plate-no',
    'starting-mileage',
    'starting-fuel',
    'ending-mileage',
    'ending-fuel',
    'starting-location',
    'ending-location',
    'mileage-allowance-1',
    'mileage-overage-1',
    'pi-r1-c2',
    'pi-r1-c3',
    'add-r1-c2',
    'add-r1-c3',
    'add-r2-c2',
    'add-r2-c3',
    'add-r3-c2',
    'add-r3-c3',
    'add-r4-c2',
    'add-r4-c3',
    'add-r5-c2',
    'add-r5-c3',
    'extras-text',
    'extras-c2',
    'extras-c3',
    'dis-c2',
    'dis-c3',
    'sub-total-c3',
    'sales-tax-c2',
    'sales-tax-c3',
    'service-fee-c2',
    'service-fee-c3',
    'card-fee-c1',
    'card-fee-c2',
    'card-fee-c3',
    'r-total-c3',
    'deposit-c3',
    'today-date',
    'customer-signature',
    'cust-signature-2',
    'cust-signature-3',
    'cust-signature-4',
    'cust-signature-5',
    'cust-signature-6',
    'tnc-string',
    'tnc-insurance',
    'tnc-indemnification',
    'tnc-damages',
    'tnc-responsibility',
    'tnc-prohibited',
    'tnc-liability',
    'tnc-cleaning',
    'tnc-fuel',
    'tnc-traffic',
    'tnc-toll',
    'tnc-dispute',
    'tnc-payment',
    'tnc-agreement',
    'tnc-prohibition',
    'tnc-travel',
    'tnc-vehicle-return',
    'tnc-late',
    'tnc-termination',
    'tnc-speed',
    'tnc-towing',
    'tnc-loss',
    'tnc-illegal',
    'tnc-refunds',
    'tnc-arbitration',
    'tnc-tracking',
    'tnc-track-use',
    'tnc-tampering',
    'tnc-amendment',
    'tnc-law'
  ]

  useEffect(() => {
    if ((isPreTrip && !reservation.pre_contract) || (!isPreTrip && !reservation.post_contract)) {
      setLoading(true)
    }
  }, [reservation, isPreTrip])

  useEffect(() => {
    console.log('effect', shouldSave)
    if (shouldSave) {
      const savePdf = async () => {
        console.log('saving pdf')
        setLoading(true)
        const { documentViewer, annotationManager } = instance.Core
        const doc = documentViewer.getDocument()
        const xfdfString = await annotationManager.exportAnnotations()
        const data = await doc.getFileData({
          // saves the document with annotations in it
          xfdfString
        })
        const arr = new Uint8Array(data)
        const blob = new Blob([arr], { type: 'application/pdf' })
        console.log('got blob')
        setLoading(false)
        onSave(blob)
      }

      savePdf()
    }
  }, [shouldSave])

  useEffect(() => {
    if (!pricing) {
      return
    }

    if ((isPreTrip && reservation.pre_contract) || (!isPreTrip && reservation.post_contract)) {
      return
    }

    const elements = []
    if (WIDTH < 700) {
      elements.push('header')
    }

    WebViewer(
      {
        path: '/webviewer',
        licenseKey: Config.APRYSE_LICENSE_KEY,
        initialDoc: getDocument(),
        disabledElements: elements
      },
      viewer.current
    ).then((tempInstance) => {
      const { Core, UI } = tempInstance
      const { documentViewer, RNPdftron, annotationManager } = Core
      LOGGER.log('Successfully loaded pdfkit', RNPdftron)
      setInstance(tempInstance)
      // UI.enableFeatures([UI.Feature.Initials])
      documentViewer.addEventListener('annotationsLoaded', (result) => {
        console.log('anootations loaded', result)
      })

      tempInstance.UI.addEventListener(tempInstance.UI.Events.DOCUMENT_LOADED, () => {
        LOGGER.log('document loaded')
        // UI.setZoomLevel('100%')
        const { documentViewer, annotationManager, Annotations } = Core
        if ((isPreTrip && !reservation.pre_contract) || (!isPreTrip && !reservation.post_contract)) {
          addAnnotations(tempInstance)
        }
      })

      // annotationManager.addEventListener('annotationChanged', (annotations, action) => {
      //   if (action === 'add') {
      //     console.log('this is a change that added annotations')
      //   } else if (action === 'modify') {
      //     console.log('this change modified annotations')
      //   } else if (action === 'delete') {
      //     console.log('there were annotations deleted')
      //   }
      //
      //   annotations.forEach((annot) => {
      //     if (annot.Subject === 'Annotation') {
      //       setTimeout(() => {
      //         const iframeDoc = tempInstance.UI.iframeWindow.document
      //         const element = iframeDoc.getElementsByClassName('signature-create')
      //         console.log('here here', element)
      //         if (element[0]) {
      //           window.scrollTo(0, 500)
      //         }
      //       }, 2000)
      //       // signature was opened
      //     }
      //     // console.log('annotation page number', annot)
      //   })
      // })

      // you can now call WebViewer APIs here...
    }).catch(err => {
      LOGGER.error('error when instantiation PDF Kit', err)
    })
  }, [pricing, reservation])

  const getDocument = () => {
    if (isPreTrip) {
      return reservation.pre_contract?.url || doc
    } else {
      return reservation.post_contract?.url || doc
    }
  }

  const addAnnotations = (
    instance
  ) => {
    if (!instance) {
      return
    }
    console.log('saerching and adding annotation')

    // const searchOptions = {
    // 	caseSensitive: true, // match case
    // 	wholeWord: true, // match whole words only
    // 	wildcard: false, // allow using '*' as a wildcard value
    // 	regex: false, // string is treated as a regular expression
    // 	searchUp: false, // search from the end of the document upwards
    // 	ambientString: true // return ambient string as part of the result
    // }

    const allAnnotations = []
    const { documentViewer, annotationManager, Annotations, Search } = instance.Core

    const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT

    // client info
    const { fName, lName, phone, address, email, license, insurance } = reservation.client_details
    const cusAddress = address?.label || ' , '
    const components = cusAddress.split(',')
    let cityPlace = ''
    components.forEach((each, i) => {
      if (i !== 0) {
        cityPlace += each
      }
    })

    const name = `${fName} ${lName}`

    const totalDays = Helpers.getTotalDays(reservation)
    const { pickup_datetime, dropoff_datetime, additional_driver, pickup_location, dropoff_location } = reservation
    const checkIn = reservation.check_in || {}
    const checkOut = reservation.check_out || {}
    const pickupString = typeof pickup_location === 'string' ? pickup_location : pickup_location.label
    const dropoffString = typeof dropoff_location === 'string' ? dropoff_location : dropoff_location.label

    const addOns = {}
    let totalMileageAllowance = pricing?.mileage_allowance * Helpers.getTotalDays(reservation)
    let showMileagePackage = false
    if (pricing?.mileage_package.value) {
      totalMileageAllowance += Number(pricing?.mileage_package?.value)
      showMileagePackage = true
    }

    if (reservation && reservation.check_in && reservation.check_out) {
      // if te check out info has been added, calculate the add ons for mileage and fuel
      const startFuel = reservation.check_in.fuel
      const endFuel = reservation.check_out.fuel
      if (endFuel < startFuel) {
        addOns.fuel = {
          value: startFuel - endFuel,
          price: Math.round((startFuel - endFuel) / 10 * pricing?.fuel_charge)
        }
      }

      const startMileage = reservation.check_in.mileage
      const endMileage = reservation.check_out.mileage
      if ((endMileage - startMileage) > totalMileageAllowance) {
        addOns.mileage = {
          value: endMileage - startMileage - totalMileageAllowance,
          price: Number((endMileage - startMileage - totalMileageAllowance) * pricing?.mileage_overage_price)
        }
      }
    }

    if (typeof pickup_location !== 'string') {
      addOns.location1 = { value: '', price: pricing?.location_fee }
    }

    if (typeof dropoff_location !== 'string') {
      addOns.location2 = { value: '', price: pricing?.location_fee }
    }

    let tempTotalPrice = reservation.totalPrice
    if (Object.keys(addOns).length > 0) {
      Object.values(addOns).forEach(value => tempTotalPrice += Number(value.price))
    }

    if (pricing?.mileage_package.value) {
      // mileag package cost must be added as well
      tempTotalPrice += Number(pricing?.mileage_package.price)
    }

    let extrasPresent = false
    if (pricing?.extras && pricing.extras.length > 0) {
      extrasPresent = true
      pricing.extras.forEach((extra, index) => {
        tempTotalPrice += Number(extra.price)
      })
    }

    const discount = Number(Helpers.getDiscountedAmount(pricing, tempTotalPrice))
    const subTotal = tempTotalPrice - discount

    const cardFee = pricing?.card_fee ? ((subTotal * pricing?.card_fee) / 100) : 0
    const salesFee = ((subTotal * pricing?.sales_tax) / 100)
    const serviceFee = ((subTotal * pricing?.service_tax) / 100)
    const total = subTotal + serviceFee + salesFee + cardFee

    let extrasTotal = 0
    let extrasString = ''
    if (extrasPresent) {
      pricing.extras.forEach((extra, index) => {
        extrasTotal += Number(extra.price)
        if (index === 0) {
          extrasString = extra.name
        } else {
          extrasString = extrasString + ', ' + extra.name
        }
      })
    }

    const searchOptions = {
      // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
      fullSearch: true,
      // The callback function that is called when the search returns a result.
      onResult: result => {
        // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
        if (result.resultCode === Search.ResultCode.FOUND) {
          const { resultStr, pageNum } = result
          const x = result.quads[0].x1
          const y = result.quads[0].y1
          let fontConfig

          switch (resultStr) {
            case 'reserv-id':
              addAnnotation(instance, pageNum, `#${reservation?._id}`, 12, 55, 14, -14, x, y, false, 0)
              break
            case 'client-address':
              fontConfig = getFontConfig(components[0], 40)
              addAnnotation(instance, pageNum, components[0], fontConfig.fontSize, 200, fontConfig.height, (-14 + fontConfig.topMargin), x, y, false, 0)
              break
            case 'client-name':
              addAnnotation(instance, pageNum, name, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-city-state':
              addAnnotation(instance, pageNum, cityPlace, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-phone':
              addAnnotation(instance, pageNum, phone, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-email':
              addAnnotation(instance, pageNum, email, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-dl-no':
              addAnnotation(instance, pageNum, license?.number, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-dl-exp':
              addAnnotation(instance, pageNum, license?.expiry_date ? moment(license.expiry_date).format('MM/DD/YYYY') : '', 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-dl-state':
              addAnnotation(instance, pageNum, license?.state, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'Insurance Info':
              addAnnotation(instance, pageNum, 'Insurance Information', 10, 200, 14, -12, x, y, true, -2)
              break
            case 'client-policy-no':
              addAnnotation(instance, pageNum, insurance?.policy_no, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'client-policy-pro':
              addAnnotation(instance, pageNum, insurance?.provider, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'Additional Driver':
              addAnnotation(instance, pageNum, 'Additional Driver', 10, 200, 14, -12, x, y, true, -2)
              break
            case 'add-name':
              if (additional_driver?.name && additional_driver.name.length > 0) {
                addAnnotation(instance, pageNum, additional_driver.name, 10, 200, 14, -14, x, y, false, 0)
              }
              break
            case 'add-license-no':
              if (additional_driver?.name && additional_driver?.number && additional_driver.number.length > 0) {
                addAnnotation(instance, pageNum, additional_driver.number, 10, 200, 14, -14, x, y, false, 0)
              }
              break
            case 'add-license-exp':
              if (additional_driver?.name && additional_driver?.expiry_date) {
                addAnnotation(instance, pageNum, moment(additional_driver.expiry_date).format('MM/DD/YYYY') || '', 10, 200, 14, -14, x, y, false, 0)
              }
              break
            case 'add-license-state':
              if (additional_driver?.name && additional_driver?.state && additional_driver.state.length > 0) {
                addAnnotation(instance, pageNum, additional_driver.state, 10, 200, 14, -14, x, y, false, 0)
              }
              break
            case 'pickup-date-time':
              addAnnotation(instance, pageNum, moment(pickup_datetime).format('MMMM Do, hh:mm A'), 10, 200, 14, -14, x, y, false, 0)
              break
            case 'return-date-time':
              addAnnotation(instance, pageNum, moment(dropoff_datetime).format('MMMM Do, hh:mm A'), 10, 200, 14, -14, x, y, false, 0)
              break
            case 'rental-info-total-string':
              addAnnotation(instance, pageNum, `${totalDays} Day Rental`, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'vehicle-name':
              addAnnotation(instance, pageNum, reservation.vehicle_details?.make, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'vehicle-vin':
              addAnnotation(instance, pageNum, reservation?.vehicle_details?.vin, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'vehicle-plate-no':
              addAnnotation(instance, pageNum, reservation?.vehicle_details?.license_plate, 10, 200, 14, -14, x, y, false, 0)
              break
            case 'starting-mileage':
              addAnnotation(instance, pageNum, checkIn.mileage ? `${checkIn.mileage} mi` : '', 10, 150, 14, -14, x, y, false, 0)
              break
            case 'starting-fuel':
              addAnnotation(instance, pageNum, typeof checkIn.fuel === 'number' ? `${checkIn.fuel} %` : '', 10, 150, 14, -14, x, y, false, 0)
              break
            case 'ending-fuel':
              addAnnotation(instance, pageNum, typeof checkOut.fuel === 'number' ? `${checkOut.fuel} %` : '', 10, 150, 14, -14, x, y, false, 0)
              break
            case 'ending-mileage':
              addAnnotation(instance, pageNum, checkOut.mileage ? `${checkOut.mileage} mi` : '', 10, 150, 14, -14, x, y, false, 0)
              break
            case 'starting-location':
              if (pickupString.length > 0) {
                fontConfig = getFontConfig(pickupString, 80)
                addAnnotation(instance, pageNum, pickupString, fontConfig.fontSize, 400, fontConfig.height, (-14 + fontConfig.topMargin), x, y, false, 0)
              }
              break
            case 'ending-location':
              if (dropoffString.length > 0) {
                fontConfig = getFontConfig(dropoffString, 80)
                addAnnotation(instance, pageNum, dropoffString, fontConfig.fontSize, 400, fontConfig.height, (-14 + fontConfig.topMargin), x, y, false, 0)
              }
              break
            case 'mileage-allowance-1':
              addAnnotation(instance, pageNum, `${totalMileageAllowance} miles`, 10, 150, 14, -14, x, y, false, 0)
              break
            case 'mileage-overage-1':
              addAnnotation(instance, pageNum, `$${pricing?.mileage_overage_price} / mile`, 10, 150, 14, -14, x, y, false, 0)
              break
            case 'pi-r1-c2':
              addAnnotation(instance, pageNum, `${totalDays}x Days ($${Number(reservation.dailyPrice).toFixed(2)})`, 10, 220, 14, -14, x, y, false, 0)
              break
            case 'pi-r1-c3':
              addAnnotation(instance, pageNum, `$${(totalDays * reservation.dailyPrice).toFixed(2)}`, 10, 90, 14, -14, x, y, true, -52, true)
              break
            case 'add-r1-c2':
              if (addOns.location1) {
                addAnnotation(instance, pageNum, 'Additional Location Charge', 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'add-r1-c3':
              if (addOns.location1) {
                addAnnotation(instance, pageNum, `$${addOns.location1.price.toFixed(2)}`, 10, 90, 14, -14, x, y, 10, -42, true)
              }
              break
            case 'add-r2-c2':
              if (addOns.location2) {
                addAnnotation(instance, pageNum, 'Additional Location Charge', 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'add-r2-c3':
              if (addOns.location2) {
                addAnnotation(instance, pageNum, `$${addOns.location2.price.toFixed(2)}`, 10, 90, 14, -14, x, y, 10, -42, true)
              }
              break
            case 'add-r3-c2':
              if (addOns.mileage) {
                addAnnotation(instance, pageNum, `Add ${addOns.mileage.value} miles`, 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'add-r3-c3':
              if (addOns.mileage) {
                addAnnotation(instance, pageNum, `$${addOns.mileage.price.toFixed(2)}`, 10, 90, 14, -14, x, y, 10, -42, true)
              }
              break
            case 'add-r4-c2':
              if (addOns.fuel) {
                addAnnotation(instance, pageNum, `${addOns.fuel.value}% Fuel Charge($${pricing?.fuel_charge}/10%)`, 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'add-r4-c3':
              if (addOns.fuel) {
                addAnnotation(instance, pageNum, `$${addOns.fuel.price.toFixed(2)}`, 10, 90, 14, -14, x, y, 10, -42, true)
              }
              break
            case 'add-r5-c2':
              if (showMileagePackage) {
                addAnnotation(instance, pageNum, `Mileage Package (${pricing?.mileage_package?.value} miles)`, 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'add-r5-c3':
              if (showMileagePackage) {
                addAnnotation(instance, pageNum, `$${pricing?.mileage_package?.price}`, 10, 90, 14, -14, x, y, 10, -42, true)
              }
              break
            case 'extras-c2':
              if (extrasPresent) {
                fontConfig = getFontConfig(extrasString, 20)
                addAnnotation(instance, pageNum, `${extrasString}`, fontConfig.fontSize, 220, fontConfig.height, (-14 + fontConfig.topMargin), x, y, false, 0)
              }
              break
            case 'extras-c3':
              if (extrasPresent) {
                addAnnotation(instance, pageNum, `$${extrasTotal}`, 10, 90, 14, -14, x, y, 10, -44, true)
              }
              break
            case 'extras-text':
              if (extrasPresent) {
                addAnnotation(instance, pageNum, 'EXTRA COSTS', 11, 120, 14, -14, x, y, false, -2)
              }
              break
            case 'dis-c2':
              if (discount > 0) {
                addAnnotation(instance, pageNum, `${pricing?.discount?.label}`, 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'dis-c3':
              if (discount > 0) {
                addAnnotation(instance, pageNum, pricing?.discount?.label ? `($${discount.toFixed(2)})` : '', 10, 90, 14, -14, x, y, true, -60, true)
              }
              break
            case 'sub-total-c3':
              addAnnotation(instance, pageNum, `$${subTotal.toFixed(2)}`, 10, 90, 14, -14, x, y, true, -32, true)
              break
            case 'sales-tax-c2':
              addAnnotation(instance, pageNum, `${pricing?.sales_tax}%`, 10, 220, 14, -14, x, y, false, 0)
              break
            case 'sales-tax-c3':
              addAnnotation(instance, pageNum, `$${salesFee.toFixed(2)}`, 10, 90, 14, -14, x, y, true, -31, true)
              break
            case 'service-fee-c2':
              addAnnotation(instance, pageNum, `${pricing?.service_tax}%`, 10, 220, 14, -14, x, y, false, 0)
              break
            case 'service-fee-c3':
              addAnnotation(instance, pageNum, `$${serviceFee.toFixed(2)}`, 10, 90, 14, -14, x, y, true, -21, true)
              break

            case 'card-fee-c3':
              if (cardFee > 0) {
                addAnnotation(instance, pageNum, `$${cardFee.toFixed(2)}`, 10, 90, 14, -14, x, y, true, -34, true)
              }
              break
            case 'card-fee-c2':
              if (cardFee > 0) {
                addAnnotation(instance, pageNum, `${pricing?.card_fee}%`, 10, 220, 14, -14, x, y, false, 0)
              }
              break
            case 'card-fee-c1':
              if (cardFee > 0) {
                addAnnotation(instance, pageNum, 'CARD FEE', 10, 120, 14, -14, x, y, false, -2)
              }
              break
            case 'r-total-c3':
              addAnnotation(instance, pageNum, `$${total.toFixed(2)}`, 10, 90, 14, -14, x, y, true, -46, true)
              break
            case 'deposit-c3':
              addAnnotation(instance, pageNum, `$${Number(pricing?.deposit).toFixed(2)}`, 10, 90, 14, -14, x, y, true, -40, true)
              break
            case 'today-date':
              addAnnotation(instance, pageNum, moment().format('MMMM Do YYYY, hh:mm A'), 10, 200, 14, -14, x, y, false, 0, false)
              break
            case 'tnc-string':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Terms and Conditions', 13, 200, 20, -14, x, y, true, -80, false)
              }
              break
            case 'tnc-insurance':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Insurance Agreement:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-indemnification':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Indemnification Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-damages':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Damages Liability and Loss of Use Agreement:', 13, 300, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-responsibility':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Driver Responsibility Provision:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-prohibited':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Prohibited Activities Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-liability':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Liability Limitation Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-cleaning':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Cleaning Fees Agreement:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-fuel':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Fuel Requirement Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-traffic':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Traffic Violations Agreement:', 13, 300, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-toll':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Toll Fees Agreement:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-dispute':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Dispute Resolution Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-payment':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Payment Authorization Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-agreement':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Agreement Validity Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-prohibition':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Prohibition of Subleasing Clause:', 13, 300, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-travel':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Travel Restrictions Provision:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-vehicle-return':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Vehicle Return Agreement:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-late':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Late Return Policy:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-termination':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Termination Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-speed':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Speed Violation Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-towing':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Towing and Retrieval Fees:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-loss':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Loss of Use Clause:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-illegal':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Illegal Activity:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-refunds':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Refunds & Rental Credits:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-arbitration':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Arbitration Agreement:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-tracking':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Vehicle Tracking and Recording:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-track-use':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Prohibited Track Use:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-tampering':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Vehicle Tampering:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-amendment':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Amendment Provision:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'tnc-law':
              if (isPreTrip) {
                addAnnotation(instance, pageNum, 'Governing Law:', 13, 200, 20, -14, x, y, true, 0, false)
              }
              break
            case 'customer-signature':
              addSignatureAnnotation(instance, pageNum, 200, 20, -14, x, y, 0)
              break
            case 'cust-signature-2':
              if (isPreTrip) {
                addSignatureAnnotation(instance, pageNum, 200, 20, -16, x, y, 0)
              }
              break
            case 'cust-signature-3':
              if (isPreTrip) {
                addSignatureAnnotation(instance, pageNum, 200, 20, -16, x, y, 0)
              }
              break
            case 'cust-signature-4':
              if (isPreTrip) {
                addSignatureAnnotation(instance, pageNum, 200, 20, -16, x, y, 0)
              }
              break
            case 'cust-signature-5':
              if (isPreTrip) {
                addSignatureAnnotation(instance, pageNum, 200, 20, -16, x, y, 0)
              }
              break
            case 'cust-signature-6':
              if (isPreTrip) {
                addSignatureAnnotation(instance, pageNum, 200, 20, -16, x, y, 0)
              }
              break
          }

          // console.log('all anotitations', allAnnotations)
          // if (allAnnotations.length === searchStrings.length) {
          //   annotationManager.addAnnotations(allAnnotations, { autoFocus: false })
          //   // annotationManager.drawAnnotationsFromList(allAnnotations)
          // }
        }
      }
    }

    const runner = () => {
      if (index + 1 === searchStrings.length) {
        console.log('need to clear runner ')
        clearRunner()
      } else if (index === 58) {
        setLoading(false)
      }
      documentViewer.textSearchInit(searchStrings[index], mode, searchOptions)
      index++
    }

    const id = setInterval(runner, 200)

    const clearRunner = () => {
      clearInterval(id)
      setLoading(false)
    }

    // instance.UI.addSearchListener((searchPattern, options, results) => {
    //   console.log('saerch listener', results)
    //   results.forEach(result => {
    //     console.log('result is', result)
    //     const { resultStr, pageNum } = result
    //     const x = result.quads[0].x1
    //     const y = result.quads[0].y1
    //

    //
    //     switch (resultStr) {
    //       case 'reserv-id':
    //         allAnnotations.push(getAnnotation(instance, pageNum, `#${reservation?._id}`, 11, 55, 14, -14, x, y))
    //         break
    //       case 'client-address':
    //         const fontConfig = getFontConfig(components[0], 40)
    //         allAnnotations.push(getAnnotation(instance, pageNum, components[0], fontConfig.fontSize, 200, fontConfig.height, (-14 + fontConfig.topMargin), x, y))
    //         break
    //       case 'client-name':
    //         allAnnotations.push(getAnnotation(instance, pageNum, name, 11, 200, 14, -14, x, y))
    //         break
    //     }
    //     // const annotation = new Annotations.RedactionAnnotation()
    //     // annotation.PageNumber = result.pageNum
    //     // annotation.Quads = result.quads.map(quad => quad.getPoints())
    //     // annotation.StrokeColor = new Annotations.Color(136, 39, 31)
    //     // return annotation
    //   })
    //
    //   const { documentViewer, annotationManager, Annotations } = instance.Core
    //
    //   annotationManager.addAnnotations(allAnnotations)
    //   annotationManager.drawAnnotationsFromList(allAnnotations)
    //
    //   documentViewer.clearSearchResults()
    //   hideSearchBar(instance)
    // })

    // start search after document loads
    // instance.UI.searchText('reserv-id', searchOptions)
    // instance.UI.searchText('client-address', searchOptions)
    // instance.UI.searchText('client-name', searchOptions)
  }

  const getFontConfig = (string, thresholdLength) => {
    let fontSize = 10
    let height = 14
    let topMargin = 0
    if (string.length > thresholdLength) {
      fontSize = 7
      height = 18
      topMargin = -2
    }

    return { fontSize, height, topMargin }
  }

  const hideSearchBar = (instance) => {
    console.log('hiding search bar')
    const iframeDoc = instance.UI.iframeWindow.document
    iframeDoc.querySelector('[data-element="searchButton"]').click()
  }

  const addSignatureAnnotation = (instance, pageNumber, width, height, topMargin, x, y, leftMargin) => {
    const { Annotations, annotationManager } = instance.Core
    const { WidgetFlags } = Annotations

    // set flags for required
    const flags = new WidgetFlags()
    flags.set('Required', true)

    // create a form field
    const field = new Annotations.Forms.Field('Sign Field', {
      type: 'Sig',
      flags
    })

    // create a widget annotation
    const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
      appearance: '_DEFAULT',
      appearances: {
        _DEFAULT: {
          Normal: {
            offset: {
              x: 50,
              y: 50
            }
          }
        }
      }
    })

    // set position and size
    widgetAnnot.PageNumber = pageNumber
    widgetAnnot.X = x + leftMargin
    widgetAnnot.Y = y + topMargin
    widgetAnnot.Width = width
    widgetAnnot.Height = height

    // add the form field and widget annotation
    annotationManager.getFieldManager().addField(field)
    annotationManager.addAnnotation(widgetAnnot)
    annotationManager.drawAnnotationsFromList([widgetAnnot])
  }

  const addAnnotation = (instance, pageNumber, replacementTxt, fontSize, width, height, topMargin, x, y, isBold, leftMargin = 0, rightAlign = false) => {
    const { Annotations, annotationManager } = instance.Core

    const freeText = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
      PageNumber: pageNumber,
      X: x + leftMargin,
      Y: y + topMargin,
      Width: width,
      Height: height,
      TextColor: new Annotations.Color(0, 0, 0),
      FillColor: new Annotations.Color(0, 255, 255, 0),
      StrokeColor: new Annotations.Color(255, 255, 255, 0),
      FontSize: `${fontSize}pt`
    })
    freeText.setContents(replacementTxt || '')
    if (rightAlign) {
      freeText.TextAlign = 'right'
    }
    if (replacementTxt === 'Terms and Conditions') {
      freeText.TextAlign = 'center'
    }

    if (isBold) {
      freeText.updateRichTextStyle({
        'font-weight': 'bold'
      })
    }

    annotationManager.addAnnotation(freeText, { autoFocus: false })
    annotationManager.redrawAnnotation(freeText)
  }

  return (
		<div className="MyComponent">
			{loading && <div style={{
			  position: 'fixed',
			  top: 0,
			  left: 0,
			  height: '100%',
			  width: '100%',
			  backgroundColor: '#00000044',
			  zIndex: 10,
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center'
			}}>
				<CustomLoaderSpinner/>
			</div>}
			{
				(isPreTrip && !reservation?.pre_contract) || (!isPreTrip && !reservation?.post_contract)
				  ? <div className="webviewer" ref={viewer} style={{ height: '100vh' }}></div>
				  : <iframe src={getDocument()} style={{ height: '100vh', width: '100vw' }}/>
			}

		</div>
  )
}
