import React, { useEffect, useRef, useState } from "react";
import moment from 'moment'
import Config from "../../config";
import axios from "axios";
import Helpers from '../../utils/Helpers'
import {LOGGER} from "../../utils/Logger";

const STORAGE_KEY = 'signatures_storage';
const ATTACHMENTS_KEY = 'attachments_storage';

export default function DDERentalPostPdfRenderer({document, reservation, shouldSave, onSave, token, backendUrl, shouldSendInvoice, onDocSend, checkSignatures, onSignatureChecked}) {
	const containerRef = useRef(null);
	const [instance, setInstance] = useState(null)
	const [pricing, setPricing] = useState(null)
	const [priceSettings, setPriceSettings] = useState(null)
	const [PsPdfKit, setPsPdfKit] = useState(null)
	const [signatureStorage, setSignatureStorage] = useState({
		[STORAGE_KEY]: null,
		[ATTACHMENTS_KEY]: null
	})

	useEffect(() => {
		const getPriceSettings = async() => {
			const config = {
				method: 'get',
				url: `${backendUrl}settings`,
				headers: {Authorization: token},
			}

			try {
				let res = await axios(config)
				let settings = res.data
				settings.forEach(setting => {
					if(setting._id === 'pricing')
						setPriceSettings(setting)
				})
			}catch(err) {
				LOGGER.error('Error when getting settings data', err)
			}
		}

		getPriceSettings()
	},[])

	useEffect(() => {
		if(reservation && priceSettings && Object.keys(priceSettings).length > 0) {
			let pricing = Helpers.getRentalsPricingObject(reservation, reservation.vehicle_details, priceSettings)
			setPricing(pricing)
		}
	},[reservation, priceSettings])

	useEffect(() => {
		if(shouldSave) {
			const savePdf = async () => {
				const documentBuffer = await instance.exportPDF();
				onSave(documentBuffer)
			}

			savePdf()
		}
	},[shouldSave])

	async function getInkSignatureForField(PSPDFKit, instance, formField) {
		const pagesAnnotations = await Promise.all(
			Array.from({ length: instance.totalPageCount }).map((_, pageIndex) =>
				instance.getAnnotations(pageIndex)
			)
		);
		let signatureBoundingBox;
		const signaturePageAnnotations = pagesAnnotations.filter(pageAnnotations => {
			const signatureWidget = pageAnnotations.find(
				annotation => annotation.id === formField.annotationIds.first()
			);
			if (signatureWidget) {
				signatureBoundingBox = signatureWidget.boundingBox;
				return true;
			}
			return false;
		});
		return signaturePageAnnotations[0] && signaturePageAnnotations[0].find(annotation => {
			if (annotation instanceof PSPDFKit.Annotations.InkAnnotation || annotation instanceof PSPDFKit.Annotations.StampAnnotation) {
				if (annotation.boundingBox.isRectOverlapping(signatureBoundingBox)) {
					return true;
				}
			}
		});
	}

	useEffect(() => {
		if(checkSignatures) {
			console.log('checkin signatures')
			// onSignatureChecked(true)
			const getInkSignature = async () => {
				const formFields = await instance.getFormFields()
				const signatureFormFields = formFields.filter(formField => formField instanceof PsPdfKit.FormFields.SignatureFormField)
				console.log('form field', signatureFormFields)
				let filledSigns = 0
				for (const signatureFormField of signatureFormFields) {
					const signatureInkAnnotation = signatureFormField
						&& await getInkSignatureForField(PsPdfKit, instance, signatureFormField)
					if(signatureInkAnnotation && signatureInkAnnotation.toJS()){
						filledSigns++
					}
				}

				if(filledSigns === signatureFormFields.size) {
					console.log('all signs filled')
					onSignatureChecked(true)
				} else {
					console.log('missing signs')
					onSignatureChecked(false)
				}

			}

			getInkSignature()
		}
	},[checkSignatures])

	useEffect(() => {
		if(shouldSendInvoice) {
			const exportPdf = async () => {
				const documentBuffer = await instance.exportPDF();
				onDocSend(documentBuffer)
			}

			exportPdf()
		}
	},[shouldSendInvoice])

	useEffect(() => {
		if(!priceSettings || Object.keys(priceSettings).length === 0 || !pricing || Object.keys(pricing).length === 0){
			return
		}

		const container = containerRef.current;
		let PSPDFKit, tempInstance;

		(async function() {
			PSPDFKit = await import("pspdfkit");
			setPsPdfKit(PSPDFKit)
			tempInstance = await PSPDFKit.load({
				// Container where PSPDFKit should be mounted.
				container,
				licenseKey: Config.PSPDFKIT_LICENSE_KEY,
				// The document to open.
				document: reservation?.post_contract?.url || document,
				baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`,
				isEditableAnnotation: (annotation) =>
					!(annotation instanceof PSPDFKit.Annotations.TextAnnotation)
			})

			setInstance(tempInstance)

			//add stored signatures
			const signaturesString = signatureStorage[STORAGE_KEY]
			const storedSignatures = JSON.parse(signaturesString);
			// Construct annotations from serialized entries and call the `setStoredSignatures` API.
			const list = PSPDFKit.Immutable.List(
				storedSignatures?.map(PSPDFKit.Annotations.fromSerializableObject),
			);
			tempInstance.setStoredSignatures(list);

			// Retrieve attachments and add them to the instance.
			const attachmentsString = signatureStorage[ATTACHMENTS_KEY]
			if (attachmentsString) {
				const attachmentsArray = JSON.parse(attachmentsString);
				// Instantiate blob objects from the data URLs on local storage.
				const blobs = await Promise.all(
					attachmentsArray?.map(({ url }) => fetch(url).then((res) => res.blob())),
				);
				// Create an attachment for each blob.
				blobs.forEach(tempInstance.createAttachment);
			}

			addSignatureListener(PSPDFKit,tempInstance)


			// createAnnotation(PSPDFKit, tempInstance, 'ma-4', 'ma-4', 26, '100', 12, 12, 0, 4)
			if(!reservation?.post_contract) {
				//page 1 details
				//client details
				let {fName, lName, phone, address, email} = reservation.client_details
				let cusAddress = address?.label || ' , '
				let components = cusAddress.split(',')
				let cityPlace = ''
				components.forEach((each,i) => {
					if(i !== 0)
						cityPlace += each
				})
				//page 2 details

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'pickup-datetime-2',
					'pickup-datetime-2',
					140,
					moment(reservation?.pickup_datetime).format('MMM DD, YYYY hh:mm A'),
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'dropoff-datetime-2',
					'dropoff-datetime-2',
					140,
					moment(reservation?.dropoff_datetime).format('MMM DD, YYYY hh:mm A'),
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'ending-miles-2',
					'ending-miles-2',
					140,
					reservation.check_out ? `${reservation.check_out.mileage} miles` : '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'ending-fuel-2',
					'ending-fuel-2',
					140,
					reservation.check_out ? `${reservation.check_out.fuel}%` : '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'customer-name-2',
					'customer-name-2',
					380,
					`${fName} ${lName}`,
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'customer-street-address-2',
					'customer-street-address-2',
					380,
					components[0] || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'customer-city-place-2',
					'customer-city-place-2',
					380,
					cityPlace || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'customer-phone-2',
					'customer-phone-2',
					380,
					phone || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'customer-email-2',
					'customer-email-2',
					380,
					email || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				//table info
				createAnnotation(
					PSPDFKit,
					tempInstance,
					'vehicle-2',
					'vehicle-2',
					266,
					reservation?.vehicle_details?.make || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				let totalDays = Helpers.getTotalDays(reservation)
				createAnnotation(
					PSPDFKit,
					tempInstance,
					'total-days-2',
					'total-days-2',
					92,
					totalDays ? `${totalDays} day(s)` : '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'daily-price-2',
					'daily-price-2',
					84,
					Number(reservation.dailyPrice)?.toFixed(2) || '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'total-price-t-2',
					'total-price-t-2',
					84,
					reservation.dailyPrice ? `${(reservation.dailyPrice * totalDays).toFixed(2)}` : '',
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				let locationFee = pricing?.location_fee || 0
				if(typeof reservation.pickup_location !== 'string') {
					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-1-2',
						'l-1-2',
						266,
						'Addition Pick-Up Location',
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)


					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-1-c1-p-2',
						'l-1-c1-p-2',
						84,
						locationFee.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)

					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-1-c2-p-2',
						'l-1-c2-p-2',
						84,
						locationFee.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)
				}


				if(typeof reservation.dropoff_location !== 'string') {
					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-2-2',
						'l-2-2',
						266,
						'Addition Drop-Off Location',
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)

					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-2-c1-p-2',
						'l-2-c1-p-2',
						84,
						locationFee.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)

					createAnnotation(
						PSPDFKit,
						tempInstance,
						'l-2-c2-p-2',
						'l-2-c2-p-2',
						84,
						locationFee.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)
				}

				let addOns = {}
				if(reservation && reservation.check_in && reservation.check_out) {
					//if te check out info has been added, calculate the add ons for mileage and fuel
					/*let startFuel = reservationToUpdate.check_in.fuel
					let endFuel = reservationToUpdate.check_out.fuel
					if(endFuel < startFuel)
						addOns['fuel'] = {
							value: startFuel - endFuel,
							price: Math.round((startFuel - endFuel)/10 * pricing?.fuel_charge)
						}*/

					let startMileage = reservation.check_in.mileage
					let endMileage = reservation.check_out.mileage
					let mileageAllowance = pricing?.mileage_allowance * Helpers.getTotalDays(reservation)
					if((endMileage - startMileage) > mileageAllowance) {
						addOns['mileage'] = {
							value: endMileage - startMileage - mileageAllowance,
							price: Number((endMileage - startMileage - mileageAllowance) * pricing?.mileage_overage_price)
						}
					}
				}

				if(typeof reservation.pickup_location !== 'string')
					addOns['location1'] = {value: '', price: pricing?.location_fee}

				if(typeof reservation.dropoff_location !== 'string')
					addOns['location2'] = {value: '', price: pricing?.location_fee}


				let tempTotalPrice = reservation.totalPrice
				if(Object.keys(addOns).length > 0)
					Object.values(addOns).forEach(value => tempTotalPrice += Number(value.price))

				let discount = Number(Helpers.getDiscountedAmount(pricing, tempTotalPrice))
				let subTotal = tempTotalPrice - discount
				let salesFee = ((subTotal * pricing?.sales_tax)/100)
				let serviceFee = ((subTotal * pricing?.service_tax)/100)
				let total = subTotal + serviceFee + salesFee
				let totalTax = salesFee + serviceFee

				if(addOns['mileage']) {
					// there is a mileage overage price that needs to be shown
					createAnnotation(
						PSPDFKit,
						tempInstance,
						'm-l-2',
						'm-l-2',
						266,
						`Add ${addOns['mileage']?.value} miles`,
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)


					createAnnotation(
						PSPDFKit,
						tempInstance,
						'mop-c1-2',
						'mop-c1-2',
						84,
						addOns['mileage']?.price?.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)

					createAnnotation(
						PSPDFKit,
						tempInstance,
						'mop-c2-2',
						'mop-c1-2',
						84,
						addOns['mileage']?.price?.toFixed(2),
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)
				}

				if(discount > 0) {
					//if discount has been added, add that line as well
					createAnnotation(
						PSPDFKit,
						tempInstance,
						'dis-label-2',
						'dis-label-2',
						266,
						`Discount - ${pricing?.discount?.label}`,
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)


					createAnnotation(
						PSPDFKit,
						tempInstance,
						'dis-p-c1-2',
						'dis-p-c1-2',
						84,
						`${discount.toFixed(2)}`,
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)

					createAnnotation(
						PSPDFKit,
						tempInstance,
						'dis-p-c2-2',
						'dis-p-c1-2',
						84,
						`(${discount.toFixed(2)})`,
						12,
						14,
						0,
						1,
						PSPDFKit.Color.TRANSPARENT
					)
				}

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'subtotal-2',
					'subtotal-2',
					84,
					`${subTotal.toFixed(2)}`,
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'service-2',
					'service-2',
					84,
					`${totalTax.toFixed(2)}`,
					12,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'total-price-2',
					'total-price-2',
					84,
					`${total.toFixed(2)}`,
					16,
					18,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'security-deposit-2',
					'security-deposit-2',
					84,
					`$ ${pricing?.deposit?.toFixed(2)}`,
					14,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createAnnotation(
					PSPDFKit,
					tempInstance,
					'today-date-2',
					'today-date-2',
					84,
					`${moment().format('MMM DD, YYYY')}`,
					14,
					14,
					0,
					1,
					PSPDFKit.Color.TRANSPARENT
				)

				createSignatureAnnotation(PSPDFKit, tempInstance, 'customer-signature-2', 'customer-signature-2', 250, 20, 1,0)

			}

			const items = tempInstance.toolbarItems;
			let ids = ['export-pdf', 'zoom-out', 'zoom-in', 'pager', 'pan']
			tempInstance.setToolbarItems(items.filter((item) => ids.includes(item.type)));


		})();

		return () => PSPDFKit && PSPDFKit.unload(container);
	}, [priceSettings, pricing]);

	const createSignatureAnnotation = async (PSPDFKit, instance, searchString, name, width, height,pageNumber, topMargin) => {

		const bbox1 = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);

		let signatureWidget = new PSPDFKit.Annotations.WidgetAnnotation({
			id: PSPDFKit.generateInstantId(),
			pageIndex: pageNumber-1,
			boundingBox: new PSPDFKit.Geometry.Rect({
				left: bbox1.left,
				top: bbox1.top + topMargin,
				width: width,
				height: height,
			}),
			formFieldName: name,
			// backgroundColor: PSPDFKit.Color.YELLOW
		});

		let signatureField = new PSPDFKit.FormFields.SignatureFormField({
			name: name,
			annotationIds: new PSPDFKit.Immutable.List([signatureWidget.id]),
		});

		instance.create([signatureField, signatureWidget]);
	}

	const addSignatureListener = (PSPDFKit,tempInstance) => {
		tempInstance.addEventListener('storedSignatures.create', async (annotation) => {
			const signaturesString = signatureStorage[STORAGE_KEY]
			const storedSignatures = signaturesString
				? JSON.parse(signaturesString)
				: [];

			const serializedAnnotation = PSPDFKit.Annotations.toSerializableObject(
				annotation,
			);
			if (annotation.imageAttachmentId) {
				const attachment = await instance.getAttachment(
					annotation.imageAttachmentId,
				);

				// Create the data URL and add it to local storage.
				// Note: This is done only for demonstration purposes.
				// Storing potentially large chunks of data using local storage is
				// considered bad practice due to the synchronous nature of the API.
				// For production applications, please consider alternatives such as
				// dedicated backend storage or IndexedDB.
				const url = URL.createObjectURL(attachment);
				const attachmentsString = signatureStorage[ATTACHMENTS_KEY]
				const attachmentsArray = attachmentsString
					? JSON.parse(attachmentsString)
					: [];
				attachmentsArray.push({ url, id: annotation.imageAttachmentId });
				// Separate the `localStorage` item to store attachments.
				let temp = Object.assign({}, signatureStorage, {[ATTACHMENTS_KEY]: JSON.stringify(attachmentsArray)})
				setSignatureStorage(temp)
			}
			storedSignatures.push(serializedAnnotation);
			let temp = Object.assign({}, signatureStorage, {[STORAGE_KEY]: JSON.stringify(storedSignatures)})
			setSignatureStorage(temp)
			// Add a new annotation so that it renders as part of the UI on the current session.
			tempInstance.setStoredSignatures((signatures) => signatures.push(annotation));
			console.log('stored signature')
		});
	}

	const createAnnotation = async (PSPDFKit, instance, searchString, name, width, textToOverlay, fontSize, height, topMargin, pageNumber, color) => {
		const bbox = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);


		let {left, top} = bbox

		const textAnnotation = new PSPDFKit.Annotations.TextAnnotation({
			boundingBox: new PSPDFKit.Geometry.Rect({
				left: left,
				top: top + topMargin,
				width: width,
				height: height,
			}),
			fontSize: fontSize,
			text: { format: "plain", value : textToOverlay },
			pageIndex: pageNumber-1,
			formFieldName: name,
			backgroundColor: color
		});

		instance.create([textAnnotation]);

	}

	return (
		<div ref={containerRef} style={{ width: "100%", height: "100%"}}/>
	);
}
