import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import GenericPdfRenderer from '../components/GenericPdfRenderer'

const AxleDocViewer = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [url, setUrl] = useState(null)

  useEffect(() => {
    console.log('searcj paramts', searchParams.get('url'))
    const urlParam = searchParams.get('url')
    if (!urlParam) {
      window.alert('Missing Url parameter')
      return
    }
    setUrl(urlParam)
  }, [])

  return (
		<div style={{ width: '100%', height: '100vh' }}>
			{
				url && <GenericPdfRenderer url={url}/>
			}
		</div>
  )
}

export default AxleDocViewer
