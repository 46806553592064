import React, { useEffect, useRef, useState } from "react";
import moment from 'moment'
import Config from "../../config";
import axios from "axios";
import Helpers from '../../utils/Helpers'
import {LOGGER} from "../../utils/Logger";

const STORAGE_KEY = 'signatures_storage';
const ATTACHMENTS_KEY = 'attachments_storage';

export default function DDEChauffeurPdfRenderer({document, reservation, shouldSave, onSave, token, backendUrl, shouldSendInvoice, onDocSend, checkSignatures, onSignatureChecked}) {
	const containerRef = useRef(null);
	const [instance, setInstance] = useState(null)
	const [pricing, setPricing] = useState(null)
	const [priceSettings, setPriceSettings] = useState(null)
	const [PsPdfKit, setPsPdfKit] = useState(null)
	const [signatureStorage, setSignatureStorage] = useState({
		[STORAGE_KEY]: null,
		[ATTACHMENTS_KEY]: null
	})

	useEffect(() => {
		const getPriceSettings = async() => {
			const config = {
				method: 'get',
				url: `${backendUrl}settings`,
				headers: {Authorization: token},
			}

			try {
				let res = await axios(config)
				let settings = res.data
				settings.forEach(setting => {
					if(setting._id === 'pricing')
						setPriceSettings(setting)
				})
			}catch(err) {
				LOGGER.error('Error when getting settings data', err)
			}
		}

		getPriceSettings()
	},[])

	useEffect(() => {
		if(reservation && priceSettings && Object.keys(priceSettings).length > 0) {
			let pricing = Helpers.getChauffeurPricingObject(reservation, reservation.vehicle_details, priceSettings)
			setPricing(pricing)
		}
	},[reservation, priceSettings])

	useEffect(() => {
		if(shouldSave) {
			const savePdf = async () => {
				const documentBuffer = await instance.exportPDF();
				onSave(documentBuffer)
			}

			savePdf()
		}
	},[shouldSave])

	async function getInkSignatureForField(PSPDFKit, instance, formField) {
		const pagesAnnotations = await Promise.all(
			Array.from({ length: instance.totalPageCount }).map((_, pageIndex) =>
				instance.getAnnotations(pageIndex)
			)
		);
		let signatureBoundingBox;
		const signaturePageAnnotations = pagesAnnotations.filter(pageAnnotations => {
			const signatureWidget = pageAnnotations.find(
				annotation => annotation.id === formField.annotationIds.first()
			);
			if (signatureWidget) {
				signatureBoundingBox = signatureWidget.boundingBox;
				return true;
			}
			return false;
		});
		return signaturePageAnnotations[0] && signaturePageAnnotations[0].find(annotation => {
			if (annotation instanceof PSPDFKit.Annotations.InkAnnotation || annotation instanceof PSPDFKit.Annotations.StampAnnotation) {
				if (annotation.boundingBox.isRectOverlapping(signatureBoundingBox)) {
					return true;
				}
			}
		});
	}

	useEffect(() => {
		if(checkSignatures) {
			console.log('checkin signatures')
			// onSignatureChecked(true)
			const getInkSignature = async () => {
				const formFields = await instance.getFormFields()
				const signatureFormFields = formFields.filter(formField => formField instanceof PsPdfKit.FormFields.SignatureFormField)
				console.log('form field', signatureFormFields)
				let filledSigns = 0
				for (const signatureFormField of signatureFormFields) {
					const signatureInkAnnotation = signatureFormField
						&& await getInkSignatureForField(PsPdfKit, instance, signatureFormField)
					if(signatureInkAnnotation && signatureInkAnnotation.toJS()){
						filledSigns++
					}
				}

				if(filledSigns === signatureFormFields.size) {
					console.log('all signs filled')
					onSignatureChecked(true)
				} else {
					console.log('missing signs')
					onSignatureChecked(false)
				}

			}

			getInkSignature()
		}
	},[checkSignatures])

	useEffect(() => {
		if(shouldSendInvoice) {
			const exportPdf = async () => {
				const documentBuffer = await instance.exportPDF();
				onDocSend(documentBuffer)
			}

			exportPdf()
		}
	},[shouldSendInvoice])

	const getTotalTime = () => {
		return (moment(reservation.pickup_datetime).diff(moment(reservation.dropoff_datetime), 'hours'))
	}

	useEffect(() => {
		if(!priceSettings || Object.keys(priceSettings).length === 0 || !pricing || Object.keys(pricing).length === 0){
			return
		}

		const container = containerRef.current;
		let PSPDFKit, tempInstance;

		(async function() {
			PSPDFKit = await import("pspdfkit");
			setPsPdfKit(PSPDFKit)
			tempInstance = await PSPDFKit.load({
				// Container where PSPDFKit should be mounted.
				container,
				licenseKey: Config.PSPDFKIT_LICENSE_KEY,
				// The document to open.
				document: reservation?.contract?.url || document,
				baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`,
				isEditableAnnotation: (annotation) =>
					!(annotation instanceof PSPDFKit.Annotations.TextAnnotation)
			})

			setInstance(tempInstance)

			//add stored signatures
			const signaturesString = signatureStorage[STORAGE_KEY]
			const storedSignatures = JSON.parse(signaturesString);
			// Construct annotations from serialized entries and call the `setStoredSignatures` API.
			const list = PSPDFKit.Immutable.List(
				storedSignatures?.map(PSPDFKit.Annotations.fromSerializableObject),
			);
			tempInstance.setStoredSignatures(list);

			// Retrieve attachments and add them to the instance.
			const attachmentsString = signatureStorage[ATTACHMENTS_KEY]
			if (attachmentsString) {
				const attachmentsArray = JSON.parse(attachmentsString);
				// Instantiate blob objects from the data URLs on local storage.
				const blobs = await Promise.all(
					attachmentsArray?.map(({ url }) => fetch(url).then((res) => res.blob())),
				);
				// Create an attachment for each blob.
				blobs.forEach(tempInstance.createAttachment);
			}

			addSignatureListener(PSPDFKit,tempInstance)

			if(!reservation?.contract) {
				createSignatureAnnotation(PSPDFKit, tempInstance, 'customer-signature-1', 'signature-1', 250, 20)

				//pickup date time
				let pickupDateTime = moment(reservation.pickup_datetime)
				createAnnotation(PSPDFKit, tempInstance, 'pickup-date-n-time', 'pickup-datetime-field-1', 150, pickupDateTime.format('MMM DD, YYYY hh:mm A'), 12, 14, 0)


				//chauffeur location
				let locations = reservation.locations || []
				let location = locations[0]?.label
				let fontSize = 10
				let topMargin = 0
				if(location.length > 28) {
					fontSize = 8
					topMargin = -5
				}
				createAnnotation(PSPDFKit, tempInstance, 'pickup-location', 'pickup-location-1', 150, location, fontSize, 18, topMargin)


				//vehicle make
				createAnnotation(PSPDFKit, tempInstance, 'vehicle-make', 'vehicle-make', 150, reservation.vehicle_details?.make, 12, 14, 0)

				//client details
				let {fName, lName, phone, address, email} = reservation.client_details
				let cusAddress = address?.label || ' , '
				let components = cusAddress.split(',')
				let cityPlace = ''
				components.forEach((each,i) => {
					if(i !== 0)
						cityPlace += each
				})
				createAnnotation(PSPDFKit, tempInstance, 'customer-name', 'customer-name', 380, `${fName} ${lName}`, 12, 14, 0)

				createAnnotation(PSPDFKit, tempInstance, 'street-address', 'street-address', 380, components[0], 12, 14, 0)
				createAnnotation(PSPDFKit, tempInstance, 'city-state-zip', 'city-state-zip', 380, cityPlace, 12, 14, 0)

				createAnnotation(PSPDFKit, tempInstance, 'customer-phone', 'customer-phone', 380, phone, 12, 14, 0)
				createAnnotation(PSPDFKit, tempInstance, 'customer-email', 'customer-email', 380, email, 12, 14, 0)

				//table info
				reservation.locations.forEach((location,index) =>{
					let label = location.label
					let fontSize = 10
					let topMargin = 0
					let height = 20
					if(label.length > 60) {
						fontSize = 8
						topMargin = -3
						height = 30
					}
					// console.log('font size is', fontSize)
					createAnnotation(PSPDFKit, tempInstance, `location-${index}`, `location-${index}`, 320, label, fontSize, height, topMargin)
				})

				createAnnotation(PSPDFKit, tempInstance, 'pickup-datetime', 'pickup-datetime', 100, moment(reservation.pickup_datetime).format('hh:mm A'), 12, 14, 0)
				createAnnotation(PSPDFKit, tempInstance, 'return-datetime', 'return-datetime', 100, moment(reservation.dropoff_datetime).format('hh:mm A'), 12, 14, 0)

				createAnnotation(PSPDFKit, tempInstance, 'hourly-price', 'hourly-price', 100, `${Number(reservation.hourlyPrice)?.toFixed(2)}`, 12, 14, 0)

				let addOns = {}
				if(reservation && reservation.check_in && reservation.check_out) {

					let startMileage = reservation.check_in.mileage
					let endMileage = reservation.check_out.mileage
					let totalDays = Math.ceil(getTotalTime()/24)
					let mileageAllowance = pricing?.mileage_allowance * totalDays
					if((endMileage - startMileage) > mileageAllowance) {
						addOns['mileage'] = {
							value: endMileage - startMileage - mileageAllowance,
							price: Number((endMileage - startMileage - mileageAllowance) * pricing?.mileage_overage_price)
						}
					}
				}

				let tempTotalPrice = reservation.totalPrice
				if(Object.keys(addOns).length > 0)
					Object.values(addOns).forEach(value => {
						tempTotalPrice += Number(value.price)
					})


				let discount = Number(Helpers.getDiscountedAmount(pricing, tempTotalPrice))
				let subTotal = tempTotalPrice - discount
				let serviceFee = Number((pricing?.service_tax*subTotal)/100)
				let total = subTotal + serviceFee

				createAnnotation(PSPDFKit, tempInstance, 'sub-total', 'sub-total', 100, `${subTotal.toFixed(2)}`, 12, 14, 0)
				createAnnotation(PSPDFKit, tempInstance, 'service-fee', 'service-fee', 100, `${serviceFee?.toFixed(2)}`, 12, 14, 0)
				createAnnotation(PSPDFKit, tempInstance, 'total-price', 'total-price', 100, `${total?.toFixed(2)}`, 15, 20, 0)

				if(discount > 0) {
					createAnnotation(PSPDFKit, tempInstance, 'dis-label-1', 'dis-label-1', 320, `Discount - ${pricing?.discount?.label}`, 12, 20, 0)
					/*createAnnotation(PSPDFKit, tempInstance, 'dis-p-c1-1', 'dis-p-c1-1', 320, `${discount.toFixed(2)}`, 12, 20, 0)*/
					createAnnotation(PSPDFKit, tempInstance, 'dis-p-c2-1', 'dis-p-c2-1', 320, `(${discount.toFixed(2)})`, 12, 20, 0)
				}

				createAnnotation(PSPDFKit, tempInstance, 'today-date', 'today-date', 100, moment().format('MMM DD, YYYY'), 14, 20, 0)
			}

			const items = tempInstance.toolbarItems;
			let ids = ['export-pdf', 'zoom-out', 'zoom-in', 'pager', 'pan']
			tempInstance.setToolbarItems(items.filter((item) => ids.includes(item.type)));


		})();

		return () => PSPDFKit && PSPDFKit.unload(container);
	}, [priceSettings, pricing]);


	const createSignatureAnnotation = async (PSPDFKit, instance, searchString, name, width, height) => {

		const bbox1 = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);

		let signatureWidget = new PSPDFKit.Annotations.WidgetAnnotation({
			id: PSPDFKit.generateInstantId(),
			pageIndex: 0,
			boundingBox: new PSPDFKit.Geometry.Rect({
				left: bbox1.left,
				top: bbox1.top,
				width: width,
				height: height,
			}),
			formFieldName: name,
			// backgroundColor: PSPDFKit.Color.YELLOW
		});

		let signatureField = new PSPDFKit.FormFields.SignatureFormField({
			name: name,
			annotationIds: new PSPDFKit.Immutable.List([signatureWidget.id]),
		});

		instance.create([signatureField, signatureWidget]);
	}

	const addSignatureListener = (PSPDFKit,tempInstance) => {
		tempInstance.addEventListener('storedSignatures.create', async (annotation) => {
			const signaturesString = signatureStorage[STORAGE_KEY]
			const storedSignatures = signaturesString
				? JSON.parse(signaturesString)
				: [];

			const serializedAnnotation = PSPDFKit.Annotations.toSerializableObject(
				annotation,
			);
			if (annotation.imageAttachmentId) {
				const attachment = await instance.getAttachment(
					annotation.imageAttachmentId,
				);

				// Create the data URL and add it to local storage.
				// Note: This is done only for demonstration purposes.
				// Storing potentially large chunks of data using local storage is
				// considered bad practice due to the synchronous nature of the API.
				// For production applications, please consider alternatives such as
				// dedicated backend storage or IndexedDB.
				const url = URL.createObjectURL(attachment);
				const attachmentsString = signatureStorage[ATTACHMENTS_KEY]
				const attachmentsArray = attachmentsString
					? JSON.parse(attachmentsString)
					: [];
				attachmentsArray.push({ url, id: annotation.imageAttachmentId });
				let temp = Object.assign({}, signatureStorage, {[ATTACHMENTS_KEY]: JSON.stringify(attachmentsArray)})
				setSignatureStorage(temp)
			}
			storedSignatures.push(serializedAnnotation);
			let temp = Object.assign({}, signatureStorage, {[STORAGE_KEY]: JSON.stringify(storedSignatures)})
			setSignatureStorage(temp)
			// Add a new annotation so that it renders as part of the UI on the current session.
			tempInstance.setStoredSignatures((signatures) => signatures.push(annotation));
			console.log('stored signature')
		});
	}

	const createAnnotation = async (PSPDFKit, instance, searchString, name, width, textToOverlay, fontSize, height, topMargin) => {
		const bbox = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);

		let {left, top} = bbox

		const textAnnotation = new PSPDFKit.Annotations.TextAnnotation({
			boundingBox: new PSPDFKit.Geometry.Rect({
				left: left,
				top: top + topMargin,
				width: width,
				height: height,
			}),
			fontSize: fontSize,
			text: { format: "plain", value : textToOverlay },
			pageIndex: 0,
			formFieldName: name,
			backgroundColor: PSPDFKit.Color.TRANSPARENT
		});

		instance.create([textAnnotation]);

	}

	return (
		<div ref={containerRef} style={{ width: "100%", height: "100%"}}/>
	);
}
