import './App.scss'
import { Outlet } from 'react-router-dom'

window.Buffer = window.Buffer || require('buffer').Buffer

function App () {
  return (
		<main>
			<Outlet/>
		</main>
  )
}

export default App
