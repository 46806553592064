export const API_CALL_STATUS = {
  SUCCEEDED: 1,
  LOADING: 2,
  FAILED: 3,
  IDLE: 4
}

export const RENTAL_STATUS = {
  WAITING_PICKUP: 1,
  RENTING: 2,
  RETURNED: 3,
  CANCELLED: 4
}

export const LOCATIONS = ['Bolingbrook', 'Indiana', 'Delivery']
export const LOCATION = 'Bolingbrook Office HQ'
export const MILEAGE_ALLOWANCE = 100
export const MILEAGE_OVERAGE_FEE = 5

export const VEHICLE_TYPES = ['SUV', 'Sports', 'Luxury', 'Convertible']

export const PERMISSIONS = {
  view_reservations: 'View Reservations', // done
  create_reservations: 'Create Reservations', // done
  delete_reservations: 'Delete Reservations', // done
  edit_prices: 'Edit Pricing', // done
  custom_discounts: 'Custom Discounts', // done
  view_analytics: 'View Analytics', // done
  edit_vehicles: 'Edit Vehicles', // done
  insurance_claims: 'Insurance Claims', // later
  view_edit_settings: 'View & Edit Settings'// done
}

export const CONTRACT_TYPES = {
  chauffeur: 1,
  rental_pre: 2,
  rental_post: 3
}

export const EMAIL_SQS_TYPE = {
  NEW_RES: 1,
  SIGN_REQUEST: 2,
  RENTAL_INVOICE: 3,
  THANK_YOU: 4
}

export const WEBSITE_PAYMENT_REQUIREMENTS = {
  PAYMENT: 'Payment',
  NO_PAYMENT: 'No Payment'
}

export const WEBSITE_PAYMENT_AMOUNT = {
  FULL_PAYMENT: 'Full Payment',
  PARTIAL_PAYMENT: 'Partial Payment',
  MINIMUM_PAYMENT: 'Minimum Payment'
}
export const VIEW_TYPES = {
  list: 1,
  dropdown: 2
}
