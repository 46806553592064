import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET_SETTINGS, UPDATE_SETTINGS } from '../actionTypes'
import { API_CALL_STATUS, WEBSITE_PAYMENT_AMOUNT, WEBSITE_PAYMENT_REQUIREMENTS } from '../../metadata/enums'
import Config from '../../config'
import axios from 'axios'
import { LOGGER } from '../../utils/Logger'

const initialState = {
  settings: [],
  status: API_CALL_STATUS.IDLE,
  error: null,
  updated_settings: false,
  viewType: null
}

export const getSettingsData = createAsyncThunk(GET_SETTINGS, async (payload) => {
  const config = {
    method: 'get',
    url: `${payload.backendUrl}settings`,
    headers: { Authorization: payload.token }
  }
  try {
    const res = await axios(config)
    return res.data
  } catch (err) {
    LOGGER.error('Error when getting settings data', err)
    return []
  }
})

export const updateSettingsData = createAsyncThunk(UPDATE_SETTINGS, async (payload) => {
  const config = {
    method: 'put',
    url: `${Config.BACKEND_URL}settings`,
    headers: { Authorization: payload.token },
    data: payload.data
  }
  try {
    const res = await axios(config)
    return payload.data
  } catch (err) {
    LOGGER.error('Error when updating settings data', err)
    return []
  }
})

const settingsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    updateUpdatedStatus (state, action) {
      return Object.assign({}, state, { updated_settings: action.payload })
    },
    updateViewType (state, action) {
      return Object.assign({}, state, { viewType: action.payload })
    }
  },
  extraReducers (builder) {
    builder
      .addCase(getSettingsData.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(getSettingsData.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        state.settings = action.payload
      })
      .addCase(getSettingsData.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
      .addCase(updateSettingsData.pending, (state, action) => {
        state.status = API_CALL_STATUS.LOADING
      })
      .addCase(updateSettingsData.fulfilled, (state, action) => {
        state.status = API_CALL_STATUS.SUCCEEDED
        const temp = []
        const updatedSettings = action.payload
        state.settings.forEach(setting => {
          if (setting._id === updatedSettings._id) {
            temp.push(Object.assign({}, setting, updatedSettings))
          } else {
            temp.push(setting)
          }
        })

        state.settings = temp
        state.updated_settings = true
      })
      .addCase(updateSettingsData.rejected, (state, action) => {
        state.status = API_CALL_STATUS.FAILED
        state.error = action.error.message
      })
  }
})

export const getSalesSettings = (state) => {
  let salesSettings = { active: [], in_active: [] }
  state.settings.forEach(setting => {
    if (setting._id === 'sales') {
      salesSettings = setting
    }
  })
  return salesSettings
}

export const getBusinessSettings = (state) => {
  let pricing = { name: '', email: '', phone: '' }
  state.settings.forEach(setting => {
    if (setting._id === 'businessDetails') {
      pricing = setting
    }
  })
  return pricing
}

export const getPriceSettings = (state) => {
  let pricing = { sales_tax: 0, service_tax: { rentals: 0, chauffeur: 0 } }
  state.settings.forEach(setting => {
    if (setting._id === 'pricing') {
      pricing = setting
    }
  })
  return pricing
}

export const getIntegrationSettings = (state) => {
  let integration = {
    authorizeNetIntegration: { name: '', transactionKey: '' },
    websiteIntegration: {
      payment_requirement: WEBSITE_PAYMENT_REQUIREMENTS.PAYMENT,
      payment_amount_requirement: WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT,
      amount: '',
      minimum_duration: 24,
      grace_period: 0,
      business_hours: {
        m_to_f: { start: '', end: '', status: true },
        sat: { start: '', end: '', status: true },
        sun: { start: '', end: '', status: true }
      },
      instant: true,
      require_id: false,
      custom_delivery: true,
      sales_tax: 0,
      service_tax: { rentals: 0, chauffeur: 0 },
      location_fee: 0,
      card_fee: 0
    }
  }
  state.settings.forEach(setting => {
    if (setting._id === 'integration') {
      integration = setting
    }
  })
  return integration
}

export const getPermissionSettings = (state) => {
  let permissions = { roles: [] }
  state.settings.forEach(setting => {
    if (setting._id === 'permissions') {
      permissions = setting
    }
  })
  return permissions
}

export const getRolesMap = (state) => {
  const map = {}
  state.settings.forEach(setting => {
    if (setting._id === 'permissions') {
      setting.roles.forEach(role => map[role.id] = role)
    }
  })
  return map
}

export const { updateUpdatedStatus, updateViewType } = settingsSlice.actions
export default settingsSlice.reducer
