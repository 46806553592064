import moment from 'moment'

const Helpers = {
  getTotalDays: (reservation) => {
    const finalPickupDatetime = moment(reservation.pickup_datetime)
    const finalReturnDatetime = moment(reservation.dropoff_datetime)
    const totalHours = finalReturnDatetime.diff(finalPickupDatetime, 'hours')
    if (totalHours <= 24) return 1
    const totalDays = Math.floor(totalHours / 24)
    const reminder = totalHours % 24
    if (reminder > 2) {
      return totalDays + 1
    } else {
      return totalDays
    }
  },

  getTotalHours: (reservation) => {
    const finalPickupDatetime = moment(reservation.pickup_datetime)
    const finalReturnDatetime = moment(reservation.dropoff_datetime)
    const totalMinutes = moment(finalReturnDatetime).diff(moment(finalPickupDatetime), 'minutes')
    return Math.ceil(totalMinutes / 60)
  },

  getDiscountedAmount: (pricing, subTotal) => {
    if (pricing?.discount?.label) {
      if (pricing.discount.label.toLowerCase() === 'custom amount') {
        return pricing.discount.value
      } else {
        return (pricing.discount.value * subTotal) / 100
      }
    } else {
      return 0
    }
  },

  getRentalsPricingObjectOnline: function (vehicle, onlinePriceSettings) {
    return {
      deposit: vehicle?.deposit || 0,
      mileage_allowance: vehicle?.details?.mileage_allowance || 0,
      mileage_overage_price: vehicle?.details?.mileage_overage_price || 0,
      service_tax: onlinePriceSettings?.service_tax?.rentals || 0,
      sales_tax: onlinePriceSettings?.sales_tax || 0,
      location_fee: onlinePriceSettings?.location_fee || 0,
      card_fee: onlinePriceSettings?.card_fee || 0,
      fuel_charge: vehicle?.details?.fuel_charge || 0,
      extras: [], // array of objects {name: '', price: ''}
      discount: {},
      mileage_package: {} // this will contain {value: 100 miles, price: $200}
    }
  },

  getRentalsPricingObject: function (reservation, vehicle, priceSettings) {
    return {
      deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
      mileage_overage_price: reservation?.pricing ? reservation?.pricing.mileage_overage_price : vehicle?.mileage_overage_price || 0,
      service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax.rentals || 0,
      sales_tax: reservation?.pricing ? reservation?.pricing?.sales_tax : priceSettings?.sales_tax || 0,
      mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
      location_fee: reservation?.pricing ? reservation?.pricing?.location_fee || 0 : priceSettings?.location_fee || 0,
      card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0 : priceSettings?.card_fee || 0,
      fuel_charge: reservation?.pricing ? reservation?.pricing?.fuel_charge || 0 : vehicle?.fuel_charge || 0,
      extras: reservation?.pricing?.extras || [], // array of objects {name: '', price: ''}
      discount: reservation?.pricing?.discount || {},
      mileage_package: reservation?.pricing?.mileage_package || {} // this will contain {value: 100 miles, price: $200}
    }
  },

  getChauffeurPricingObject: function (reservation, vehicle, priceSettings) {
    return {
      deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
      mileage_overage_price: reservation?.pricing ? reservation?.pricing?.mileage_overage_price : vehicle?.mileage_overage_price || 0,
      service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax.chauffeur || 0,
      mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
      card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0 : priceSettings?.card_fee || 0,
      extras: reservation?.pricing?.extras || [], // array of objects {name: '', price: ''}
      discount: reservation?.pricing?.discount || {},
      mileage_package: reservation?.pricing?.mileage_package || {} // this will contain {value: 100 miles, price: $200}
    }
  },

  checkPhoneNumberFormat: (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '')

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6, 10)}`
  },

  validateEmail: function (email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  },

  getCardSpacedNumbers: function (text) {
    const formattedText = text.replace(/ /g, '')
    let inputNumbersOnly = formattedText.replace(/\D/g, '')

    if (inputNumbersOnly.length > 16) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 16)
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g)

    let spacedNumber = ''
    if (splits) {
      spacedNumber = splits.join(' ')
    }

    return spacedNumber
  },

  getCardType: function (cardNumber) {
    const cardTypes = [
      {
        type: 'visa',
        pattern: /^4/
      },
      {
        type: 'mastercard',
        pattern: /^5[1-5]/
      },
      {
        type: 'amex',
        pattern: /^3[47]/
      },
      {
        type: 'discover',
        pattern: /^6(?:011|5)/
      }
    ]

    for (let i = 0; i < cardTypes.length; i++) {
      if (cardNumber.match(cardTypes[i].pattern)) {
        return cardTypes[i]
      }
    }
    return { type: 'unknown', image: null }
  },

  getFormattedExpiry: function (text, expiry) {
    if (text.length === 2 && expiry?.length === 1) {
      text += '/'
    } else if (text.length === 2 && expiry.length === 3) {
      text = text.substring(0, text.length - 1)
    }

    return text
  },

  numFormatter: function (num) {
    if (isNaN(num)) return null
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  },

  validateCardNumber: function (number, cvv, expiry) {
    const luhnCheck = (val) => {
      let checksum = 0 // running checksum total
      let j = 1 // takes value of 1 or 2

      // Process each digit one by one starting from the last
      for (let i = val.length - 1; i >= 0; i--) {
        let calc = 0
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(val.charAt(i)) * j

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum += 1
          calc -= 10
        }

        // Add the units element to the checksum total
        checksum += calc

        // Switch the value of j
        if (j === 1) {
          j = 2
        } else {
          j = 1
        }
      }

      // Check if it is divisible by 10 or not.
      return checksum % 10 === 0
    }

    if (!number || number.length === 0) {
      return false
    }
    // Check if the number contains only numeric value
    // and is of between 13 to 19 digits
    const regex = /^[0-9]{13,19}$/
    if (!regex.test(number.replace(/ /g, ''))) {
      return false
    }
    if (cvv.length !== 3 && cvv.length !== 4) return false
    if (expiry.length !== 5 || expiry.split('/').length !== 2) return false
    return luhnCheck(number.replace(/ /g, ''))
  }
}

export default Helpers
