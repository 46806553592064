import React, { useEffect, useState } from 'react'

import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import Helpers from '../../utils/Helpers'
import Button from '@mui/material/Button'
import Colors from '../../config/colors'
import { useDispatch, useSelector } from 'react-redux'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import CustomGooglePlacesNew from './CustomGooglePlacesNew'
import { getBusinessSettings, getIntegrationSettings } from '../../redux/slices/settingsSlice'
import { addReservationDetails } from '../../redux/slices/reservationsSlice'
import moment from 'moment'
import { CircularProgress } from '@mui/material'

const Alert = React.forwardRef(function Alert (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Step1 = ({ showLoader, sendDataToParent, locationUpdated }) => {
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [age, setAge] = useState('')
  const reservationDetails = useSelector(state => state.reservations.reservation_details)

  const [address, setAddress] = useState(null)
  const [delAddress, setDelAddress] = useState(null)
  const [additionalAddress, setAdditionalAddress] = useState('')
  const [zip, setZip] = useState('')
  const [licenseNo, setLicenseNo] = useState('')
  const [expiryLicense, setExpiryLicense] = useState(null)
  const [licenseState, setLicenseState] = useState('')
  const [policyProvider, setPolicyProvider] = useState('')
  const [policy, setPolicy] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [radioValue, setRadioValue] = useState('pickup')

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [customDel, setCustomDel] = useState(false)

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      // gap:"50px",
      width: '100%',
      marginTop: '20px'
      // backgroundColor:'pink'
    },
    textField: {
      display: 'flex'

    },

    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)'
    },
    typography: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      color: 'black'
    },
    boxTrip: {

      height: '35%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
      // backgroundColor:"red"
    },
    formHeader: {

      // backgroundColor: "red"
    },
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
      // backgroundColor:"red"
    },
    form2: {
      height: '50%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px'
      // justifyContent: "space-evenly",
      // lineHeight:"10px"
      // gap: "15px",

    },
    userInfo: {
      height: '100%'
      // backgroundColor:'red',
      // display:"flex",
      // flexDirection:"column",
      // justifyContent:"space-between"
    },

    formElement2: {
      // backgroundColor:"red"
      display: 'flex'
    }
  }))

  // const handleSubmit = (e) => {
  //     e.preventDefault();

  //     console.log('Form submitted with data:', {
  //         firstName,
  //         lastName,
  //         address,
  //         phoneNumber,
  //         age,
  //     });
  // };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const [state, setState] = useState({
    selection: {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  })

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  const [dataToSend, setDataToSend] = useState({
    bool: true
  })
  const businessSettings = useSelector(state => getBusinessSettings(state.settings))
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))
  const [requireId, setRequireId] = useState(false)
  const [deliveryAllowed, setDeliveryAllowed] = useState(false)

  const [location, setLocation] = useState('')

  useEffect(() => {
    if (businessSettings && businessSettings.address) {
      setLocation(`${businessSettings?.address}, ${businessSettings?.city_state} ${businessSettings?.zip}`)
    }
  }, [businessSettings])

  useEffect(() => {
    if (integrationSettings?.websiteIntegration) {
      const { require_id, custom_delivery } = integrationSettings.websiteIntegration
      setRequireId(require_id)
      setDeliveryAllowed(custom_delivery)
    }
  }, [integrationSettings])
  useEffect(() => {
    if (reservationDetails?.customerInfo) {
      const { fName, lName, email, phone, insurance, license } = reservationDetails.customerInfo
      setFirstName(fName)
      setLastName(lName)
      setEmailAddress(email)
      setPhoneNumber(phone)
      setAddress(reservationDetails.customerInfo.address)
      setPolicy(insurance?.policy_no)
      setPolicyProvider(insurance?.provider)
      setLicenseNo(license?.number)
      setLicenseState(license.state)
      if (typeof reservationDetails?.pickupLocation === 'string') {
        setCustomDel(false)
        setRadioValue('pickup')
      } else {
        setCustomDel(true)
        setDelAddress(reservationDetails.pickupLocation)
        setRadioValue('delivery')
      }
    }
  }, [reservationDetails])

  const validateData = () => {
    if (firstName.length === 0 || lastName.length === 0) {
      setErrorMessage('Please enter your name')
      return false
    }

    if (!Helpers.validateEmail(emailAddress)) {
      setErrorMessage('Please enter a valid email address')
      return false
    }

    if (phoneNumber.length !== 14) {
      setErrorMessage('Please enter a valid phone number')
      return false
    }

    if (!address || !address.label) {
      setErrorMessage('Please enter your residential address')
      return false
    }

    console.log('require ID is', requireId)
    if (requireId) {
      if (licenseNo.length === 0) {
        setErrorMessage('Please enter your license number')
        return false
      }

      if (!expiryLicense) {
        setErrorMessage('Please enter the license expiry date')
        return false
      }

      if (!licenseState || licenseState.length === 0) {
        setErrorMessage('Please enter your licence state')
        return false
      }
    }

    return true
  }

  const handleSubmit = async (e) => {
    //   sendDataToParent(dataToSend);
    e.preventDefault()

    if (!validateData()) {
      setSnackbarOpen(true)
    } else {
      const customerInfo = {
        fName: firstName,
        lName: lastName,
        email: emailAddress,
        phone: phoneNumber,
        address,
        insurance: {
          provider: policyProvider,
          policy_no: policy
        },
        license: {
          number: licenseNo,
          expiry_date: expiryLicense ? moment(expiryLicense).toDate().toISOString() : null,
          state: licenseState
        }
      }

      const temp = Object.assign({}, reservationDetails, {
        customerInfo,
        pickupLocation: customDel && delAddress !== null ? delAddress : location
      })
      dispatch(addReservationDetails(temp))
      console.log('added customer info', temp)
      await sendDataToParent(true)
    }
  }

  const renderPickupLocationInfo = () => {
    if (!deliveryAllowed) {
      return (
                <div className='form'
                     style={{ marginTop: 60, marginBottom: 20, display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <label style={{ color: Colors.theme, fontSize: 20 }}>Pick-up Location: Rental HQ - {location}</label>
                </div>

      )
    }

    return (
            <div className='form'
                 style={{ marginTop: 60, marginBottom: 20, display: 'flex', width: '100%', flexDirection: 'column' }}>
                <FormControl>
                    <RadioGroup
                        row
                        value={radioValue}
                        onChange={(e) => {
                          setRadioValue(e.target.value)
                          setDelAddress(null)
                          if (e.target.value === 'delivery') {
                            setCustomDel(true)
                          } else {
                            setCustomDel(false)
                            locationUpdated(location)
                          }
                        }}
                    >
                        <FormControlLabel
                            value="pickup"
                            style={{ color: 'black' }}
                            control={<Radio color='primary'/>}
                            label="Pick Up"
                        />
                        <FormControlLabel
                            value="delivery"
                            style={{ color: 'black' }}
                            control={<Radio color='primary'/>} label="Delivery"
                        />
                    </RadioGroup>
                </FormControl>
                {
                    customDel
                      ? <div>
                            <CustomGooglePlacesNew
                                placeholder={'Delivery Address'}
                                value={delAddress}
                                className={classes.formElement}
                                onChange={(value) => {
                                  setDelAddress(value)
                                  locationUpdated(value)
                                }}
                            />
                        </div>
                      : <label style={{ color: Colors.secondaryTextColor }}>Rental HQ Location: {location}</label>
                }
                <Typography variant='subtitle' style={{ color: 'gray' }}>

                </Typography>
            </div>
    )
  }

  return (
        <div className={`${classes.container} userInfoStep1Container`}>

            <div id='h-90' className={`${classes.userInfo} userInfoClass`}>
                <div id='flex-col' className={classes.formHeader}>

                    <h2 className='h2' style={{}}>Personal Information</h2>

                    <form className={classes.form}>
                        <div className='flex-col' style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          marginBottom: 10,
                          marginTop: 10
                        }}>
                            <TextField
                                variant='outlined'
                                type="text"
                                id="firstName"
                                placeholder='FIRST NAME*'
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value)
                                }}
                                required
                                className={`${classes.formElement} w-90 margin-top`}

                                style={{ width: '48%' }}
                            />

                            <TextField
                                variant='outlined'
                                type="text"
                                id="lastName"
                                placeholder='LAST NAME*'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className={`${classes.formElement} w-90 margin-top-sm`}
                                style={{ width: '48%' }}
                            />

                        </div>

                        <div style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                          height: 'auto',
                          overflowY: 'scroll'
                        }}>
                            <div className='flex-col'
                                 style={{
                                   display: 'flex',
                                   width: '100%',
                                   justifyContent: 'space-between',
                                   marginBottom: 10
                                 }}>
                                <TextField
                                    variant='outlined'
                                    type="text"
                                    id="email"
                                    placeholder='EMAIL ADDRESS*'
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    required
                                    className={`${classes.formElement} w-90`}
                                    style={{ width: '48%' }}
                                />

                                <TextField
                                    variant='outlined'
                                    type="tel"
                                    id="phoneNumber"
                                    placeholder='PHONE NUMBER*'
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(Helpers.checkPhoneNumberFormat(e.target.value))}
                                    required
                                    className={`${classes.formElement} w-90 margin-top-sm`}
                                    style={{ width: '48%' }}
                                />
                            </div>

                            <CustomGooglePlacesNew
                                style={{}}
                                placeholder={'ADDRESS *'}
                                value={address}
                                className={`${classes.formElement} w-90 margin-top-sm`}
                                onChange={(value) => {
                                  setAddress(value)
                                }}

                            />

                            <h2 className='h2' style={{ marginTop: 20, marginBottom: 20 }}>License Information</h2>
                            <div className='flex-col'
                                 style={{
                                   display: 'flex',
                                   width: '100%',
                                   justifyContent: 'space-between',
                                   marginBottom: 10
                                 }}>
                                <TextField
                                    variant='outlined'
                                    type="text"
                                    id="license"
                                    placeholder={`LICENSE NUMBER${requireId ? '*' : ''}`}
                                    style={{ width: '48%' }}
                                    value={licenseNo}
                                    onChange={(e) => setLicenseNo(e.target.value)}
                                    required
                                    className={`${classes.formElement} w-90 margin-top-sm`}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={`LICENSE EXPIRY${requireId ? '*' : ''}`}
                                        value={expiryLicense}
                                        onChange={(date) => {
                                          console.log('date is', date)
                                          setExpiryLicense(date.toDate())
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        inputFormat="dd/MM/yyyy" // Customize the date format
                                        className={`${classes.formElement} w-90 margin-top-sm`}
                                        sx={{ width: '48%' }}
                                    />

                                </LocalizationProvider>

                            </div>

                            <TextField
                                variant='outlined'
                                type="text"
                                id="licenseState"
                                placeholder={`LICENSE STATE${requireId ? '*' : ''}`}
                                style={{ width: '48%' }}
                                value={licenseState}
                                onChange={(e) => setLicenseState(e.target.value)}
                                required
                                className={`${classes.formElement} w-90 margin-top-sm`}
                            />

                            <h2 className='h2' style={{ marginTop: 20, marginBottom: 20 }}>Insurance Information</h2>
                            <div className='flex-col'
                                 style={{
                                   display: 'flex',
                                   width: '100%',
                                   justifyContent: 'space-between',
                                   marginBottom: 10
                                 }}>
                                <TextField
                                    variant='outlined'
                                    type="text"
                                    id="policyProvider"
                                    placeholder='POLICY PROVIDER'
                                    style={{ width: '48%' }}
                                    value={policyProvider}
                                    onChange={(e) => setPolicyProvider(e.target.value)}
                                    className={`${classes.formElement} w-90 margin-top-sm`}
                                />

                                <TextField
                                    variant='outlined'
                                    type="text"
                                    id="policy"
                                    placeholder='POLICY NUMBER'
                                    style={{ width: '48%' }}
                                    value={policy}
                                    onChange={(e) => setPolicy(e.target.value)}
                                    className={`${classes.formElement} w-90 margin-top-sm`}
                                />
                            </div>
                        </div>

                    </form>
                </div>

                {renderPickupLocationInfo()}
                {
                    showLoader
                      ? <div className="header-search-dropdown-loader-container">
                            <CircularProgress/>
                        </div>
                      : <Button className='w-90 btn' variant="contained"
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    top: 20
                                  }}
                                  onClick={handleSubmit}>Next</Button>
                }

            </div>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%', margin: '0 auto' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

        </div>

  )
}

export default Step1
