import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Contract from './routes/Contracts/Contract'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Partner from './routes/Partner/Partner'
import Reservation from './routes/Reservation/Reservation'
import UserInfo from './routes/Reservation/UserInfoMain'
import CalendarPage from './routes/Reservation/CalendarPage'
import PaymentInvoicePage from './routes/Payments/PaymentInvoice'
import AddressComponent from './routes/Address'
import store from './redux/store'
import { Provider } from 'react-redux'
import { DataContextProvider } from './context/dataContext'
import AxleDocViewer from './routes/AxleDocViewer'
import './styles/main.css'
import StripeCheckout from './routes/Payments/StripeCheckout'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App/>}/>
				<Route path="/contract/chauffeur" element={<Contract/>}/>
				<Route path="/contract/rental/pre" element={<Contract/>}/>
				<Route path="/contract/rental/post" element={<Contract/>}/>

				<Route path="/customer/contract/chauffeur" element={<Contract/>}/>
				<Route path="/customer/contract/rental/pre" element={<Contract/>}/>
				<Route path="/customer/contract/rental/post" element={<Contract/>}/>
				<Route path="/partner" element={<Partner/>}/>
				<Route path="/iframe" element={<Reservation/>}/>
				<Route path="/iframe/payment" element={<DataContextProvider><UserInfo/></DataContextProvider>}/>
				<Route path='/iframe/vehicles' element={<CalendarPage/>}/>
				<Route path='/payments' element={<PaymentInvoicePage/>}/>
				<Route path='/address' element={<AddressComponent/>}/>
				<Route path='/axle/doc' element={<AxleDocViewer/>}/>
				<Route path='/stripe/checkout' element={<StripeCheckout/>}/>
				<Route path='/stripe/checkout/return' element={<StripeCheckout/>}/>
				<Route
					path="*"
					element={
						<main style={{ padding: '1rem' }}>
							<h1>404 not found</h1>
						</main>
					}
				/>
			</Routes>
		</BrowserRouter>
	</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
