import React, { useEffect, useState } from 'react'

import { CircularProgress, makeStyles, Snackbar, TextField } from '@material-ui/core'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useDataContext } from '../../context/dataContext'
import { Alert } from '@mui/material'
import { addReservationDetails } from '../../redux/slices/reservationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getIntegrationSettings } from '../../redux/slices/settingsSlice'
import { WEBSITE_PAYMENT_AMOUNT } from '../../metadata/enums'
import Colors from '../../config/colors'
import Helpers from '../../utils/Helpers'

const Step2 = ({ onSubmitClicked, totalPrice, balancePayableChange, showCheckout }) => {
  const dispatch = useDispatch()
  const reservationDetails = useSelector(state => state.reservations.reservation_details)
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))

  const [cardName, setCardName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvv, setCVV] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [cardState, setCardState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('USA')
  const { finalLoader, updateData } = useDataContext()

  const [errorMessage, setErrorMessage] = useState('')
  const [license, setLicense] = useState('')
  const [expiryLicense, setExpiryLicense] = useState(null)
  const [policyProvider, setPolicyProvider] = useState('')
  const [policy, setPolicy] = useState('')
  const [payable, setPayable] = useState('')
  const [paymentString, setPaymentString] = useState('')
  const [disableAmount, setDisableAmount] = useState(false)

  useEffect(() => {
    console.log('here here', totalPrice, integrationSettings?.websiteIntegration)
    if (integrationSettings?.websiteIntegration && totalPrice > 0) {
      const { payment_amount_requirement, amount } = integrationSettings.websiteIntegration
      if (payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT) {
        console.log('full payment requred')
        setPayable(Number(totalPrice).toFixed(2))
        setPaymentString(`Full payment of $${totalPrice} is required to confirm the rental`)
        setDisableAmount(true)
      } else if (payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.PARTIAL_PAYMENT) {
        console.log('partial payment requred')
        const temp = (totalPrice * Number(amount)) / 100
        setPayable(Number(temp).toFixed(2))
        setPaymentString(`${amount}% of total balance is payable now`)
      } else {
        console.log('min payment requred')
        setPayable(Number(amount).toFixed(2))
        setPaymentString(`Minimum of $${amount} must be paid to reserve the booking`)
      }
    }
  }, [integrationSettings, totalPrice])

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      // gap:"50px",
      width: '100%',
      marginTop: '20px'
      // backgroundColor:'pink'
    },
    carCard: {
      display: 'flex',
      flexDirection: 'column'
    },
    textField: {
      display: 'flex'
    },
    boxTrip: {
      display: 'flex',
      flexDirection: 'column',
      height: '30%',
      justifyContent: 'space-evenly'
    },
    formElement: {
      width: '40%'
    },
    userInfo: {
      height: '85%',
      width: '100%'

    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%',
      width: '100%'
    },
    formElement2: {
      width: '100px'
    }
  }))

  const classes = useStyles()

  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const validateDate = () => {
    if (cardName.length === 0) {
      setErrorMessage('Please enter the full name on the card ')
      return false
    }

    if (!Helpers.validateCardNumber(cardNumber, cvv, expiry)) {
      setErrorMessage('Invalid card details')
      return false
    }

    if (!address1 || address1.length === 0) {
      setErrorMessage('Please enter billing address')
      return false
    }

    if (!city || city.length === 0) {
      setErrorMessage('Please enter billing city')
      return false
    }

    if (!cardState || cardState.length === 0) {
      setErrorMessage('Please enter billing state')
      return false
    }

    if (!zip || zip.length === 0) {
      setErrorMessage('Please enter billing zip')
      return false
    }

    if (!country || country.length === 0) {
      setErrorMessage('Please enter billing country')
      return false
    }

    if (!expiry || expiry.length !== 5) {
      setErrorMessage('Please enter a valid expiry date')
      return false
    }

    let minAmount = 0
    if (integrationSettings?.websiteIntegration?.payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.PARTIAL_PAYMENT) {
      minAmount = (totalPrice * Number(integrationSettings?.websiteIntegration?.amount)) / 100
    }

    if (integrationSettings?.websiteIntegration?.payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.MINIMUM_PAYMENT) {
      minAmount = Number(integrationSettings?.websiteIntegration?.amount)
    }

    if (Number(payable) < Number(Number(minAmount).toFixed(2))) {
      setErrorMessage(`The minimum amount to pay is $${Number(minAmount).toFixed(2)}`)
      return false
    }

    return true
  }

  const handleSubmit = (e) => {
    // if (!validateDate()) {
    //   setSnackbarOpen(true)
    // } else {
    updateData(true)
    e.preventDefault()
    const cardData = {
      cardName,
      cardNumber,
      expiry,
      address: address1,
      city,
      state: cardState,
      zip,
      country,
      cvv,
      amount: Number(payable)
    }
    const temp = Object.assign({}, reservationDetails, { cardData })
    dispatch(addReservationDetails(temp))
    onSubmitClicked(true)
    /* } */
  }

  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())

  const isStepOptional = (step) => {
    return step === 1
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const [cardType, setCardType] = useState({ type: 'unknown', image: null })
  const [state, setState] = useState({
    selection: {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  })

  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  const handleCardChange = (text) => {
    const spacedNumber = Helpers.getCardSpacedNumbers(text)
    setCardType(Helpers.getCardType(spacedNumber))
  }

  return (
        <div className={`${classes.container}`}>

            <div className={classes.userInfo}>
                <div className={classes.form}>
                    <div style={{ width: '100%', flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                        <TextField
                            variant='outlined'
                            type="text"
                            id="cardName"
                            placeholder='PAYABLEE AMOUNT*'
                            style={{ width: '40%' }}
                            value={`$${payable}`}
                            onChange={(e) => {
                              if (integrationSettings?.websiteIntegration?.payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT) {
                                return
                              }

                              const text = e.target.value
                              const amountNumber = text.replace('$', '').trim()
                              if (!isNaN(amountNumber)) {
                                setPayable(amountNumber)
                                balancePayableChange(Number(amountNumber))
                              }
                            }}
                            required
                            className={`${classes.formElement} w-90`}
                        />

                        <p style={{ color: Colors.theme, fontSize: 16, marginLeft: 20 }}>{paymentString}</p>
                    </div>

                    {/* <form className={classes.form}> */}

                    {/*    <div> */}
                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="cardName" */}
                    {/*            placeholder='NAME ON CARD*' */}
                    {/*            style={{ width: '100%' }} */}
                    {/*            value={cardName} */}
                    {/*            onChange={(e) => setCardName(e.target.value)} */}
                    {/*            required */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*        /> */}
                    {/*    </div> */}

                    {/*    <div className='flex-col' style={{ display: 'flex' }}> */}

                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="cardNumber" */}
                    {/*            placeholder='CARD NUMBER*' */}
                    {/*            value={cardNumber} */}
                    {/*            style={{ width: '100%' }} */}
                    {/*            onChange={(e) => { */}
                    {/*              const text = e.target.value */}
                    {/*              if (text.length < 20) { */}
                    {/*                const value = text */}
                    {/*                  .replace(/[^0-9]/gi, '') */}
                    {/*                  .replace(/(.{4})/g, '$1 ') */}
                    {/*                  .trim() */}
                    {/*                setCardNumber(value) */}
                    {/*              } */}
                    {/*              handleCardChange(text) */}
                    {/*            }} */}
                    {/*            required */}
                    {/*            InputProps={{ inputProps: { maxLength: 19 } }} */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*        /> */}

                    {/*    </div> */}

                    {/*    <div className='margin-top' style={{ display: 'flex' }}> */}

                    {/*        <TextField */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="expiry" */}
                    {/*            placeholder='EXPIRY MM/YY' */}
                    {/*            value={expiry} */}
                    {/*            onChange={(e) => { */}
                    {/*              const text = e.target.value */}
                    {/*              const number = text.replace('/', '') */}
                    {/*              if (isNaN(number)) { */}
                    {/*                return */}
                    {/*              } */}
                    {/*              setExpiry(Helpers.getFormattedExpiry(text, expiry)) */}
                    {/*            }} */}
                    {/*            required */}
                    {/*            InputProps={{ inputProps: { maxLength: 5 } }} */}
                    {/*            pattern="/^-?\d+\.?\d*$/" */}
                    {/*            className={`${classes.formElement2} w-45`} */}
                    {/*        /> */}

                    {/*        <TextField */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="cvv" */}
                    {/*            placeholder='CVV*' */}
                    {/*            value={cvv} */}
                    {/*            onChange={(e) => { */}
                    {/*              const text = e.target.value */}
                    {/*              if (isNaN(text)) { */}
                    {/*                return */}
                    {/*              } */}
                    {/*              setCVV(text) */}
                    {/*            }} */}
                    {/*            required */}
                    {/*            InputProps={{ inputProps: { maxLength: 4 } }} */}
                    {/*            pattern="/^-?\d+\.?\d*$/" */}
                    {/*            className={`${classes.formElement2} w-45`} */}
                    {/*        /> */}

                    {/*    </div> */}

                    {/*    <TextField */}
                    {/*        variant='outlined' */}
                    {/*        type="text" */}
                    {/*        id="billing_address_1" */}
                    {/*        placeholder='BILLING ADDRESS LINE 1*' */}
                    {/*        style={{ width: '100%' }} */}
                    {/*        value={address1} */}
                    {/*        onChange={(e) => setAddress1(e.target.value)} */}
                    {/*        required */}
                    {/*        className={`${classes.formElement} w-90`} */}
                    {/*    /> */}

                    {/*    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="city" */}
                    {/*            placeholder='BILLING CITY*' */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            value={city} */}
                    {/*            onChange={(e) => setCity(e.target.value)} */}
                    {/*            required */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*        /> */}
                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="state" */}
                    {/*            placeholder='BILLING STATE*' */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            value={cardState} */}
                    {/*            onChange={(e) => setCardState(e.target.value)} */}
                    {/*            required */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*        /> */}

                    {/*    </div> */}

                    {/*    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="Zip" */}
                    {/*            placeholder='BILLING ZIP CODE*' */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            value={zip} */}
                    {/*            onChange={(e) => setZip(e.target.value)} */}
                    {/*            required */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*            InputProps={{ inputProps: { maxLength: 5 } }} */}
                    {/*        /> */}
                    {/*        <TextField */}
                    {/*            variant='outlined' */}
                    {/*            type="text" */}
                    {/*            id="country" */}
                    {/*            placeholder='BILLING COUNTRY*' */}
                    {/*            style={{ width: '50%' }} */}
                    {/*            value={country} */}
                    {/*            onChange={(e) => setCountry(e.target.value)} */}
                    {/*            required */}
                    {/*            className={`${classes.formElement} w-90`} */}
                    {/*            InputProps={{ inputProps: { maxLength: 5 } }} */}
                    {/*        /> */}
                    {/*    </div> */}

                    {/*    /!* <TextField *!/ */}
                    {/*    /!*	variant='outlined' *!/ */}
                    {/*    /!*	type="text" *!/ */}
                    {/*    /!*	id="state" *!/ */}
                    {/*    /!*	placeholder='STATE*' *!/ */}
                    {/*    /!*	style={{width: "100%"}} *!/ */}
                    {/*    /!*	value={cardState} *!/ */}
                    {/*    /!*	onChange={(e) => setCardState(e.target.value)} *!/ */}
                    {/*    /!*	required *!/ */}
                    {/*    /!*	className={`${classes.formElement} w-90`} *!/ */}
                    {/*    /!* /> *!/ */}

                    {/* </form> */}

                    <Typography id="w-90" className={classes.typography}>
                        <p style={{ color: 'black' }}>Free cancellation within 24 hours from the time you placed the
                            order.</p>
                        <p style={{ color: 'black' }}>By placing this order, I agree to the <span
                            style={{ color: '#1976d2' }}> Terms & Conditions</span> and <span
                            style={{ color: '#1976d2' }}>Privacy Policy</span>.</p>
                        <p style={{ color: 'grey', fontSize: '12px' }}>Please Note: We will temporarily reserve the
                            funds on your
                            credit card with a pre-authorization. Your credit card will only be charged after the
                            reservation gets
                            confirmed by the Sales Team.</p>
                        <p style={{ color: 'grey', fontSize: '12px' }}>Safe and Secure SSL Encrypted</p>
                    </Typography>

                    <div>
                        {finalLoader
                          ? (
                                <div className="header-search-dropdown-loader-container"
                                     style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress/>
                                </div>
                            )
                          : (
                                <Button className='w-90' onClick={handleSubmit} style={{
                                  width: '100%',
                                  padding: '10px',
                                  backgroundColor: '#1976d2',
                                  color: 'white',
                                  cursor: 'pointer'
                                }}>

                                    Reserve Vehicle
                                </Button>
                            )}
                    </div>
                </div>

            </div>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%', margin: '0 auto' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

        </div>
  )
}

export default Step2
