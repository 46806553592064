import React, { useEffect, useState } from "react";
import axios from "axios";
import PdfViewerComponent from "../../components/Pdfviewer";
import GeneralStyles from "../styles/GeneralStyles.module.scss";
import Config from "../../config";
import ClientsMap from "../../metadata/ClientsMap";
import CustomLoaderSpinner from "../../components/CustomLoaderSpinner";
import CustomButtonContained from "../../components/CustomButtonContained";
import colors from "../../config/colors";
import { Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactS3Client from "../../services/S3Client";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.buttonTheme,
      dark: "#A996FF66",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffffff00",
      dark: "#ffffff66",
      contrastText: "#fff",
      borderColor: "#fff",
    },
    tertiary: {
      main: colors.buttonThemeLight,
      dark: "#BFBEFC66",
      contrastText: "#000",
      borderColor: "#fff",
    },
    white: {
      main: colors.primaryTextColor,
      dark: "#FCFCFC66",
      contrastText: "#000",
    },
  },
});

const Partner = (props) => {
  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("_id");
  const vehicleId = params.get("vehicleId");
  const authToken = params.get("token");
  const bus = params.get("bus");
  const [partner, setPartner] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFileSaveLoading, setIsFileSaveLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [newSelectedAgreements, setNewSelectedAgreements] = useState([]); // list ot the vehicle id and agreements urls the user has selected for the vehicles

  useEffect(() => {
    if (!partnerId || !authToken || !bus) {
      window.alert("invalid link");
    } else {
      let client = ClientsMap[bus];
      if (!client) {
        window.alert("Cannot find business information");
        return;
      }
      fetchParterProfileData(partnerId, authToken);
    }
  }, []);

  const fetchParterProfileData = async (partnerId, authToken) => {
    const url = `${Config.BACKEND_URL}partners/${partnerId}`;
    const headers = {
      Authorization: authToken,
      "Content-Type": "application/json", // Fixed 'contentType' to 'Content-Type'
    };
    try {
      const response = await axios.get(url, { headers });
      setPartner(response.data);
      const findVahicle = response.data.split.find(
        (vehicle) => vehicle.Vehicle_id === vehicleId
      );
      setSelectedVehicle({
        name: "Old Agreement",
        Vehicle_id: findVahicle.Vehicle_id,
        agreement: findVahicle.agreement,
        selectedFile: null,
      });
      setIsLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      let documentFileObjectUrl = URL.createObjectURL(e.target.files[0]);

      if (newSelectedAgreements.name !== "Old Agreement") {
        let agreementsList = [...newSelectedAgreements];
        // removing old pdf for same vehicle
        agreementsList = agreementsList.filter(
          (agreement) => agreement.vehicle_id !== selectedVehicle.Vehicle_id
        );
        // adding the vehicle id and agreement pdf:
        agreementsList.push({
          vehicle_id: selectedVehicle.Vehicle_id,
          agreementURL: documentFileObjectUrl,
          agreementFile: selectedFile,
        });
        setNewSelectedAgreements([...agreementsList]);
      }
      setSelectedVehicle({
        name: selectedFile.name,
        agreement: documentFileObjectUrl,
        selectedFile: selectedFile,
        Vehicle_id: selectedVehicle.Vehicle_id, // Retain other properties
      });
    } else {
      alert("Please select pdf file");
    }
  };

  useEffect(() => {
    console.log("selected value", newSelectedAgreements);
  }, [newSelectedAgreements]);

  const handleSave = async () => {
    setIsLoading(true);
    setIsFileSaveLoading(true);
    try {
      const agreements = [...newSelectedAgreements];
      const deletePromises = partner.split.map(async (vehicle) => {
        const agreement = agreements.find(
          (agreement) => agreement.vehicle_id === vehicle.Vehicle_id
        );
        if (vehicle.agreement && agreement) {
          const fileKey = vehicle.agreement.replace(
            Config.AWS_CLOUDFRONT_URL,
            ""
          );
          await ReactS3Client.deleteFile(fileKey);
        }
      });
      // Upload all agreement files and gather their URLs
      const uploadPromises = agreements.map(async (agreement) => {
        const file = agreement.agreementFile;
        const data = await ReactS3Client.uploadFile(file);
        const url = Config.AWS_CLOUDFRONT_URL + data.key;
        agreement["s3URL"] = url;
        return agreement;
      });

      // Wait for all previous files to be removed:
      await Promise.all(deletePromises);
      // Wait for all agreement files to be uploaded
      await Promise.all(uploadPromises);

      // Associate agreement URLs with corresponding vehicles
      partner.split.forEach((vehicle) => {
        const agreement = agreements.find(
          (agreement) => agreement.vehicle_id === vehicle.Vehicle_id
        );
        if (agreement) {
          vehicle["agreement"] = agreement.s3URL;
        }
      });

      const config = {
        method: "put",
        url: `${Config.BACKEND_URL}partners/${partnerId}`,
        headers: { Authorization: authToken, contentType: "application/json" },
        data: partner,
      };
      await axios(config);
      setIsLoading(false);
      setNewSelectedAgreements([]);
      setIsFileSaveLoading(false)
      window.alert("File uploaded successfully");
    } catch (error) {
      setIsLoading(false);
      setIsFileSaveLoading(false)
      console.log("error", error);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <div
          className={GeneralStyles.container}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CustomLoaderSpinner
            text={`${
              isFileSaveLoading ? "Please wait file is being uploaded" : ""
            }`}
          />
        </div>
      ) : (
        <>
          <Typography sx={{ textAlign: "center", m: 2 }} variant="h6">
            {selectedVehicle?.agreement ? selectedVehicle?.name : ""}
          </Typography>
          {selectedVehicle?.agreement ? (
            <PdfViewerComponent document={selectedVehicle?.agreement} />
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginTop: 20,
              width: "100%",
              marginTop: `${selectedVehicle.agreement ? "" : "200px"}`,
            }}
          >
            {selectedVehicle?.agreement ? (
              <CustomButtonContained
                style={{ maxWidth: "50%", height: 40 }}
                text={"Save"}
                onClick={handleSave}
              />
            ) : null}
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "transparent",
                  maxWidth: "50%",
                  height: 40,
                  borderRadius: 3,
                  border: 1,
                  borderColor: colors.themeLight,
                  color: colors.themeLight,
                  textTransform: "none",
                  padding: "6px 30px",
                }}
              >
                Upload New Agreement
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  hidden
                />
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

export default Partner;
