import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Step2 from '../../components/reservation/UserInfoStep2'
import CarCard from '../../components/reservation/CarCard'
import Step1 from '../../components/reservation/UserInfoStep1'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { clientsMapSelector } from '../../redux/slices/clientsSlice'
import { getBusinessSettings, getIntegrationSettings } from '../../redux/slices/settingsSlice'
import axios from 'axios'
import { LOGGER } from '../../utils/Logger'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, CircularProgress, Dialog, Snackbar, Typography } from '@mui/material'
import { CardContent } from '@mui/joy'
import Colors from '../../config/colors'
import { useDataContext } from '../../context/dataContext'
import Helpers from '../../utils/Helpers'
import { RENTAL_STATUS, VIEW_TYPES, WEBSITE_PAYMENT_AMOUNT, WEBSITE_PAYMENT_REQUIREMENTS } from '../../metadata/enums'
import Config from '../../config'
import Styles from './styles/UserInfoMain.module.scss'
import CustomModal from '../../components/CustomModal'

const steps = ['Select', 'Pay', 'Done']

function UserInfo () {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '100vw',
      height: '100vh'
    },
    carCard: {
      display: 'flex',
      flexDirection: 'column'
    },
    textField: {
      display: 'flex'
    },
    boxTrip: {
      display: 'flex',
      flexDirection: 'column',
      height: '30%',
      justifyContent: 'space-evenly'
    },
    formElement: {
      width: '400px'
    },
    userInfo: {
      height: '50%'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%'
    },
    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)'
    },

    DateCalendar: {
      padding: '0px !important',
      height: '30vh',
      width: '30vw'
    },
    img: {
      margin: 'auto'
    }
  }))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const viewType = useSelector(state => state.settings.viewType)
  const businessSettings = useSelector(state => getBusinessSettings(state.settings))
  const reservationDetails = useSelector(state => state.reservations.reservation_details)
  const token = useSelector(state => state.auth.token)
  const reservationState = useSelector(state => state.reservations)
  const clientsState = useSelector((state) => state.clients)
  const backendUrl = useSelector(state => state.auth.backend_url)
  const business = useSelector(state => state.auth.business)
  const integrationSettings = useSelector(state => getIntegrationSettings(state.settings))

  const [showCheckout, setShowCheckout] = useState(false)
  const [onlinePriceSettings, setOnlinePriceSettings] = useState(null)

  const classes = useStyles()

  const [openSnackbar, setOpenSnackbar] = useState({
    status: false,
    message: '',
    severity: ''
  })

  const [errorMessage, setErrorMessage] = useState('')

  const [pickupLocation, setPickupLocation] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const { finalLoader, updateData } = useDataContext()
  const [pricing, setPricing] = useState(null)

  const [additionalDriver, setAdditionalDriver] = useState({
    name: '',
    number: '',
    expiry_date: new Date(),
    state: ''
  })
  const [customerInfo, setCustomerInfo] = useState({
    fName: '',
    lName: '',
    email: '',
    phone: '',
    address: ''
  })
  const [licenseInfo, setLicenseInfo] = useState({
    number: '',
    expiry_date: new Date(),
    state: ''
  })
  const [insuranceInfo, setInsuranceInfo] = useState({
    provider: '',
    policy_no: ''
  })

  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expirationDate: '',
    cardCode: '',
    billingAddress: '',
    cardName: ''
  })

  const [originalCard, setOriginalCard] = useState({
    cardNumber: '',
    expirationDate: '',
    cardCode: ''
  })

  const [ids, setIds] = useState({
    clientId: '',
    _id: ''
  })

  const [allTransactions, setAllTransactions] = useState([])

  const [loader, setLoader] = useState(false)

  const clientsMap = clientsMapSelector(clientsState)

  const clientData = clientsMap[reservationState.reservation_to_update?.client]

  const [toSaveReservation, setToSaveReservation] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [payable, setPayable] = useState(0)
  const [paymentRequired, setPaymentRequired] = useState(false)

  useEffect(() => {
    if (!reservationDetails) {
      navigate(-1)
    }
  }, [reservationDetails])

  useEffect(() => {
    if (integrationSettings?.websiteIntegration) {
      setOnlinePriceSettings(integrationSettings.websiteIntegration)
      const { payment_requirement } = integrationSettings.websiteIntegration
      if (payment_requirement === WEBSITE_PAYMENT_REQUIREMENTS.PAYMENT) {
        setPaymentRequired(true)
      } else {
        // no payment is required for reservation
        setPaymentRequired(false)
      }
    }
  }, [integrationSettings, totalPrice])

  useEffect(() => {
    if (integrationSettings?.websiteIntegration && totalPrice > 0) {
      const { payment_amount_requirement, amount } = integrationSettings.websiteIntegration
      if (payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.FULL_PAYMENT) {
        console.log('full payment requred')
        setPayable(Number(totalPrice).toFixed(2))
      } else if (payment_amount_requirement === WEBSITE_PAYMENT_AMOUNT.PARTIAL_PAYMENT) {
        console.log('partial payment requred')
        const temp = (totalPrice * Number(amount)) / 100
        setPayable(Number(temp).toFixed(2))
      } else {
        console.log('min payment requred')
        setPayable(Number(amount).toFixed(2))
      }
    }
  }, [integrationSettings, totalPrice])

  useEffect(() => {
    if (onlinePriceSettings && reservationDetails && reservationDetails.vehicle) {
      setPricing(Helpers.getRentalsPricingObjectOnline(reservationDetails.vehicle, onlinePriceSettings))

      const subTotal = Number(reservationDetails?.vehicle?.details?.daily_price * reservationDetails?.totalDays)
      let locationFee = 0
      if (typeof pickupLocation !== 'string') {
        locationFee = Number(onlinePriceSettings?.location_fee)
      }

      const tempTotal = subTotal + locationFee

      const cardFee = onlinePriceSettings?.card_fee ? ((tempTotal * onlinePriceSettings?.card_fee) / 100) : 0
      const salesFee = ((tempTotal * onlinePriceSettings?.sales_tax) / 100)
      const serviceFee = ((tempTotal * onlinePriceSettings?.service_tax.rentals) / 100)

      setTotalPrice(tempTotal + cardFee + salesFee + serviceFee)
    }
  }, [onlinePriceSettings, reservationDetails, pickupLocation])

  useEffect(() => {
    if (businessSettings && businessSettings.address) {
      const address = `${businessSettings?.address}, ${businessSettings?.city_state} ${businessSettings?.zip}`
      setPickupLocation(address)
    }
  }, [businessSettings])

  const [skipped, setSkipped] = React.useState(new Set())

  // const isStepOptional = (step) => {
  //     return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const getBlockingResOrMain = async (isReservation, startDate, endDate) => {
    return new Promise((resolve, reject) => {
      let url
      if (isReservation) {
        url = `${backendUrl}reservations/blocking?start_date=${startDate}&end_date=${endDate}`
      } else {
        url = `${backendUrl}maintenances/blocking?start_date=${startDate}&end_date=${endDate}`
      }

      const config = {
        method: 'get',
        url,
        headers: { Authorization: token, contentType: 'application/json' }
      }
      axios(config)
        .then(res => resolve(res.data))
        .catch(err => {
          LOGGER.error('error when getting blocing something', err)
          reject(err)
        })
    })
  }

  const [dataFromChild, setDataFromChild] = useState(false)

  useEffect(() => {
    if (reservationDetails?.customerInfo && !paymentRequired) {
      console.log('got customer data', reservationDetails)
      // customer info is updated and no pamyent is required, make the reservation
      onSubmitClicked(true)
    }
  }, [reservationDetails?.customerInfo])

  const receiveDataFromChild = (data) => {
    if (paymentRequired) {
      setDataFromChild(true)
      setActiveStep(1)
    } else {
      console.log('no payment required')
      setLoader(true)
    }
  }

  const [salesPerson, setSalesPerson] = useState(null)

  const closeSnackbarHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar({ status: false, message: '', severity: '' })
  }

  const onSubmitClicked = async (data) => {
    if (!data) {
      return
    }
    setLoader(true)
    const {
      vehicle,
      startDate,
      endDate,
      pickupTime,
      returnTime,
      customerInfo,
      pickupLocation,
      dropoffLocation,
      totalDays,
      cardData
    } = reservationDetails
    if (!vehicle) {
      return
    }
    console.log(reservationDetails)
    const pickupMoment = moment(pickupTime)
    const returnMoment = moment(returnTime)
    const finalPickupDatetime = moment(startDate).hours(pickupMoment.hours()).minutes(pickupMoment.minutes()).toDate().toISOString()
    const finalReturnDatetime = moment(endDate).hours(returnMoment.hours()).minutes(returnMoment.minutes()).toDate().toISOString()

    console.log(finalPickupDatetime, finalReturnDatetime)

    const blockingRes = await getBlockingResOrMain(true, finalPickupDatetime, finalReturnDatetime)
    const blockingMain = await getBlockingResOrMain(false, finalPickupDatetime, finalReturnDatetime)
    const total = blockingRes.concat(blockingMain)

    const forbiddenVehicleIds = total.filter((v, i, a) => a.findIndex((v2) => v2.vehicle_id === v.vehicle_id) === i).map((temp) => temp.vehicle_id)
    console.log('forbiddent vehcles', forbiddenVehicleIds)

    if (forbiddenVehicleIds.includes(vehicle?.id)) {
      LOGGER.log('the vehicle has been reserved while waiting')
      if (window.confirm('Unfortunately the vehicle has been reserved and is not available for the select dates. Would you like to try different dates or select a different?')) {
        navigateHome()
      }
      return
    }

    console.log('can book', pricing)

    const pricingTemp = {}
    for (const key in pricing) {
      if (!pricing.hasOwnProperty(key)) continue
      if (key === 'discount' || key === 'mileage_package' || key === 'extras') {
        pricingTemp[key] = pricing[key]
      } else {
        pricingTemp[key] = Number(pricing[key])
      }
    }

    const dailyPrice = vehicle?.details?.daily_price || vehicle?.dailyPrice

    const toSaveReservation = {
      vehicle_id: vehicle.id,
      pickup_datetime: finalPickupDatetime,
      dropoff_datetime: finalReturnDatetime,
      rental_status: RENTAL_STATUS.WAITING_PICKUP,
      paid: false,
      totalPrice: dailyPrice * totalDays,
      dailyPrice: Number(dailyPrice),
      sales_attribution: 'Website Booking',
      pricing: pricingTemp,
      pickup_location: pickupLocation,
      dropoff_location: dropoffLocation,
      additional_driver: Object.assign({}, additionalDriver, { expiry_date: new Date(additionalDriver.expiry_date).toISOString() })
    }

    const customer = JSON.parse(JSON.stringify(customerInfo))
    customer.license.expiry_date = new Date(customerInfo.license?.expiry_date).toISOString()
    toSaveReservation.customer = customer

    setToSaveReservation(toSaveReservation)
    handleClick(toSaveReservation)
    setLoader(false)
  }
  const navigateHome = () => {
    const url = `/iframe?bus=${business}&random=${Config.ADMIN_CREDS[1]}&view=${viewType === VIEW_TYPES.dropdown ? 'dropdown' : 'list'}`
    navigate(url)
  }

  const chargeNewCardForCustomer = async (responseData) => {
    try {
      const chargeResponse = await chargeCard(responseData)
      console.log('customer profile needs to be created', chargeResponse)
      await createCustomerProfile(responseData)
      alert('Successfully created your rental. You should receive a confirmation email with all the details in a few seconds')
      navigateHome()
    } catch (err) {
      LOGGER.error('Error when charging card', err)
      alert('There was a problem when charging your card. Please contact support to resolve and confirm your booking')
    }
  }

  const chargeCard = async (responseData) => new Promise(async (resolve, reject) => {
    const { _id, clientId } = responseData
    const {
      vehicle,
      cardData
    } = reservationDetails

    const name = cardData?.cardName
    const payload = {
      firstName: name.split(' ', 2)[0],
      lastName: name.split(' ', 2)[1] || '',
      clientId,
      reservationId: _id,
      vehicleId: vehicle.id,
      address: cardData?.address,
      city: cardData?.city,
      state: cardData?.state,
      zip: cardData?.zip,
      country: cardData?.country,
      salesPerson: 'Website Booking',
      payment: {
        amount: Number(payable),
        creditCard: {
          cardNumber: cardData?.cardNumber.replace(/\s/g, ''),
          expirationDate: cardData?.expiry.replace('/', ''),
          cardCode: cardData?.cvv
        }
      }
    }

    const chargeConfig = {
      method: 'post',
      url: `${backendUrl}payments/online/balance`, // replace with config backend url
      data: payload,
      headers: { Authorization: token }
    }

    console.log('charge card data', payload)
    try {
      const chargeResponse = await axios(chargeConfig)
      console.log('charge card resposne', chargeResponse)
      if (chargeResponse.data?._id) {
        LOGGER.log('successfully charged the card')

        setActiveStep(2)
        setLoader(false)
        setAnchorEl(false)
        setAnchorEl2(true)
        resolve(chargeResponse)
      }
    } catch (err) {
      LOGGER.error('Error when charging card', err)
      setOpenSnackbar((prevValue) => {
        return {
          ...prevValue,
          status: true,
          message: err,
          severity: 'error'
        }
      })
      reject(err)
    }
  })

  const createCustomerPaymentProfile = async (data) => new Promise(async (resolve, reject) => {
    const inputDate = new Date(cardDetails.expirationDate)
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0')
    const year = inputDate.getFullYear().toString().slice(-2)
    const formattedDate = month + year
    const paymentProfilePayload = {
      firstName: customerInfo.fName,
      lastName: customerInfo.lName,
      clientId: ids.clientId,
      customerProfileId: clientData.customerProfileId,
      address: cardDetails.billingAddress,
      payment: {
        creditCard: {
          cardNumber: parseInt(originalCard.cardNumber),
          expirationDate: formattedDate
        }
      },
      token
    }

    const paymentProfileConfig = {
      method: 'post',
      url: `${backendUrl}/createCustomerPaymentProfile`,
      data: paymentProfilePayload,
      headers: {
        Authorization: paymentProfilePayload.token
      }
    }

    try {
      const paymentProfileResponse = await axios(paymentProfileConfig)
      LOGGER.log('createCustomerPaymentProfile', paymentProfileResponse)
      if (paymentProfileResponse.data?.messages?.resultCode === 'Ok' ||
                paymentProfileResponse.data?.messages?.message[0]?.code === 'E00039') {
        resolve()
      } else {
        reject()
      }
    } catch (err) {
      LOGGER.error('Error when creating customer payment profile', err)
      setOpenSnackbar((prevValue) => {
        return {
          ...prevValue,
          status: true,
          message: err,
          severity: 'error'
        }
      })
      reject(err)
    }
  })

  const createCustomerProfile = async (responseData) => new Promise(async (resolve, reject) => {
    const { clientId } = responseData
    const {
      cardData, customerInfo
    } = reservationDetails
    const name = cardData?.cardName
    const customerPayload = {
      firstName: name.split(' ', 2)[0],
      lastName: name.split(' ', 2)[1],
      clientId,
      address: cardData?.address,
      city: cardData?.city,
      state: cardData?.state,
      zip: cardData?.zip,
      country: cardData?.country,
      customerData: {
        customerId: clientId,
        email: customerInfo.email,
        creditCard: {
          cardNumber: cardData?.cardNumber.replace(/\s/g, ''),
          expirationDate: cardData?.expiry.replace('/', '')
        }
      }
    }
    const customerConfig = {
      method: 'post',
      url: `${backendUrl}createCustomerProfile`,
      data: customerPayload,
      headers: { Authorization: token }
    }

    try {
      const customerResponse = await axios(customerConfig)
      LOGGER.log('createCustomerProfile API respnse', customerResponse)
      if (customerResponse.data.messages.resultCode === 'Ok') {
        resolve()
      } else if (customerResponse.data?.messages?.message[0]?.code === 'E00039') {
        reject('duplicate')
      } else {
        reject()
      }
    } catch (err) {
      LOGGER.error('Error when creating customer profile', err)
      setOpenSnackbar((prevValue) => {
        return {
          ...prevValue,
          status: true,
          message: err,
          severity: 'error'
        }
      })

      reject(err)
    }
  })

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = () => {
    setAnchorEl(true)
    updateData(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorEl2, setAnchorEl2] = useState(null)

  const handleClose2 = () => {
    setAnchorEl2(null)
  }

  const addReservation = async (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          method: 'post',
          url: `${backendUrl}reservations`,
          headers: { Authorization: token, contentType: 'application/json' },
          data: payload
        }

        const res = await axios(config)
        resolve(res.data)
      } catch (err) {
        LOGGER.log('error when adding new reservation', err)
        reject()
      }
    })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'calendar-popover' : undefined

  const open2 = Boolean(anchorEl2)
  const id2 = open2 ? 'calendar-popover' : undefined

  const handleSubmit = async () => {
    setLoader(true)

    try {
      console.log(toSaveReservation)
      const response = await addReservation(toSaveReservation)
      console.log('response from add res', response)
      const temp = Object.assign({}, toSaveReservation, { _id: response._id, client_id: response.clientId })
      setToSaveReservation(temp)
      setOpenSnackbar((prevValue) => ({
        ...prevValue,
        status: true,
        message: '',
        severity: 'success'
      }))
      setErrorMessage('Successfully created your rental')
      if (!paymentRequired) {
        setLoader(false)
        setAnchorEl(false)
        alert('Successfully created your rental. You should receive a confirmation email with all the details in a few seconds')
        navigateHome()
        return
      }

      // the reservation has been made, charge the customer
      setShowCheckout(true)
      // try {
      //   await chargeNewCardForCustomer(response)
      // } catch (err) {
      //   console.log('Error when charging card', err)
      //   setLoader(false)
      //   setAnchorEl(false)
      //   setOpenSnackbar((prevValue) => ({
      //     ...prevValue,
      //     status: true,
      //     message: err,
      //     severity: 'error'
      //   }))
      //   setErrorMessage('Error when charging your card')
      //   alert('There was a problem when charging your card. Please contact support to resolve and confirm your booking')
      // }
    } catch (err) {
      console.log('Error when creatng reservation', err)
      setErrorMessage('Error when creating your reservation')
    }
  }

  const renderPaymentModal = () => {
    const salesPerson = 'Website Booking'
    if (!toSaveReservation || !toSaveReservation.client_id || !toSaveReservation._id) {
      return null
    }

    const { _id, customer, vehicle_id, client_id } = toSaveReservation

    const url =
            `${Config.CONTRACT_URL}stripe/checkout?bus=${business}&email=${customer?.email}&productId=${reservationDetails?.vehicle?.stripe?.product_id}&token=${token}&clientId=${client_id}&vehicleId=${vehicle_id}&resId=${_id}&salesPerson=${salesPerson}&isSecurity=false&amount=${payable}`
    return (
            <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 20
                }}>
                <label
                    style={{ color: Colors.theme, fontSize: 22, width: '100%', textAlign: 'center' }}>
                    The car has been reserved. Please complete the payment.
                </label>
                <iframe
                    style={{ borderWidth: 0 }}
                    src={url}
                    height={'100%'}
                    width={'100%'}/>
            </div>
    )
  }

  return (
        <div className={classes.container}>

            <div className={Styles.container2}>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                          const stepProps = {}
                          const labelProps = {}
                          if (isStepSkipped(index)) {
                            stepProps.completed = false
                          }
                          return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                          )
                        })}
                    </Stepper>

                </Box>

                <div className='infoMainDiv' style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 30
                }}>
                    {
                        dataFromChild
                          ? (
                                <div className='infoMainDetailsCard' style={{ width: '50%', height: '100%' }}>
                                    <label
                                        onClick={() => setDataFromChild(false)}
                                        style={{
                                          color: Colors.themeLight,
                                          cursor: 'pointer',
                                          textDecoration: 'underline',
                                          paddingBottom: 10,
                                          fontSize: 18
                                        }}
                                    >
                                        &larr; edit personal information
                                    </label>
                                    <Step2 onSubmitClicked={onSubmitClicked} totalPrice={totalPrice}
                                           balancePayableChange={(value) => setPayable(value)} showCheckout={showCheckout}/>
                                </div>
                            )
                          : (
                                <div className='infoMainDetailsCard' style={{ width: '50%', height: '100%' }}>
                                    <Step1 sendDataToParent={receiveDataFromChild}
                                           showLoader={loader}
                                           locationUpdated={(location) => setPickupLocation(location)}/>
                                </div>
                            )
                    }
                    <div className='infoMainCarCard' style={{ width: '50%', height: '100%' }}>
                        <CarCard
                            location={pickupLocation}
                            vehicle={reservationDetails?.vehicle || {}}
                            pickupDate={moment(reservationDetails?.startDate).format('Do MMM, YYYY')}
                            returnDate={moment(reservationDetails?.endDate).format('Do MMM, YYYY')}
                            totalDays={reservationDetails?.totalDays}
                            returnTime={moment(reservationDetails?.returnTime).format('hh:mm A')}
                            pickupTime={moment(reservationDetails?.pickupTime).format('hh:mm A')}
                        />
                    </div>
                </div>

            </div>

            <Dialog
                id={id}
                open={open}
                // open={true}
                className='dialogFirst'
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ height: '40vh', width: '50vw', margin: 'auto', display: 'flex', justifyContent: 'center' }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop
                  }
                }}
            >
                <Box className={classes.paper2} style={{
                  width: '95%',
                  height: '40vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  margin: 'auto',
                  textAlign: 'center'
                }}>

                    <Typography variant='h5'>
                        {`Are you sure you want to reserve ${reservationDetails?.vehicle.make} from ${moment(reservationDetails?.startDate).format('Do MMM, YYYY')} at ${moment(reservationDetails?.pickupTime).format('hh:mm A')}`}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {loader
                          ? (
                                <div className="header-search-dropdown-loader-container">
                                    <CircularProgress/>
                                </div>
                            )
                          : (
                                <Button onClick={handleSubmit} sx={{
                                  backgroundColor: '#1976d2',
                                  padding: '10px',
                                  color: 'white',
                                  borderRadius: '20px',
                                  cursor: 'pointer'
                                }}>

                                    Yes
                                </Button>
                            )}
                        <Button onClick={handleClose} sx={{
                          backgroundColor: '#1976d2',
                          padding: '10px',
                          color: 'white',
                          borderRadius: '20px',
                          cursor: 'pointer'
                        }}>
                            No
                        </Button>

                    </div>

                </Box>
            </Dialog>

            <Snackbar
                open={openSnackbar.status}
                // open={true}
                autoHideDuration={3000}
                onClose={closeSnackbarHandler}
            >
                <Alert severity={openSnackbar.severity} sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Dialog
                id={id2}
                fullScreen={'lg'}
                open={open2}
                className='dialogReservedMain'
                // open={true}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                style={{
                  height: '50%',
                  width: '50%',
                  margin: 'auto',
                  display: 'flex',
                  minHeight: '60vh',
                  maxHeight: '60vh'
                }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop
                  }
                }}
            >
                <Box className="dialogReserved" sx={{
                  width: '100%',
                  minWidth: '60vw',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}>
                    <img src={reservationDetails?.vehicle?.pictureUrl} alt="/" width={'100%'} height={'100%'}
                         style={{ position: 'absolute' }}
                         className={classes.img}/>
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', zIndex: '10' }}>
                        <CardContent>
                            <Typography variant='h5' color={'white'}
                                        level="title-md">{reservationDetails?.vehicle?.make}</Typography>

                        </CardContent>
                        <CardContent>

                            <h3 style={{ color: 'white' }}>Your car has been reserved!</h3>
                        </CardContent>

                    </div>
                </Box>
            </Dialog>

            <CustomModal
                show={showCheckout}
                handleClose={() => {
                  setShowCheckout(false)
                  navigateHome()
                }}
                containerWidth={window.innerWidth - 100}
                containerHeight={window.innerHeight - 100}
            >
                {renderPaymentModal()}
            </CustomModal>

        </div>
  )
}

export default UserInfo
