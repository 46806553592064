import React, { useEffect, useRef, useState } from 'react'
import Config from '../config'

const GenericPdfRenderer = ({ url }) => {
  const containerRef = useRef(null)
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    if (!url) {
      window.alert('missing url renderer')
      return
    }

    const container = containerRef.current
    let PSPDFKit, tempInstance
    (async function () {
      PSPDFKit = await import('pspdfkit')
      tempInstance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        licenseKey: Config.PSPDFKIT_LICENSE_KEY,
        // The document to open.
        document: url,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}/`
      })

      setInstance(tempInstance)
      const items = tempInstance.toolbarItems
      const ids = ['export-pdf', 'zoom-out', 'zoom-in', 'pager', 'pan']
      tempInstance.setToolbarItems(items.filter((item) => ids.includes(item.type)))
    })()
    return () => PSPDFKit && PSPDFKit.unload(container)
  }, [])

  return (
		<div style={{ width: '100%', height: '100vh' }}>
			<div ref={containerRef} style={{ width: '100%', height: '100%' }}/>
		</div>
  )
}

export default GenericPdfRenderer
