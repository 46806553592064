import React, { createContext, useContext, useState } from 'react';

// Create a new context
const DataContext = createContext();

// Create a provider component to wrap around your components
export function DataContextProvider({ children }) {
  const [finalLoader, setFinalLoader] = useState(false); 

  // Define functions to update the data (if needed)
  const updateData = (newData) => {
    setFinalLoader(newData);
  };

  return (
    <DataContext.Provider value={{ finalLoader, updateData }}>
      {children}
    </DataContext.Provider>
  );
}

// Create a custom hook to easily access the context in child components
export function useDataContext() {
  return useContext(DataContext);
}
